import axios from "axios";
import store from "../store/index";

const urlApiHorusAuth = process.env.VUE_APP_URL_HORUS + "auth";

const application = process.env.VUE_APP_APPLICATION;

let checkPwdInstance = axios.create();
delete checkPwdInstance.defaults.headers.common["Authorization"];

export default class auth {
  async signIn(password, body) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login?password=" + password,
      body,
      {
        headers: { "x-app": application },
      }
    );
    return request;
  }

  async user_signIn_fido(fidoKey) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login/fido",
      fidoKey,
      {
        headers: { "x-app": application },
      }
    );

    return request.data;
  }

  async user_signIn_new_tab(token) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login/app",
      {},
      {
        headers: { "x-app": application, Authorization: "Bearer " + token },
      }
    );

    return request.data;
  }

  async login_digicode(digicode, userPath, infoConfig) {
    const request = await axios.post(
      urlApiHorusAuth +
        "/security/login/digicode?digicode=" +
        digicode +
        "&userPath=" +
        userPath,
      infoConfig,
      {
        headers: { "x-app": application },
      }
    );

    return request;
  }

  async get_user_info() {
    const info = await axios.get(urlApiHorusAuth + "/user/info");

    return info.data;
  }

  async signUp(user) {
    const request = await axios.post(urlApiHorusAuth + "/user/register", user, {
      headers: { "x-app": application },
    });
    return request.data;
  }

  async logout() {
    try {
      await axios.post(urlApiHorusAuth + "/security/logout");
      store.commit("update_userConnected", false);
      store.commit("update_userAuth", {
        displayName: null,
        locale: null,
        login: null,
        loginDate: null,
        nextRenewCredentialsDate: null,
        token: null,
      });
      store.commit("update_currentBu", null);
      store.commit("update_noChangePass", false);
      localStorage.removeItem("horusToken");
    } catch (error) {
      console.error(error);
    } finally {
      delete axios.defaults.headers.common["Authorization"];
      return "logout ok";
    }
  }

  async pwdForgot(userPath) {
    delete axios.defaults.headers.common["Authorization"];
    const request = await axios.post(urlApiHorusAuth + "/user/renew", {
      login: userPath,
    });
    return request.data;
  }

  async changePwd(password) {
    const request = await axios.post(
      urlApiHorusAuth + "/user/changePassword?password=" + password
    );

    store.commit("update_userAuth", request.data);

    return request.data;
  }

  async verifyPwd(password) {
    const request = await axios.post(
      urlApiHorusAuth + "/user/verifyPassword?password=" + password
    );

    return request.data;
  }

  async update_user(user) {
    const request = await axios.post(urlApiHorusAuth + "/user/update", user);
    return request.data;
  }

  async checkMdp(password) {
    const request = await axios.post(
      urlApiHorusAuth + "/user/checkPassword?password=" + password,
      {}
    );
    return request.data;
  }

  async checkMdp_from_signup(password) {
    const request = await checkPwdInstance.post(
      urlApiHorusAuth + "/user/checkPassword?password=" + password,
      {},
      {
        headers: { "x-app": application },
      }
    );
    return request.data;
  }

  async get_user_policy_from_signup() {
    const request = await checkPwdInstance.post(
      urlApiHorusAuth + "/user/passwordPolicy",
      {},
      {
        headers: { "x-app": application },
      }
    );
    return request.data;
  }

  async change_info_user(apiKey, userPath, infoUser) {
    const request = await axios.post(
      urlApiHorusAuth + "/user/save/onbehalf?userPath=" + userPath,
      infoUser,
      {
        headers: { "x-tenant": apiKey },
      }
    );

    return request.data;
  }

  async infoSupport(locale) {
    // delete axios.defaults.headers.common["Authorization"];
    let localeFormat = "en_US";
    if (locale == "fr") {
      localeFormat = "fr_FR";
    }
    const request = await axios.post(
      urlApiHorusAuth + "/app/contact",
      {},
      {
        headers: { "x-locale": localeFormat },
      }
    );
    return request.data;
  }

  async infoSupportTenant(locale, tenant) {
    // delete axios.defaults.headers.common["Authorization"];
    let localeFormat = "en_US";
    if (locale == "fr") {
      localeFormat = "fr_FR";
    }
    const request = await axios.post(
      urlApiHorusAuth + "/app/contact",
      {},
      {
        headers: { "x-locale": localeFormat, "x-tenant": tenant },
      }
    );

    return request.data;
  }

  async codeSupport(tenant) {
    const info = await axios.get(urlApiHorusAuth + "/tenant/support/generate", {
      headers: { "x-tenant": tenant },
    });
    return info.data;
  }

  async favoriteUpdate(apiKey, mode, direct) {
    let request = "";
    request = await axios.post(
      urlApiHorusAuth +
        "/tenant/grant/update?apiKey=" +
        apiKey +
        "&mode=" +
        mode +
        "&direct=" +
        direct,
      {}
    );

    return request.data;
  }

  async favoritesList() {
    const favorie = await axios.get(
      urlApiHorusAuth + "/security/tenants/favorite?module=member"
    );

    return favorie.data;
  }

  async searchTenant(search) {
    const tenantList = await axios.get(
      urlApiHorusAuth +
        "/security/tenants/search?search=" +
        search +
        "&module=member"
    );

    return tenantList.data;
  }

  async create_tenant(token, name, data) {
    const request = await axios.post(
      urlApiHorusAuth + "/tenant/createTenant?name=" + name,
      data,
      {
        headers: {
          Authorization: token,
          "x-app": application,
        },
      }
    );
    return request.data;
  }

  async create_tenant_from_signin(name, data) {
    const request = await axios.post(
      urlApiHorusAuth + "/tenant/createTenant?name=" + name,
      data,
      {
        headers: {
          "x-app": application,
        },
      }
    );
    return request.data;
  }

  async get_user_policy() {
    const request = await axios.post(
      urlApiHorusAuth + "/user/passwordPolicy",
      {}
    );
    return request.data;
  }

  async get_user_audit(apiKey) {
    const info = await axios.get(urlApiHorusAuth + "/user/audit", {
      headers: { "x-tenant": apiKey },
    });
    return info.data;
  }

  async get_sso(body) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login/sso",
      body
    );
    return request.data;
  }

  async get_user_forgot_pwd(tmptk) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login/renew?tmptk=" + tmptk,
      {}
    );
    return request.data;
  }

  async get_user_forgot_pwd(tmptk) {
    const request = await axios.post(
      urlApiHorusAuth + "/security/login/renew?tmptk=" + tmptk,
      {}
    );
    return request.data;
  }

  async set_user_avatar(apiKey, form) {
    const request = await axios.post(
      urlApiHorusAuth + "/user/save/avatar",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_perimeter(apiKey, search) {
    const request = await axios.post(
      urlApiHorusAuth + "/perimeter/search?search=" + search,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_enrich_perimeter(apiKey, scope) {
    const request = await axios.post(
      urlApiHorusAuth + "/perimeter/enrich",
      scope,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
