var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "max-width": "350",
        "offset-x": "",
        "z-index": "1000",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        "x-small": "",
                        fab: "",
                        dark: "",
                        color: "white",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "navPrimary" } }, [
                    _vm._v("mdi-apps"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "div",
            { staticClass: "pa-1 d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", text: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("change-tenant")
                    },
                  },
                },
                [_vm._v("Changer d'espace de travail ")]
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-2" }),
          _c(
            "div",
            { staticClass: "pa-2" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-col",
                    { key: item.uuid, attrs: { cols: "6" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ hover }) {
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        { name: "blur", rawName: "v-blur" },
                                      ],
                                      staticClass:
                                        "d-flex flex-column align-center justify-center",
                                      attrs: {
                                        flat: "",
                                        color: hover ? "soft" : "",
                                        disabled:
                                          item.currentApp ||
                                          !_vm.$authorization.validateGrant(
                                            item.grant
                                          ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.select_app(item.link)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            value:
                                              !_vm.$authorization.validateGrant(
                                                item.grant
                                              ),
                                            bordered: "",
                                            overlap: "",
                                            color: "error",
                                            icon: "mdi-lock",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: "50",
                                                color: item.currentApp
                                                  ? "grey"
                                                  : item.color,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "subtitle-2",
                                          class: {
                                            "grey--text": item.currentApp,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }