<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet
      class="layout-side mx-auto d-none d-md-flex flex-md-column justify-space-between px-2 glass"
      height="100vh"
      tile
    >
      <div class="mt-3 mt-md-10 pa-2">
        <div
          class="pa-2 d-flex flex-column justify-start"
          @click="toLoginPage()"
        >
          <v-img
            src="@/assets/brandPro-logo.png"
            aspect-ratio="1.4"
            contain
            style="cursor: pointer"
          ></v-img>
        </div>
        <div>
          <span class="display-1 font-weight-bold secondary--text"
            >Adhérent</span
          >
        </div>
      </div>
      <!-- <div class="pa-2">
        <v-btn @click="openInfoSupport = true" outlined small color="white">
          <v-icon small left>mdi-account-question</v-icon>
          {{ $t("support.helpSupport") | capitalize }}
        </v-btn>
        <SupportInfo :showDialog.sync="openInfoSupport" />
      </div> -->
    </v-sheet>

    <div
      class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
    >
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <div class="overline mt-4"><Toolbar-language /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToolbarLanguage from "../components/toolbar/ToolbarLanguage";
import SupportInfo from "@/components/support/SupportInfo";

export default {
  name: "AuthLayout",
  components: {
    ToolbarLanguage,
    SupportInfo,
  },

  computed: {
    ...mapState("app", ["product"]),
  },

  data() {
    return {
      openInfoSupport: false,
    };
  },

  mounted() {
    if (document.getElementById("ze-snippet")) {
      zE("messenger:set", "zIndex", -100);
    }

    this.langDetect();
    document.title = "Adhérent";
  },

  methods: {
    langDetect() {
      const supported = ["en", "fr"];
      try {
        // get browser default language
        const { 0: browserLang } = navigator.language.split("-");

        if (supported.includes(browserLang)) {
          this.$i18n.locale = browserLang;
          this.$moment.locale(browserLang);
        }
      } catch (error) {
        console.error(error);
      }
    },

    toLoginPage() {
      if (this.$route.name == "utility-maintenance") {
        this.$router.push({ name: "auth-signin" });
      }
    },
  },
};
</script>

<style scoped>
.layout-side {
  width: 420px;
}
.layout-content {
  max-width: 480px;
}
</style>
