var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openDialog
    ? _c(
        "v-dialog",
        {
          attrs: { width: "1000", persistent: "" },
          model: {
            value: _vm.openDialog,
            callback: function ($$v) {
              _vm.openDialog = $$v
            },
            expression: "openDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "title primary--text" }, [
                _vm._v(" Notification importante "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _vm.tip.nature == "broadcast"
                        ? _c("UserTipsBroadcast", {
                            attrs: { tip: _vm.tip },
                            on: {
                              submited: function ($event) {
                                return _vm.cancel_form(true)
                              },
                            },
                          })
                        : _vm.tip.nature == "billingStatus"
                        ? _c("UserTipsBillingStatus", {
                            attrs: { tip: _vm.tip },
                          })
                        : _c("UserTipsMessage", { attrs: { tip: _vm.tip } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "min-width": "100", color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancel_form(false)
                        },
                      },
                    },
                    [_vm._v(" Fermer ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }