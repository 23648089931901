var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticClass: "pa-2",
      attrs: { timeout: _vm.timeout, bottom: "", right: "" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "span",
                _vm._b(
                  {
                    staticClass: "pa-2",
                    staticStyle: { cursor: "pointer" },
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.notification.open = false
                      },
                    },
                  },
                  "span",
                  attrs,
                  false
                ),
                [_vm._v("X")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.notification.open,
        callback: function ($$v) {
          _vm.$set(_vm.notification, "open", $$v)
        },
        expression: "notification.open",
      },
    },
    [
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notification.context === "success",
              expression: "notification.context === 'success'",
            },
          ],
          staticClass: "pr-3",
          attrs: { color: "success" },
        },
        [_vm._v("mdi-check")]
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notification.context === "error",
              expression: "notification.context === 'error'",
            },
          ],
          staticClass: "pr-3",
          attrs: { color: "error" },
        },
        [_vm._v("mdi-alert-circle")]
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notification.context === "warning",
              expression: "notification.context === 'warning'",
            },
          ],
          staticClass: "pr-3",
          attrs: { color: "warning" },
        },
        [_vm._v("mdi-alert-circle")]
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notification.context === "info",
              expression: "notification.context === 'info'",
            },
          ],
          staticClass: "pr-3",
        },
        [_vm._v("mdi-information")]
      ),
      _vm._v(" " + _vm._s(_vm.notification.text) + " "),
      _c("v-progress-linear", {
        attrs: {
          absolute: "",
          bottom: "",
          color: "secondary",
          value: _vm.progressValue,
          reverse: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }