<template>
  <div class="flex-grow-1">
    <!-- Navbars -->
    <Navbar-desktop :currentBu="currentBu" v-if="renderComponent && currentBu && $vuetify.breakpoint.lgAndUp" />

    <Navbar-mobile v-if="renderComponent && currentBu && $vuetify.breakpoint.mdAndDown" />

    <v-main>
      <div v-if="renderComponent && currentBu">
        <slot v-if="localeOk"></slot>
      </div>

      <v-dialog v-model="modalCGV" fullscreen hide-overlay transition="dialog-bottom-transition">
        <CGV @close="modalCGV = false" />
      </v-dialog>
      <!-- <ScrollTop /> -->
    </v-main>
    <HorusDesktopMenu />
    <!-- <ZendeskInit /> -->
  </div>
</template>

<script>
// layout imports >>
import { mapState, mapGetters } from "vuex";
import shop from "@/class/shop";

// navigation menu configurations
import config from "@/configs";
// import ScrollTop from "@/tools/scrollTop";
import HorusDesktopMenu from "@/components/horusDesktop/HorusDesktopMenu";
import NavbarDesktop from "../components/navigation/NavbarDesktop.vue";
import NavbarMobile from "../components/navigation/NavbarMobile.vue";
// import ZendeskInit from "@/components/zendesk/ZendeskInit.vue";
import Setting from "@/class/setting.js";

export default {
  name: "DefaultLayout",
  components: {
    CGV: () => import("@/components/common/CGV"),
    // ScrollTop,
    HorusDesktopMenu,
    NavbarDesktop,
    NavbarMobile,
    // ZendeskInit,
  },
  data() {
    return {
      showDialogShop: false,
      drawer: false,
      navigation: config.navigation,
      modalCGV: false,
      tenantsApiKey: "",
      localeOk: false,
      renderComponent: true,
      colorHorusDesktop: "red",
      countFaultHorusDesktop: 0,
      msgHorusDesktop: "-- Hors line --",
      appName: "Adhérent",
    };
  },
  computed: {
    ...mapGetters([
      "userAuth",
      "currentBu",
      "cookie",
      "version",
      "currentShopInfo",
    ]),
    ...mapState("app", ["isContentBoxed", "toolbarTheme", "isToolbarDetached"]),
  },
  watch: {
    currentBu() {
      if (this.currentBu != null) {
        this.assign_local_language();
        if (this.currentBu.tenant.type != "TENANT") {
          // this.shop_type();
          this.forceRerender();
        }
      }
    },
  },

  mounted() {
    // this.shop_type();
    this.assign_local_language();
    this.tenantsApiKey = this.currentBu;
    document.title = this.appName + " - " + this.currentBu.tenant.mnemo;

    this.get_user_preferences(this.currentBu.tenant.apiKey);
  },

  methods: {
    async get_user_preferences(apiKey) {
      const ClassSetting = new Setting();
      const response = await ClassSetting.actionsGet(
        apiKey,
        "user.preferences.automaticHealthOpen",
        null
      );
      if (response.value != null) {
        this.$store.commit("upd_user_preferences", {
          automaticHealthOpen: response.value,
        });
      } else {
        this.$store.commit("upd_user_preferences", {
          automaticHealthOpen: false,
        });
      }
    },

    open_shop() {
      if (this.currentShopInfo.needInitialisation) {
        this.showDialogShop = true;
      }
    },

    async shop_type() {
      try {
        this.$store.commit("update_currentShopInfo", {});
        this.shopServices = new shop();

        const response = await this.shopServices.getCurrentShop(
          this.currentBu.tenant.apiKey
        );
        this.$store.commit("update_currentShopInfo", response);

        if (
          response.specialityDefined == false ||
          (response.specialityDefined && response.country == null)
        ) {
          this.showDialogShop = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.open_shop();
      }
    },

    close_dialog() {
      this.showDialogShop = false;
    },

    // layout functions >>
    route_to_homepage() {
      if (this.$route.name != "myOsmose") {
        this.$router.push({ name: "myOsmose" });
      }
    },

    assign_local_language() {
      this.localeOk = false;
      if (
        this.userAuth.locale.split("_")[0] == "fr" ||
        this.userAuth.locale == "fr"
      ) {
        this.$i18n.locale = "fr";
        this.$moment.locale("fr");
      } else {
        this.$i18n.locale = "en";
        this.$moment.locale("en");
      }
      this.localeOk = true;
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    checkPagesQuote() {
      if (
        ["auditoryQuote", "equipmentQuote", "loanQuote"].includes(
          this.$route.name
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
