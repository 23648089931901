<template>
  <v-card
    :color="color"
    :class="allowRedirection ? 'cursor-pointer' : ''"
    :style="outlined ? 'border: 1px solid var(--v-primary-base)' : ''"
    @click="go_to_company_page(currentCompany.companyUUID)"
  >
    <v-card-title
      v-if="showTitle"
      style="font-weight: 450"
      class="d-flex justify-space-between subtitle-1 grey--text"
    >
      Société associée

      <v-btn
        color="primary"
        small
        @click="$emit('edit')"
        v-if="
          showEditButton &&
          $authorization.validateGrant('manageMember@brandpro')
        "
        >Modifier</v-btn
      >
    </v-card-title>
    <v-card-text class="d-flex justify-space-between">
      <div>
        <p class="subtitle-1 info--text mb-0">
          {{ currentCompany.socialReason }} -
          {{ currentCompany.code }}
        </p>
        <p
          class="subtitle-2 primary--text mb-0"
          v-if="currentCompany.address != null"
        >
          {{ currentCompany.address.postal.city.city }}
          {{ currentCompany.address.postal.city.postCode }}
        </p>
        <p class="caption grey--text mb-0">
          <span v-if="currentCompany.siren != null">
            SIREN {{ currentCompany.siren }} &nbsp;
          </span>
          <span v-if="currentCompany.vatNumber != null">
            TVA {{ currentCompany.vatNumber }} &nbsp;
          </span>
          <span v-if="currentCompany.rcs != null"
            >RCS {{ currentCompany.rcs }} &nbsp;
          </span>
        </p>
      </div>
      <div>
        <v-btn
          v-if="$authorization.validateGrant('manageMember@brandpro')"
          color="primary"
          small
          @click.stop="showChangeCompany = true"
          >Changer de société</v-btn
        >
      </div>
    </v-card-text>
    <DialogChangeCompany
      v-if="showChangeCompany"
      :showDialog.sync="showChangeCompany"
      :currentCompany="currentCompany"
      @refresh="$emit('refresh')"
    />
  </v-card>
</template>

<script>
import DialogChangeCompany from "./DialogChangeCompany";

export default {
  name: "CompanySummaryCard",

  components: {
    DialogChangeCompany,
  },

  data() {
    return {
      showChangeCompany: false,
    };
  },

  props: {
    currentCompany: {
      type: Object,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    allowRedirection: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "bglvl1",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    go_to_company_page(companyUUID) {
      if (!this.allowRedirection) return;
      this.$router.push({
        name: "detailsCompany",
        params: {
          companyUUID: companyUUID,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
