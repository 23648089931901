import Vue from "vue";

Vue.filter("formatLabelAddress", formatLabelAddress);

export function formatLabelAddress(address) {
  // console.log("address :>> ", address);
  if (
    address.postal.buildingInfo == null ||
    address.postal.buildingInfo == ""
  ) {
    address.postal.label =
      address.postal.name +
      " " +
      address.postal.city.postCode +
      " " +
      address.postal.city.city;
  } else {
    address.postal.label =
      address.postal.name +
      " " +
      address.postal.buildingInfo +
      " " +
      address.postal.city.postCode +
      " " +
      address.postal.city.city;
  }
  if (address.postal.city.country != null) {
    address.postal.label =
      address.postal.label + " " + address.postal.city.country;
  }
  if (address.postal.label.trim() == "") {
    address = null;
  }
  return address;
}
