import axios from "axios";
// import store from "../store/index.js";

let urlApiHorus = process.env.VUE_APP_URL_HORUS;

export default class gouvFr {
  async postal(apiKey, postalValue) {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("query", postalValue);

    let request = await axios.post(
      urlApiHorus + "static/postal/fr/postals",
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search(apiKey, libellePattern) {
    let request = await axios.post(
      urlApiHorus + "static/organization/fr/legal/search",
      {
        pattern: libellePattern,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_info_by_siren(siren) {
    let request = await axios.get(
      urlApiHorus + "/static/organization/fr/legal?identifier=" + siren
    );
    return request.data;
  }

  async get_info_by_siret(siret) {
    let request = await axios.get(
      urlApiHorus + "/static/organization/fr/office?identifier=" + siret
    );
    return request.data;
  }

  async get_all_countries() {
    let request = await axios.get(urlApiHorus + "/static/country");
    return request.data;
  }
}
