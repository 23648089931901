import Vue from "vue";

Vue.prototype.$formatTableOrders = function (tableOptions, arrMapping) {
  if (tableOptions.sortBy.length > 0) {
    const orders = [];
    for (let i = 0; i < tableOptions.sortBy.length; i++) {
      let tableFilter = null;
      let sortOrder = "asc";
      const sortKey = tableOptions.sortBy[i];
      tableFilter = arrMapping.filter((e) => e.front == sortKey)[0].back;
      sortOrder = tableOptions.sortDesc[i] === true ? "desc" : "asc";
      orders.push({ key: tableFilter, direction: sortOrder });
    }
    return orders;
  } else {
    return null;
  }
};
