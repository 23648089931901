import Vue from "vue";

Vue.filter("validPhone", validPhone);
//NOTE Temporary solution but will need to be changed for a better one that will be more flexible about countries formats
export function validPhone(phone) {
  if (phone.num == null) return true;
  if (
    phone.num.length == 0 ||
    (phone.num.startsWith("0") && phone.num.length == 10) ||
    (phone.num.startsWith("+33") && phone.num.length == 12)
  ) {
    return true;
  }
  return false;
}
