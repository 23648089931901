<template>
  <v-menu
    v-if="!loadInit"
    ref="menuDate"
    v-model="menuDate"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="refInputDate"
        v-mask="'##-##-####'"
        v-model="inputDate"
        :name="name"
        :placeholder="placeholder"
        :hint="hint"
        :persistent-hint="persistentHint"
        @change="assign_date_to_picker"
        :rules="check_props_rules()"
        :disabled="disabled"
        :outlined="outlined"
        :dense="dense"
        @update:error="upd_error"
        :class="onWarning ? 'warning--text warnColor' : ''"
        :success="onWarning"
        :success-messages="warningMessage"
        :clearable="clearable"
        :readonly="readonly"
        :hide-details="hideDetails"
        autcomplete="off"
        aria-autocomplete="off"
      >
        <template v-slot:append-inner>
          <v-icon :color="onWarning ? 'warning' : ''">mdi-clear</v-icon>
        </template>
        <template v-slot:label>
          <span :class="onWarning ? 'warning--text' : ''">{{ dateLabel }}</span>
        </template>
        <template v-slot:message="{ message }">
          <span :class="onWarning ? 'warning--text' : ''">{{ message }}</span>
        </template>
        <template v-slot:prepend-inner v-if="!readonly">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            :color="isError ? 'error' : 'primary'"
            :class="onWarning ? 'warning--text' : ''"
          >
            mdi-calendar
          </v-icon>
        </template>
        <template v-slot:append v-if="predefinedDate">
          <v-menu offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
                :color="isError ? 'error' : 'primary'"
                :class="onWarning ? 'warning--text' : ''"
                >mdi-calendar-text</v-icon
              >
            </template>
            <v-card width="300" class="pa-1">
              <v-row dense class="pb-2">
                <v-col cols="5">
                  <v-card
                    v-for="(item, index) in predefinedDateListOffset"
                    :key="index"
                    style="text-align: start; cursor: pointer"
                    small
                    @click="preselect_date(item)"
                    class="pa-1 mb-1 caption hovering"
                    elevation="0"
                    >{{ item.name }}</v-card
                  >
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="6">
                  <v-row dense>
                    <v-col
                      :cols="number_col(item)"
                      v-for="(item, index) in predefinedDateList"
                      :style="apply_max_width(item)"
                      style="max-height: 33px"
                      align-self="center"
                      :key="index"
                    >
                      <v-card
                        v-if="item.icon == null"
                        style="
                          background-color: transparent;
                          white-space: nowrap;
                          padding-top: 4px;
                        "
                        class="caption hovering"
                        elevation="0"
                        >{{ item.name }}</v-card
                      >
                      <v-icon
                        v-if="item.icon != null"
                        style="cursor: pointer"
                        class="justify-end hovering"
                        size="20"
                        @click="preselect_date(item)"
                        >{{ item.icon }}</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <!-- <v-list class="px-1">
              <v-list-item
                v-for="(item, index) in predefinedDateList"
                :key="index"
                @click="preselect_date(item)"
              >
                <v-list-item-title
                  @click="preselect_date(item)"
                  class="cursor-pointer primary--text body-2"
                  >{{ item.name }}</v-list-item-title
                >
              </v-list-item>
            </v-list> -->
          </v-menu>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="pickerDate"
      ref="picker"
      :max="
        birthdayMode
          ? new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          : ''
      "
      :min="birthdayMode ? '1940-01-01' : ''"
      :active-picker.sync="activePicker"
      color="primary"
      locale="fr-FR"
      @input="menuDate = false"
      @change="assign_date_to_input"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  name: "InputDate",
  directives: { mask },
  props: {
    // V-model >>
    dateModel: {
      type: [String, Number], // Expected date (Number == timestemp, String == ISO).
      require: true, //NOTE ".sync" require
    },
    // Display settings >>
    dateLabel: {
      type: String,
      default: "Date",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Ex: 01-12-2021 (jour-mois-année)",
    },
    hint: {
      type: String,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "inputDate",
    },
    predefinedDate: {
      type: Boolean, // Activate the quick selection menu.
      default: false,
    },
    birthdayMode: {
      type: Boolean,
      default: false,
    },
    // Style
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    // Warning (they activate a non-blocking warning) >>
    todayMinWarning: {
      type: Boolean,
      default: false,
    },
    todayMaxWarning: {
      type: Boolean,
      default: false,
    },
    minDateWarning: {
      type: [String, Number], // Expected date (Number == timestemp, String == ISO).
      default: null,
    },
    minDateWarningMsg: {
      type: String, // !!! this props is strongly recommended if "minDateWarning" is active.
      default:
        "Attention ! La date saisie est trop basse. (ne bloque pas la validation)",
    },
    maxDateWarning: {
      type: [String, Number], // Expected date (Number == timestemp, String == ISO).
      default: null,
    },
    maxDateWarningMsg: {
      type: String, // !!! this props is strongly recommended if "maxDateWarning" is active.
      default:
        "Attention ! La date saisie est trop haute. (ne bloque pas la validation)",
    },
    // Rules (they activate blocking rules) >>
    required: {
      type: Boolean,
      default: false,
    },
    requiredErrorMsg: {
      type: String,
      default: "Ce champ est requis",
    },
    maxDate: {
      type: [String, Number], // Expected date (Number == timestemp, String == ISO).
      default: null,
    },
    maxDateMsg: {
      type: String, // is used only for maxDate.
      default: "La date saisie est trop haute",
    },
    minDate: {
      type: [String, Number], // Expected date (Number == timestemp, String == ISO).
      default: null,
    },
    minDateMsg: {
      type: String, // is used only for minDate.
      default: "La date saisie est trop basse.",
    },
    todayMin: {
      type: Boolean,
      default: false,
    },
    todayMax: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    majority: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // Functional
    outputDate: {
      get() {
        return this.dateModel;
      },
      set(value) {
        this.$emit("update:dateModel", value);
      },
    },
    pickerDateConvertFR() {
      return this.$options.filters.formatDate(this.pickerDate, "DD-MM-YYYY");
    },
    formDateConvertEN() {
      return this.format_date_to_en(this.inputDate);
    },
    // Utils
    today() {
      return new Date().toISOString().substring(0, 10);
    },
    // warnings
    warnMinToday() {
      if (this.todayMinWarning) {
        return {
          ruleStatus: this.check_minToday_rule(this.inputDate),
          message:
            "Attention ! La date saisie est inférieure à celle du jour ! (ne bloque pas la validation)",
        };
      } else return { ruleStatus: true, message: "" };
    },
    warnMaxToday() {
      if (this.todayMaxWarning) {
        return {
          ruleStatus: this.check_maxToday_rule(this.inputDate),
          message:
            "Attention ! La date saisie est suppérieur à celle du jour ! (ne bloque pas la validation)",
        };
      } else return { ruleStatus: true, message: "" };
    },
    warnMinDate() {
      if (this.minDateWarning != null) {
        return {
          ruleStatus: this.check_minDateProps_rule(
            this.inputDate,
            this.minDateWarning
          ),
          message: this.minDateWarningMsg,
        };
      } else return { ruleStatus: true, message: "" };
    },
    warnMaxDate() {
      if (this.maxDateWarning != null) {
        return {
          ruleStatus: this.check_maxDateProps_rule(
            this.inputDate,
            this.maxDateWarning
          ),
          message: this.maxDateWarningMsg,
        };
      } else return { ruleStatus: true, message: "" };
    },
    warningActivation() {
      if (!this.warnMinToday.ruleStatus) return this.warnMinToday;
      if (!this.warnMaxToday.ruleStatus) return this.warnMaxToday;
      if (!this.warnMinDate.ruleStatus) return this.warnMinDate;
      if (!this.warnMaxDate.ruleStatus) return this.warnMaxDate;
      else return { ruleStatus: true, message: "" };
    },
  },
  watch: {
    outputDate: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.init_date_model();
        }
      },
      deep: true,
    },

    warningActivation(newVal) {
      if (newVal.ruleStatus == false) {
        this.onWarning = true;
        this.warningMessage = newVal.message;
      } else {
        this.onWarning = false;
        this.warningMessage = "";
      }
    },

    menuDate(val) {
      val &&
        this.birthdayMode &&
        setTimeout(() => {
          this.activePicker = "YEAR";
          this.inputDate = this.$options.filters.formatDate(
            this.default_birthday_date(),
            "DD-MM-YYYY"
          );
          this.pickerDate = this.$options.filters.formatDate(
            this.default_birthday_date(),
            "YYYY-MM-DD"
          );
        });
    },
  },
  data() {
    return {
      loadInit: true,
      isError: false,
      onWarning: false,
      warningMessage: "",
      menuDate: false,
      inputDate: null,
      activePicker: "DATE",
      pickerDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      rules: {
        required: (v) => !!v || this.requiredErrorMsg,
        maxLength: (len) => (v) =>
          (v || "").length <= len || `${len} caractères maximum`,
        incomplete: (v) =>
          this.check_incomplete_rule(v) || "La saisie est incompléte.",
        checkFrFormat: (v) =>
          this.checkFrFormat(v) ||
          "Format invalide. Exemple pour le 2 mars 2021 le champ doit correspondre à : 02-03-2021",
        maxDateProps: (v) =>
          this.check_maxDateProps_rule(v, this.maxDate) || this.maxDateMsg,
        minDateProps: (v) =>
          this.check_minDateProps_rule(v, this.minDate) || this.minDateMsg,
        minDateToday: (v) =>
          this.check_minToday_rule(v) ||
          `La date saisie doit être suppérieur ou égale à la date d'aujourd'hui`,
        maxDateToday: (v) =>
          this.check_maxToday_rule(v) ||
          `La date saisie doit être inférieur ou égale à la date d'aujourd'hui`,
        majorityMandatory: (v) =>
          this.check_majority_rule(v) || `La personne doit être majeure`,
      },
      predefinedDateList: [
        { name: "Janvier" },
        { icon: "mdi-page-first", unit: "date", day: 1, month: 1 },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 1,
        },
        { name: "Fevrier" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 2,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: this.bisextil_year(),
          month: 2,
        },
        { name: "Mars" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 3,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 3,
        },
        { name: "Avril" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 4,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 30,
          month: 4,
        },
        { name: "Mai" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 5,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 5,
        },
        { name: "Juin" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 6,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 30,
          month: 6,
        },
        { name: "Juillet" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 7,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 7,
        },
        { name: "Aout" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 8,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 8,
        },
        { name: "Septembre" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 9,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 30,
          month: 9,
        },
        { name: "Octobre" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 10,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 10,
        },
        { name: "Novembre" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 11,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 30,
          month: 11,
        },
        { name: "Decembre" },
        {
          icon: "mdi-page-first",
          unit: "date",
          day: 1,
          month: 12,
        },
        {
          icon: "mdi-page-last",
          unit: "date",
          day: 31,
          month: 12,
        },
      ],
      predefinedDateListOffset: [
        { name: "Aujourd'hui", unit: "day", offset: 0 },
        { name: "Demain", unit: "day", offset: 1 },
        { name: "Dans 1  semaine", unit: "day", offset: 7 },
        { name: "Dans 1 mois", unit: "month", offset: 1 },
        { name: "Dans 2 mois", unit: "month", offset: 2 },
        { name: "Dans 3 mois", unit: "month", offset: 3 },
        { name: "Dans 6 mois", unit: "month", offset: 6 },
        { name: "Dans 1 an", unit: "year", offset: 1 },
      ],
    };
  },
  mounted() {
    this.loadInit = true;
    this.init_date_model();
    this.bisextil_year();
    this.loadInit = false;
  },
  methods: {
    apply_max_width(item) {
      if (item.icon != null) {
        return "max-width:40px; padding-left:16x";
      }
    },
    number_col(item) {
      if (item.icon != null) {
        return 3;
      } else {
        return 6;
      }
    },
    bisextil_year() {
      let year = new Date().getFullYear();
      if (year % 4 == 0) {
        return 29;
      } else {
        return 28;
      }
    },
    init_date_model() {
      this.inputDate = this.$options.filters.formatDate(
        this.outputDate,
        "DD-MM-YYYY"
      );
      this.pickerDate = this.$options.filters.formatDate(
        this.outputDate,
        "YYYY-MM-DD"
      );
    },

    assign_date_to_input() {
      this.inputDate = this.pickerDateConvertFR;
      this.outputDate = this.pickerDate;
    },
    assign_date_to_picker() {
      if (this.$refs.refInputDate.validate()) {
        this.pickerDate = this.format_date_to_en(this.inputDate);
        this.outputDate = this.pickerDate;
      }
      this.$emit("change");
    },
    format_date_to_en(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    convert_tsDate_and_isoDate(pDate) {
      let date = pDate;
      if (typeof pDate == "number") {
        date = new Date(pDate).toISOString();
      }
      return date.substring(0, 10);
    },

    default_birthday_date() {
      let date = new Date().getFullYear();
      let year = date - 40;
      let nDate = new Date(year.toString());
      return nDate.toISOString().substring(0, 10);
    },

    upd_error(e) {
      this.isError = e;
    },

    // Rules
    check_props_rules() {
      let rules = [];
      rules.push(this.rules.checkFrFormat);
      rules.push(this.rules.incomplete);

      // props rules >>
      if (this.required) {
        rules.push(this.rules.required);
      }
      if (this.maxDate != null) {
        rules.push(this.rules.maxDateProps);
      }
      if (this.minDate != null) {
        rules.push(this.rules.minDateProps);
      }
      if (this.todayMin) {
        rules.push(this.rules.minDateToday);
      }
      if (this.todayMax) {
        rules.push(this.rules.maxDateToday);
      }
      if (this.majority) {
        rules.push(this.rules.majorityMandatory);
      }
      return rules;
    },

    checkFrFormat(v) {
      let checkRules = false;
      if (v && v.length == 10) {
        const day = v.substring(0, 2);
        const month = v.substring(3, 5);
        // const year = v.substring(6, 10);
        let checkDay = () => {
          return day > 0 && day <= 31;
        };
        let checkMonth = () => {
          return month > 0 && month <= 12;
        };
        if (checkDay() && checkMonth()) {
          checkRules = true;
        }
      } else {
        return true;
      }
      return checkRules;
    },

    check_maxDateProps_rule(v, pDate) {
      let check = true;
      if (v && v.length == 10) {
        let date = this.format_date_to_en(v);
        if (date > this.convert_tsDate_and_isoDate(pDate)) {
          check = false;
        }
      }
      return check;
    },
    check_minDateProps_rule(v, pDate) {
      let check = true;
      if (v && v.length == 10) {
        let date = this.format_date_to_en(v);
        if (date < this.convert_tsDate_and_isoDate(pDate)) {
          check = false;
        }
      }
      return check;
    },
    check_minToday_rule(v) {
      let check = true;
      if (v && v.length == 10) {
        let date = this.format_date_to_en(v);
        if (date < this.today) {
          return false;
        }
      }
      return check;
    },
    check_maxToday_rule(v) {
      let check = true;
      if (v && v.length == 10) {
        let date = this.format_date_to_en(v);
        if (date > this.today) {
          return false;
        }
      }
      return check;
    },
    check_majority_rule(v) {
      let check = true;
      if (v && v.length == 10) {
        let date = this.format_date_to_en(v);
        let dateNow = new Date();
        let dateNowYear = dateNow.getFullYear();
        let dateNowMonth = dateNow.getMonth();
        let dateNowDay = dateNow.getDate();
        let dateBirth = new Date(date);
        let dateBirthYear = dateBirth.getFullYear();
        let dateBirthMonth = dateBirth.getMonth();
        let dateBirthDay = dateBirth.getDate();
        let age = dateNowYear - dateBirthYear;
        let m = dateNowMonth - dateBirthMonth;
        if (m < 0 || (m === 0 && dateNowDay < dateBirthDay)) {
          age--;
        }
        if (age < 18) {
          check = false;
        }
      }
      return check;
    },
    check_incomplete_rule(v) {
      let check = true;
      if (v && v.length > 0) {
        if (v.length < 10) {
          check = false;
        }
      }
      return check;
    },

    // Menu
    preselect_date(item) {
      if (item.unit == "day") {
        this.inputDate = this.$options.filters.formatDate(
          new Date().setDate(new Date().getDate() + item.offset),
          "DD-MM-YYYY"
        );
      } else if (item.unit == "month") {
        this.inputDate = this.$options.filters.formatDate(
          new Date().setMonth(new Date().getMonth() + item.offset),
          "DD-MM-YYYY"
        );
      } else if (item.unit == "year") {
        this.inputDate = this.$options.filters.formatDate(
          new Date().setFullYear(new Date().getFullYear() + item.offset),
          "DD-MM-YYYY"
        );
      } else if (item.unit == "date") {
        // NOTE https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        this.inputDate = this.$options.filters.formatDate(
          new Date(
            new Date().getFullYear() + "/" + item.month + "/" + item.day
          ),
          "DD-MM-YYYY"
        );
      }

      this.pickerDate = this.format_date_to_en(this.inputDate);
      this.outputDate = this.pickerDate;
    },
  },
};
</script>

<style scoped>
.warnColor .success--text {
  color: var(--v-warning-base) !important;
}
.hovering:hover {
  background-color: #eeeeee;
}
</style>
