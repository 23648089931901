var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "900", "max-width": "900" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "bgLvl3" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(
                " " +
                  _vm._s(_vm._f("capitalize")(_vm.$t("support.helpSupport"))) +
                  " "
              ),
              _c("v-spacer"),
              _c("span", { staticClass: "primary--text caption" }, [
                _vm._v(
                  " Build du " +
                    _vm._s(_vm._f("formatDate")(_vm.buildDate, "LLL")) +
                    " " +
                    _vm._s(_vm._f("capitalize")(_vm.$t("utility.version"))) +
                    " " +
                    _vm._s(_vm.version)
                ),
              ]),
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm.isConnect
              ? _c("span", { staticClass: "subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.currentBu.tenant.label) + " "),
                ])
              : _vm._e(),
          ]),
          _c("Card-info-support", { attrs: { isConnect: _vm.isConnect } }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close_modals()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("capitalize")(_vm.$t("form.close"))) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }