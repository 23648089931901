import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Member from "@/class/member";
import Company from "@/class/company";
import Laboratory from "@/class/laboratory";

import router from "../router";

// Global vuex
import AppModule from "./app";

Vue.use(Vuex);

// VuexPersistence garde les element suivant dans le store apres un refresh de la page
const vuexLocal = new VuexPersistence({
  key: "OsmoseMemberStore",
  storage: window.sessionStorage,
  reducer: (state) => ({
    themeDark: state.themeDark,
    userConnected: state.userConnected,
    userAuth: state.userAuth,
    currentBu: state.currentBu,
    currentShopInfo: state.currentShopInfo,
    specialitiesBU: state.specialitiesBU,
    noChangePass: state.noChangePass, // If true, no open popup change pass.
    cookie: state.cookie,
    querySignIn: state.querySignIn,
    pingHorusDesktop: state.pingHorusDesktop,
    currentQuoteUUID: state.currentQuoteUUID,
    currentFreeQuoteUUID: state.currentFreeQuoteUUID,
    currentQuoteEquipmentUUID: state.currentQuoteEquipmentUUID,
    currentPayment: state.currentPayment,
    buildDate: state.buildDate,
    showUserTutorials: state.showUserTutorials,
    supplierEntity: state.supplierEntity,
    userPreferences: state.userPreferences,

    customTheme: state.customTheme,

    currentInventory: state.currentInventory,
  }),
});

// This function defines the default values of the state.
const getDefaultState = () => {
  return {
    version: "1.0",
    buildDate: null,
    themeDark: false,
    // Auth data
    userConnected: false,
    userAuth: {
      displayName: null,
      locale: null,
      login: null,
      loginDate: null,
      nextRenewCredentialsDate: null,
      token: null,
    },
    showUserTutorials: {
      opportunity: true,
      campaign: true,
      noah: true,
      calendar: true,
    },
    userPreferences: {
      automaticHealthOpen: false,
    },
    supplierEntity: null,
    // Essentials
    currentBu: null,
    specialitiesBU: [
      { label: "Optique", key: "OPTIC" },
      { label: "Audio", key: "HEARING" },
    ],
    currentShopInfo: null,
    booleanUpdMenuNav: false,
    // Others
    notification: {
      text: null,
      context: null,
      open: false,
    },
    fastTracks: [],
    noChangePass: false, // If true, no open popup change pass.
    modalCGV: false,
    cookie: false,
    querySignIn: {},
    savedSearchPerimeter: 'mainMember',

    // After template

    allWidgetsPages: {
      todoFolders: false,
      userAlerts: false,
      userShortcuts: false,
      dailyAppointments: false,
      alerts: false,
      folders: false,
      storyPast: false,
      storyFuture: false,
      consummables: false,
      balancePatient: false,
      devicesPatient: false,
      devicesPatientHistory: false,
      currentFolders: false,
      foldersHistory: false,
      currentInvoices: false,
      invoicesHistory: false,
      widgetStoryFutureAuditory: false,
      widgetStoryPastAuditory: false,
      wigdetNoah: false,
      widgetSesamVitale: false,
    },

    // racine Dialogs
    openDialogCalendar: false,
    openDialogConsultationSV: false,
    openDialogDashboard: false,
    openDialogOpportunity: false,
    openDialogBackOffice: {
      open: false,
      choosenPage: {
        pageKey: null,
        parentPageKey: null,
        filterKey: null,
        actionSearch: null,
      },
    },
    openDialogManagementCampaign: {
      open: false,
      entityUUID: null,
    },
    storedMember: null,
    storedCompany: null,
    storedLaboratory: null,
    fullPageDialog: {
      title: "",
      component: "",
    },
    openDialogNote: {
      open: false,
      entityUUID: null,
    },
    refreshProgressNote: false,
    // horus desktop
    pingHorusDesktop: true,
    statusDesktop: false,
    statusAreaFse: false,
    listScans: [],
    listPrinters: [],
    currentProfil: {
      profilName: "Profil par défaut",
      printers: null,
      scanners: null,
    },
    newScan: null,
    // quotes
    currentQuoteUUID: null,
    currentQuoteAuditory: null,
    currentFreeQuoteUUID: null, // freeQuote
    freeQuoteSuggestedPatient: null,
    currentPayment: null, // payment
    //  EquipmentQuote
    currentQuoteEquipmentUUID: null,
    currentQuoteEquipment: null,
    openSelectTenant: false, // force external opening select tenant.

    customTheme: null,

    currentInventory: null,
  };
};

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: getDefaultState(), // get the initial state.
  getters: {
    // TODO Attention si getter envelé, penser à changer les appels : ...mapGetters => ...mapState .
    version: (state) => state.version, // TODO getters à virer.
    buildDate: (state) => state.buildDate,
    // Auth data
    userConnected: (state) => state.userConnected, // TODO getters à virer.
    userAuth: (state) => state.userAuth, // TODO getters à virer.
    // Auth
    noChangePass: (state) => state.noChangePass, // If true, no open popup change pass.
    querySignIn: (state) => state.querySignIn, // Save message errors for interceptor.
    // Essential
    currentBu: (state) => state.currentBu, // TODO getters à virer.
    apiKey: (state) => state.currentBu.tenant.apiKey, // TODO voir pour le mettre dans le main.js (axios header), dans ce cas, devra être réatribuer au changement de BU, et sur certaine reqete devra être enlevé.
    currentShopInfo: (state) => state.currentShopInfo, // TODO getters à virer.
    // Others
    notification: (state) => state.notification, // TODO getters à virer.
    modalCGV: (state) => state.modalCGV, // TODO getters à virer.
    cookie: (state) => state.cookie, // TODO getters à virer.
    // After template
    openDialogCalendar: (state) => state.openDialogCalendar, // TODO getters à virer.
    openDialogDashboard: (state) => state.openDialogDashboard, // TODO getters à virer.
    openDialogManagementCampaign: (state) => state.openDialogManagementCampaign, // TODO getters à virer.
    fullPageDialog: (state) => state.fullPageDialog, // TODO getters à virer.
    openDialogNote: (state) => state.openDialogNote, // TODO getters à virer.
    refreshProgressNote: (state) => state.refreshProgressNote,

    // horus desktop // TODO getters horus à regarder si utile.
    pingHorusDesktop: (state) => state.pingHorusDesktop,
    statusDesktop: (state) => state.statusDesktop,
    listScans: (state) => state.listScans,
    listPrinters: (state) => state.listPrinters,
    currentProfil: (state) => state.currentProfil,
    newScan: (state) => state.newScan,
  },
  mutations: {
    // buildDate
    upd_buildDate(state, buildDate) {
      state.buildDate = buildDate;
    },
    // Store function
    reset_all_state(state) {
      Object.assign(state, getDefaultState()); // Replace the current state by initial state.
    },
    // configs
    upd_theme_dark(state, bool) {
      state.themeDark = bool;
    },
    // Auth functions
    update_userConnected(state, userConnected) {
      state.userConnected = userConnected;
    },
    update_userAuth(state, userAuth) {
      state.userAuth = userAuth;
    },
    update_show_user_tutorials(state, data) {
      Object.entries(data).map((entry) => {
        state.showUserTutorials[entry[0]] = entry[1];
      });
    },
    upd_user_preferences(state, data) {
      Object.entries(data).map((entry) => {
        state.userPreferences[entry[0]] = entry[1];
      });
    },
    update_supplierEntity(state, supplierEntity) {
      state.supplierEntity = supplierEntity;
    },
    // Essential functions
    update_currentBu(state, currentBu) {
      state.currentBu = currentBu;
    },
    update_noChangePass: (state, noChangePass) => {
      state.noChangePass = noChangePass;
    },
    update_currentShopInfo: (state, info) => {
      state.currentShopInfo = info;
    },
    upd_menuNav_btn: (state, bool) => {
      state.booleanUpdMenuNav = bool;
    },

    // Others
    update_notif: (state, data) => {
      state.notification.text = data.text;
      state.notification.context = data.context;
      state.notification.open = true;
    },
    push_fast_tracks: (state, data) => {
      state.fastTracks.push(data);
    },
    delete_fast_track: (state, uuid) => {
      const index = state.fastTracks
        .map((x) => {
          return x.uuid;
        })
        .indexOf(uuid);
      state.fastTracks.splice(index, 1);
    },
    update_modalCGV: (state, modalCGV) => {
      state.modalCGV = modalCGV;
    },
    update_cookie: (state, cookie) => {
      state.cookie = cookie;
    },
    update_querySignIn: (state, querySignIn) => {
      state.querySignIn = querySignIn;
    },
    // After template

    update_pages_widgets: (state, data) => {
      Object.entries(data.updateWidgets).map((entry) => {
        if (state.allWidgetsPages.hasOwnProperty(entry[0])) {
          state.allWidgetsPages[entry[0]] = entry[1];
        }
      });
    },

    open_dialog_calendar: (state, bool) => {
      state.openDialogCalendar = bool;
    },
    open_dialog_consultation_SV: (state, bool) => {
      state.openDialogConsultationSV = bool;
    },
    open_dialog_dashboard: (state, bool) => {
      state.openDialogDashboard = bool;
    },
    open_dialog_opportunity: (state, bool) => {
      state.openDialogOpportunity = bool;
    },
    open_dialog_back_office: (state, data) => {
      state.openDialogBackOffice.open = data.open ? data.open : false;
      state.openDialogBackOffice.choosenPage = data.choosenPage
        ? data.choosenPage
        : {
            pageKey: null,
            parentPageKey: null,
            filterKey: null,
            actionSearch: null,
          };
    },
    open_dialog_management_campaign: (state, bool) => {
      state.openDialogManagementCampaign = bool;
    },
    upd_stored_member: (state, patient) => {
      state.storedMember = patient;
    },
    upd_stored_member_contacts: (state, contacts) => {
      state.storedMember.contacts = contacts;
    },
    upd_stored_company: (state, company) => {
      state.storedCompany = company;
    },
    upd_stored_laboratory: (state, laboratory) => {
      state.storedLaboratory = laboratory;
    },
    update_fullPageDialog: (state, fullPageDialog) => {
      state.fullPageDialog = fullPageDialog;
    },

    open_openDialogNote: (state, openDialogNote) => {
      state.openDialogNote = openDialogNote;
    },

    upd_refreshProgressNote: (state, refreshProgressNote) => {
      state.refreshProgressNote = refreshProgressNote;
    },
    // horus desktop
    upd_pingHorusDesktop: (state, pingHorusDesktop) => {
      state.pingHorusDesktop = pingHorusDesktop;
    },
    upd_statusDesktop: (state, statusDesktop) => {
      state.statusDesktop = statusDesktop;
    },
    upd_statusAreaFse: (state, bool) => {
      state.statusAreaFse = bool;
    },
    upd_listScans: (state, listScans) => {
      state.listScans = listScans;
    },
    upd_listPrinters: (state, listPrinters) => {
      state.listPrinters = listPrinters;
    },
    upd_currentProfil: (state, currentProfil) => {
      state.currentProfil = currentProfil;
    },
    upd_newScan: (state, newScan) => {
      state.newScan = newScan;
    },
    // quotes
    upd_current_quote_uuid: (state, quoteUUID) => {
      state.currentQuoteUUID = quoteUUID;
    },
    upd_current_quote_auditory: (state, quote) => {
      state.currentQuoteAuditory = quote;
    },
    // freeQuote
    update_current_freeQuote_uuid: (state, uuid) => {
      state.currentFreeQuoteUUID = uuid;
    },
    upd_freeQuote_SuggestedPatient: (state, data) => {
      state.freeQuoteSuggestedPatient = data;
    },
    // Payment
    upd_current_payment: (state, payment) => {
      state.currentPayment = payment;
    },
    // Equipment quote
    upd_current_quote_equipment_uuid: (state, quoteUUID) => {
      state.currentQuoteEquipmentUUID = quoteUUID;
    },
    upd_current_quote_equipment: (state, quote) => {
      state.currentQuoteEquipment = quote;
    },
    upd_open_select_tenant: (state, bool) => {
      state.openSelectTenant = bool;
    },

    upd_custom_theme: (state, newTheme) => {
      state.customTheme = newTheme;
    },

    upd_current_inventory: (state, inventory) => {
      state.currentInventory = inventory;
    },
  },

  actions: {
    reset_state: ({ state, commit }) => {
      // Backs up the data essential for operation
      const userAuth = JSON.parse(JSON.stringify(state.userAuth));
      const userConnected = JSON.parse(JSON.stringify(state.userConnected));
      const buildDate = structuredClone(state.buildDate);
      const customTheme = structuredClone(state.customTheme);
      // Delete the state
      commit("reset_all_state");
      // Re assigns the saved data
      commit("update_userAuth", userAuth);
      commit("update_userConnected", userConnected);
      commit("upd_buildDate", buildDate);
      commit("upd_custom_theme", customTheme);
    },

    refresh_member_details: async ({ state, commit }, memberUUID) => {
      let ClassMember = new Member();
      let resMember = await ClassMember.get_member_details(
        state.currentBu.tenant.apiKey,
        memberUUID
      );
      commit("upd_stored_member", resMember);
    },

    refresh_company_details: async ({ state, commit }, companyUUID) => {
      let ClassCompany = new Company();
      let resCompany = await ClassCompany.get_company_details(
        state.currentBu.tenant.apiKey,
        companyUUID
      );
      commit("upd_stored_company", resCompany);
    },

    refresh_laboratory_details: async ({ state, commit }, laboratoryUUID) => {
      let ClassLaboratory = new Laboratory();
      let resLaboratory = await ClassLaboratory.get_laboratory_details(
        state.currentBu.tenant.apiKey,
        laboratoryUUID
      );
      commit("upd_stored_laboratory", resLaboratory);
    },

    refresh_current_quote_auditory: async ({ state, commit }, quoteUUID) => {
      let quote = await ClassQuote.get_quote_details(
        state.currentBu.tenant.apiKey,
        quoteUUID
      );
      commit("upd_current_quote_auditory", quote);
      commit("upd_current_quote_uuid", quote.uuid);
    },

    add_fast_track: async ({ commit }, data) => {
      let defaultNotif = {
        uuid: Date.now().toString(36) + Math.random().toString(36).substring(2),
        title: null,
        actionKey: null,
        actionLabel: null,
        actionUUID: null,
        level: "INFO",
        duration: 20, // seconds.
        persistent: false,
      };
      Object.entries(data).map((entry) => {
        defaultNotif[entry[0]] = entry[1];
      });
      commit("push_fast_tracks", defaultNotif);
    },

    update_widgets: ({ commit }, widgetsToUpdate) => {
      const currentRoute = router.app._route.name;
      const wMyOsmose = [
        "todoFolders",
        "userAlerts",
        "userShortcuts",
        "dailyAppointments",
        "widgetSesamVitale",
      ];
      const wDetailsPatient = [
        "alerts",
        "storyPast",
        "storyFuture",
        "devicesPatient",
        "folders",
        "consummables",
        "balancePatient",
      ];
      const wPatientHistoryDevices = [
        "devicesPatient",
        "devicesPatientHistory",
      ];
      const wPatientHistoryFolders = ["currentFolders", "foldersHistory"];
      const wPatientHistoryInvoices = ["currentInvoices", "invoicesHistory"];
      const wPatientSpecialityAuditory = [
        "widgetStoryFutureAuditory",
        "widgetStoryPastAuditory",
        "wigdetNoah",
        "devicesPatient",
      ];
      let listPageWidgets;
      switch (currentRoute) {
        case "myOsmose":
          listPageWidgets = wMyOsmose;
          break;
        case "detailsMember":
          listPageWidgets = wDetailsPatient;
          break;
        case "patientHealthAuditory":
          listPageWidgets = wPatientSpecialityAuditory;
          break;
        case "patientHistoryDevices":
          listPageWidgets = wPatientHistoryDevices;
          break;
        case "patientHistoryInvoices":
          listPageWidgets = wPatientHistoryInvoices;
          break;
        case "patientHistoryQuotes":
          listPageWidgets = wPatientHistoryFolders;
          break;
        default:
          listPageWidgets = null;
          break;
      }

      if (listPageWidgets != null) {
        let updateWidgets = JSON.parse(JSON.stringify(widgetsToUpdate));

        for (var key in updateWidgets) {
          if (listPageWidgets.indexOf(key) === -1) {
            delete updateWidgets[key];
          }
        }
        commit("update_pages_widgets", {
          updateWidgets: updateWidgets,
        });
      }

      // Ex call function :
      // this.$store.dispatch("update_widgets", {
      //   widgetStoryFutureAuditory: true,
      //   widgetStoryPastAuditory: true,
      //   storyPast: true,
      //   storyFuture: true,
      // });
    },
  },

  modules: {
    app: AppModule,
  },
});

export default store;
