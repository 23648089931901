import axios from "axios";

let urlHorusDesktop = "http://localhost:1981";

export default class horusDesktop {
  async ping() {
    let info = await axios.get(urlHorusDesktop + "/desktop/admin/ping", {
      timeout: 5000, // timeout a 5 seconde
    });
    return info;
  }

  async status() {
    let status = await axios.get(urlHorusDesktop + "/desktop/status", {
      timeout: 5000, // timeout a 5 seconde
    });
    return status.data;
  }

  async version() {
    let version = await axios.get(urlHorusDesktop + "/desktop/admin/version");
    return version.data;
  }

  async open_noah(infoPatient) {
    let response = await axios.post(
      urlHorusDesktop + "/desktop/himsa/noah/open",
      infoPatient
    );
    return response.data;
  }
}
