<template>
  <v-list-item>
    <v-list-item-content>
      <v-row dense>
        <!-- Icon -->
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                @click.stop="edit_broadcast()"
                v-bind="attrs"
                v-on="on"
                >{{ check_icon(tip.underlying.messageType) }}</v-icon
              >
            </template>
            <span>Editer le message</span>
          </v-tooltip>
        </v-col>

        <v-col cols="11" class="d-flex justify-end align-center">
          <v-row dense justify="space-between">
            <!-- Text -->
            <v-col cols="12" md="9">
              <div class="title primary--text">
                {{ tip.underlying.messageShortDescription }}
              </div>

              <div class="info--text body-2">
                {{ tip.underlying.startDate | formatDate("DD MMM") }}
              </div>

              <div class="body-2">
                {{ tip.underlying.messageLongDescription }}
              </div>
            </v-col>
            <!-- Actions -->
            <v-col cols="12" md="3" class="d-flex justify-end align-center">
              <div v-if="tip.underlying.messageType == 'TIPS_OF_THE_DAY'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      @click.stop="
                        validate_tip(tip.underlying.documentUUID, 'ACKNOWLEDGE')
                      "
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <v-icon>mdi-eye-off</v-icon>
                    </v-btn>
                  </template>
                  <span>Ne plus voir</span>
                </v-tooltip>
              </div>

              <div v-if="tip.underlying.messageType == 'ACKNOWLEDGE'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      small
                      @click.stop="
                        validate_tip(tip.underlying.documentUUID, 'ACKNOWLEDGE')
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-thumb-up</v-icon>
                    </v-btn>
                  </template>
                  <span>J'ai compris</span>
                </v-tooltip>
              </div>

              <div
                v-if="tip.underlying.messageType == 'AGREEMENT'"
                class="d-flex"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="
                        validate_tip(tip.underlying.documentUUID, 'REJECTED')
                      "
                      class="mr-1"
                      color="error"
                      small
                    >
                      <v-icon>mdi-thumb-down</v-icon>
                    </v-btn>
                  </template>
                  <span>Refuser</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="
                        validate_tip(tip.underlying.documentUUID, 'ACCEPTED')
                      "
                      color="success"
                      small
                    >
                      <v-icon>mdi-thumb-up</v-icon></v-btn
                    >
                  </template>
                  <span>Accepter</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-list-item-content>

    <Dialog-set-brodcast-message
      v-if="dialogSetBroadcast"
      :showDialog.sync="dialogSetBroadcast"
      @submited="$emit('refresh')"
      isEdit
      :editData="tip.underlying"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import Setting from "@/class/setting";
import DialogSetBrodcastMessage from "../DialogSetBrodcastMessage.vue";

export default {
  components: { DialogSetBrodcastMessage },
  name: "UserTipsBroadcast",
  props: { tip: Object },
  computed: {
    ...mapGetters(["apiKey"]),
  },
  data() {
    return {
      dialogSetBroadcast: false,
      ClassSetting: null,
    };
  },

  methods: {
    check_icon(messageType) {
      switch (messageType) {
        case "TIPS_OF_THE_DAY":
          return "mdi-lightbulb-on-outline";

        case "AGREEMENT":
          return "mdi-help-circle-outline";

        case "ACKNOWLEDGE":
          return "mdi-information-outline";
      }
    },

    edit_broadcast() {
      this.dialogSetBroadcast = true;
    },

    async validate_tip(tipUUID, ackType) {
      try {
        if (this.ClassSetting == null) {
          this.ClassSetting = new Setting();
        }
        await this.ClassSetting.broadcast_ack(this.apiKey, tipUUID, ackType);
        this.$emit("submited");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
