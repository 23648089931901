import axios from "axios";

export default class ClearCache {
  async build_version() {
    const request = await axios.get("meta.json", {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    });
    if (request) {
      return request.data.buildDate;
    } else return request;
  }
}
