var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-auto overflow-hidden" },
    [
      _c(
        "v-app-bar",
        { attrs: { color: "navPrimary", app: "" } },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { color: "white" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = true
              },
            },
          }),
          _c("v-spacer"),
          _c("Toolbar-user"),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            fixed: "",
            temporary: "",
            color: "navPrimary",
            width: "80vw",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pa-2 d-flex flex-column align-center",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "my-3", on: { click: _vm.route_to_homepage } },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/logoOsmose.png"),
                      height: "40px",
                      contain: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-2 d-flex align-center" },
                [_c("Select-tenants-btn", { staticClass: "mr-2" })],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { block: "", outlined: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$store.commit("open_dialog_calendar", true)
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { small: "", left: "", color: "white" } },
                    [_vm._v("mdi-calendar")]
                  ),
                  _vm._v(" Ouvrir l'agenda"),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { block: "", outlined: "", dark: "" },
                  on: { click: _vm.route_to_freeQuote },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { small: "", left: "", color: "white" } },
                    [_vm._v("mdi-basket")]
                  ),
                  _vm._v("Ouvrir la caisse"),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { block: "", outlined: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$store.commit(
                        "open_dialog_consultation_SV",
                        true
                      )
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { small: "", left: "", color: "white" } },
                    [_vm._v("mdi-card-account-details")]
                  ),
                  _vm._v("Démarrer une lecture"),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-around mt-2",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        icon: "",
                        small: "",
                        outlined: "",
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$store.commit(
                            "open_dialog_opportunity",
                            true
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "white" } }, [
                        _vm._v("mdi-view-dashboard"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        icon: "",
                        small: "",
                        outlined: "",
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$store.commit("open_dialog_back_office", {
                            open: true,
                          })
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "white" } }, [
                        _vm._v("mdi-arrow-top-right"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        icon: "",
                        small: "",
                        outlined: "",
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$store.commit(
                            "open_dialog_management_campaign",
                            true
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "white" } }, [
                        _vm._v("mdi-bullhorn"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        icon: "",
                        small: "",
                        outlined: "",
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$store.commit(
                            "open_dialog_dashboard",
                            true
                          )
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "white" } }, [
                        _vm._v("mdi-sine-wave"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }