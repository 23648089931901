import axios from "axios";
const API = process.env.VUE_APP_URL_HORUS;

export default class Member {
  async count_member(apiKey, search) {
    const request = await axios.post(API + "omember/member/count", search, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }
  // Search

  async store_member_identity(apiKey, form) {
    let request = await axios.post(
      API + "omember/member/store/identity",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_member_contact(apiKey, form) {
    let request = await axios.post(API + "omember/member/store/contact", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async store_member_registration(apiKey, form, memberUUID) {
    let request = await axios.post(API + "omember/member/store/registration?memberUUID=" + memberUUID, form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async search_member(apiKey, strSearch) {
    let request = await axios.post(
      API + "omember/member/search",
      { search: strSearch, crossBU: false, allKeys: true },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async adv_search_member(apiKey, query, size, page) {
    let request = await axios.post(
      API + "omember/member/search?size=" + size + "&page=" + page,
      query,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search_member_v2(apiKey, form, pagination) {
    const request = await axios.post(
      API +
        "omember/member/search/v2?page=" +
        pagination.currentPage +
        "&size=" +
        pagination.size,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_member_details(apiKey, memberUUID) {
    let request = await axios.get(
      API + "omember/member/detail?memberUUID=" + memberUUID,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // async set_search(apiKey, search) {
  //   let request = await axios.post(API + "member/dashboard/save", search, {
  //     headers: {
  //       "x-tenant": apiKey,
  //     },
  //   });
  //   return request.data;
  // }

  async upload_document(apiKey, file, nameExtansion) {
    // charge un document et renvoie un num edm
    // resource peut etre null
    // retourn un json a parser
    let FormData = require("form-data");
    const data = new FormData();
    var blob = new Blob([file], { type: "text/xml" });
    data.append("resource", blob, nameExtansion);
    let request = await axios.post(API + "omember/document/upload", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_identity_document(apiKey, body) {
    let request = await axios.post(API + "omember/document", JSON.parse(body), {
      headers: {
        "x-tenant": apiKey,
      },
      responseType: "blob",
    });
    return request.data;
  }

  async store_document(apiKey, data) {
    let request = await axios.post(API + "omember/member/store/icard", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async delete_document(apiKey, uuid) {
    let request = await axios.post(
      API + "omember/member/delete/icard",
      { memberUUID: uuid },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_wedding(apiKey, data) {
    let request = await axios.post(API + "omember/member/store/wedding", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async delete_one_contact(apiKey, memberUUID, contactKey, body) {
    let request = await axios.post(
      API +
        "omember/member/store/contact/single?memberUUID=" +
        memberUUID +
        "&contactKey=" +
        contactKey,
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_other_function(apiKey, id) {
    let request = await axios.post(
      API + "omember/member/delete/function/other",
      { id: id },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_functions(apiKey, memberUUID) {
    let request = await axios.post(
      API + "omember/member/get/function",
      { memberUUID: memberUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_shareholders(apiKey, memberUUID) {
    let request = await axios.post(
      API + "omember/member/get/company/shareholder",
      { memberUUID: memberUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_other(apiKey, body) {
    let request = await axios.post(API + "omember/member/store/other", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_user_shortcuts(apiKey, perimeter) {
    const request = await axios.post(
      API + `omember/member/shortcuts?perimeter=${perimeter}`,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_alerts(apiKey, perimeter) {
    const request = await axios.post(
      API + "omember/member/alerts?perimeter=" + perimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async member_export_file(apiKey, body = {}) {
    const request = await axios.post(API + "omember/member/export", body, {
      headers: {
        "x-tenant": apiKey,
      },
      responseType: "blob",
    });
    return request.data;
  }

  async get_member_filterKey_list(apiKey) {
    const request = await axios.post(
      API + "omember/member/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_list_legal_functions(apiKey) {
    const request = await axios.post(
      API + "omember/member/legalFunction/list",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_person_function(apiKey, personId) {
    const request = await axios.post(
      API + "omember/member/delete/function",
      { id: personId },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
