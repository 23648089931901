import Vue from "vue";

Vue.filter("formatPhone", (value) => {
  let phoneFormated = "";
  if (value.startsWith("+33")) {
    phoneFormated = value.replace("+33", "0");
    // make a space every 2 numbers
    phoneFormated = phoneFormated.replace(/(\d{2})/g, "$1 ");
    // remove the last space
    phoneFormated = phoneFormated.slice(0, -1);
    return phoneFormated;
  }

  return value;
});
