<template>
  <v-app-bar
    app
    color="navPrimary"
    style="box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.6) !important"
  >
    <v-card color="transparent" class="pa-0 ma-0" flat width="100%">
      <div
        style="width: 100%"
        class="d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <div class="mr-1">
            <MenuSwitchApps @change-tenant="dialogSelectTenant = true" />
          </div>
          <!-- Select tenants/Bu -->
          <SelectTenantsBtn
            :externalOpening.sync="dialogSelectTenant"
            :hideBtn="true"
          />

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                style="cursor: pointer"
                class="ml-1"
                @click="route_to_homepage()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white">mdi-home</v-icon>
              </div>
            </template>
            <span>Aller à l'accueil</span>
          </v-tooltip>
        </div>

        <div class="d-flex align-center">
          <!-- Desktop -->
          <Menu-nav v-if="$vuetify.breakpoint.lgAndUp" />

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="white"
                @click.stop="
                  $store.commit('open_dialog_back_office', { open: true })
                "
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left color="white">mdi-chevron-up-circle</v-icon>
                <span class="white--text font-weight-medium">
                  Back office
                </span>
              </v-btn>
            </template>
            <span>Ouvrir le Back-office</span>
          </v-tooltip>

          <!-- search input desktop -->
          <NavSearchEntity
            :widthSize="$vuetify.breakpoint.lgAndDown ? '400' : '500'"
          />

          <!-- <Toolbar-notifications /> -->

          <Toolbar-user class="ml-1" />
        </div>
      </div>
    </v-card>
    <DialogCreateCompany
      v-if="showDialogCreateCompany"
      :showDialog.sync="showDialogCreateCompany"
    />
    <DialogCreateLaboratory
      v-if="showDialogCreateLaboratory"
      :showDialog.sync="showDialogCreateLaboratory"
    />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";

import SelectTenantsBtn from "../common/selectTenant/SelectTenantsBtn.vue";
import MenuNav from "../common/MenuNav.vue";
import ToolbarNotifications from "../userSpace/userTips/UserTips.vue";
import ToolbarUser from "../userSpace/menus/ToolbarUser.vue";
import NavSearchEntity from "../searchMember/NavSearchEntity.vue";
import DialogCreateCompany from "@/components/company/DialogCreateCompany.vue";
import DialogCreateLaboratory from "@/components/laboratory/DialogCreateLaboratory.vue";
import MenuSwitchApps from "./menuSwitchApps/MenuSwitchApps.vue";

export default {
  name: "NavbarDesktop",
  components: {
    ToolbarUser,
    ToolbarNotifications,
    MenuNav,
    SelectTenantsBtn,
    NavSearchEntity,
    DialogCreateCompany,
    DialogCreateLaboratory,
    MenuSwitchApps,
  },

  props: {
    currentBu: Object,
  },

  computed: {
    ...mapState(["currentShopInfo", "storedMember", "openSelectTenant"]),
  },

  data() {
    return {
      dialogEditShop: false,
      dashboardSelected: { name: "Mon osmose", link: "myOsmose" },
      dashboardItems: [
        { name: "Mon osmose", link: "myOsmose" },
        { name: "Mon back office", link: "myBackOffice" },
        { name: "Mes campagnes", link: "myCampagne" },
      ],
      dialogSelectTenant: false,
      showDialogCreateCompany: false,
      showDialogCreateLaboratory: false,
    };
  },

  watch: {
    openSelectTenant(newVal) {
      if (newVal) {
        this.dialogSelectTenant = newVal;
        setTimeout(() => {
          this.$store.commit("upd_open_select_tenant", false);
        }, 500);
      }
    },
  },

  methods: {
    route_to_homepage() {
      if (this.$route.name != "myOsmose") {
        this.$router.push({ name: "myOsmose" });
      }
    },

    route_to_freeQuote() {
      if (this.$route.name != "freeQuote") {
        if (this.storedMember) {
          this.$store.commit("upd_freeQuote_SuggestedPatient", {
            fullName: this.storedMember.fullName,
            patientPath: this.storedMember.patientPath,
          });
        } else {
          this.$store.commit("upd_freeQuote_SuggestedPatient", null);
        }
        this.$store.commit("update_current_freeQuote_uuid", null);
        this.$router.push({ name: "freeQuote" });
      }
    },

    open_dialog_create_company() {
      this.showDialogCreateCompany = true;
    },

    open_dialog_create_laboratory() {
      this.showDialogCreateLaboratory = true;
    },
  },
};
</script>
