// time format for vue filter `| formatDate`
export default {
  // https://momentjs.com/timezone/docs/#/using-timezones/
  // zone: "Europe/Athens",
  // zone: "Europe/Berlin",
  zone: Intl.DateTimeFormat().resolvedOptions().timeZone,

  // https://momentjs.com/docs/#/displaying/format/
  format: "LL",
};
