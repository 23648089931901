export default [
  {
    path: "/sso/:target",
    name: "Sso",
    component: () => import("@/pages/public/Sso.vue"),
    meta: {
      layout: "Public",
    },
  },
];
