<template>
  <div>
    <v-menu offset-y left transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <span v-if="count != 0">
          <v-badge
            color="accent black--text"
            style="font-weight: bold"
            :content="count"
            offset-x="22"
            offset-y="22"
          >
            <v-btn icon v-on="on">
              <v-icon color="white">mdi-bell</v-icon>
            </v-btn>
          </v-badge>
        </span>
        <span v-else>
          <v-btn icon v-on="on">
            <v-icon color="white">mdi-bell</v-icon>
          </v-btn>
        </span>
      </template>

      <!-- dropdown card -->
      <v-card width="600" :loading="loadTips" color="bgLvl2">
        <v-card-title class="d-flex justify-space-between py-1 pr-1">
          <span class="subtitle-1 primary--text">
            Mes {{ $t("notification.notifications") }}
          </span>
          <v-btn small color="primary" @click.stop="dialogSetBroadcast = true"
            ><v-icon small left>mdi-plus</v-icon> Diffuser un message
          </v-btn>
        </v-card-title>

        <v-alert
          v-if="count == 0"
          type="info"
          text
          dense
          class="body-2 mb-0 mt-1 mx-1"
        >
          {{ $t("notification.noNotifications") | capitalize }}
        </v-alert>

        <v-list three-line class="pa-1" color="bgLvl2">
          <template v-for="(item, index) of tips">
            <div :key="index">
              <UserTipsBroadcast
                v-if="item.nature == 'broadcast'"
                :tip="item"
                @submited="get_tips"
              />

              <UserTipsBillingStatus
                v-else-if="item.nature == 'billingStatus'"
                :tip="item"
              />

              <UserTipsMessage v-else :tip="item" />

              <v-divider v-if="index < tips.length - 1"></v-divider>
            </div>
          </template>
        </v-list>
      </v-card>

      <DialogSetBrodcastMessage
        v-if="dialogSetBroadcast"
        :showDialog.sync="dialogSetBroadcast"
        @submited="get_tips"
      />
    </v-menu>

    <div v-if="$route.name == 'myOsmose'">
      <DialogTipsHigh
        v-for="(tip, index) of tipsHigh"
        :key="index"
        :tip="tip"
        @refresh="get_tips()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserTipsBroadcast from "./tipsElements/UserTipsBroadcast.vue";
import UserTipsBillingStatus from "./tipsElements/UserTipsBillingStatus.vue";
import DialogSetBrodcastMessage from "./DialogSetBrodcastMessage.vue";
import UserTipsMessage from "./tipsElements/UserTipsMessage.vue";
import DialogTipsHigh from "./DialogTipsHigh.vue";
import Osmose from "@/class/osmose";

export default {
  name: "UserTips",
  components: {
    UserTipsBroadcast,
    UserTipsBillingStatus,
    DialogSetBrodcastMessage,
    UserTipsMessage,
    DialogTipsHigh,
  },
  computed: {
    ...mapGetters(["apiKey"]),
    count() {
      return this.tips.length;
    },
  },

  data() {
    return {
      ClassOsmose: null,
      loadTips: false,
      tips: [],
      dialogSetBroadcast: false,
      tipsHigh: [],
    };
  },
  watch: {
    currentBu(newVal) {
      if (newVal != null) {
        this.init_tips();
      }
    },
  },

  created() {
    this.ClassOsmose = new Osmose();
    this.init_tips();
  },

  methods: {
    async init_tips() {
      await this.get_tips();
      this.is_high_tips();
    },

    async get_tips() {
      try {
        this.loadTips = true;
        this.tipsHigh = [];
        this.tips = await this.ClassOsmose.get_tips(this.apiKey);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadTips = false;
      }
    },

    is_high_tips() {
      this.tips.forEach((tip) => {
        if (tip.priority == "HIGH") {
          this.tipsHigh.push(tip);
        }
      });
    },
  },
};
</script>

<style scoped>
.divider {
  border-width: 1px !important;
  border-color: #18586a !important;
  height: 100%;
}
</style>
