<template>
  <v-snackbar
    v-model="notification.open"
    class="pa-2"
    :timeout="timeout"
    bottom
    right
  >
    <v-icon
      v-show="notification.context === 'success'"
      class="pr-3"
      color="success"
      >mdi-check</v-icon
    >
    <v-icon v-show="notification.context === 'error'" class="pr-3" color="error"
      >mdi-alert-circle</v-icon
    >
    <v-icon
      v-show="notification.context === 'warning'"
      class="pr-3"
      color="warning"
      >mdi-alert-circle</v-icon
    >
    <v-icon v-show="notification.context === 'info'" class="pr-3"
      >mdi-information</v-icon
    >
    {{ notification.text }}

    <template v-slot:action="{ attrs }">
      <span
        @click="notification.open = false"
        text
        v-bind="attrs"
        class="pa-2"
        style="cursor: pointer"
        >X</span
      >
    </template>

    <v-progress-linear
      absolute
      bottom
      color="secondary"
      :value="progressValue"
      reverse
    />
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SnackbarNotification",
  data() {
    return {
      timeout: 3000,
      progressValue: 0,
      steps: 100,
      interval: null
    };
  },
  computed: {
    ...mapGetters(["notification"])
  },

  mounted() {
    this.startProgress();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    startProgress() {
      if (this.timeout < 1) {
        return;
      }

      clearInterval(this.interval);

      this.interval = setInterval(() => {
        if (this.steps === this.timeout) {
          clearInterval(this.interval);
          return;
        }

        this.steps = this.steps + 100;
        this.progressValue = Math.round((this.steps / this.timeout) * 100);
      }, 100);
    }
  }
};
</script>
