var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "div",
        {
          staticStyle: { "min-height": "100vh", width: "100vw" },
          attrs: { id: "elApp" },
        },
        [
          _vm.isRouterLoaded
            ? _c(
                _vm.currentLayout,
                { tag: "component" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [_c("router-view", { key: _vm.$route.path })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      this.notification.open ? _c("SnackbarNotification") : _vm._e(),
      _c("IsUpdateBuild"),
      _vm.isRouterLoaded
        ? _c("InitCustomTheme", { attrs: { currentLayout: _vm.currentLayout } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }