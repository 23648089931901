<template>
  <div class="mx-auto overflow-hidden">
    <v-app-bar color="navPrimary" app>
      <v-app-bar-nav-icon
        color="white"
        @click.stop="drawer = true"
      ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <!-- <Toolbar-notifications class="mr-2" /> -->
      <Toolbar-user />
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="navPrimary"
      width="80vw"
    >
      <div style="width: 100%" class="pa-2 d-flex flex-column align-center">
        <div @click="route_to_homepage" class="my-3">
          <v-img src="@/assets/logoOsmose.png" height="40px" contain></v-img>
        </div>

        <div class="pa-2 d-flex align-center">
          <Select-tenants-btn class="mr-2" />
        </div>

        <v-btn
          block
          outlined
          dark
          class="mb-2"
          @click="$store.commit('open_dialog_calendar', true)"
        >
          <v-icon small left color="white">mdi-calendar</v-icon>
          Ouvrir l'agenda</v-btn
        >

        <v-btn block outlined dark class="mb-2" @click="route_to_freeQuote"
          ><v-icon small left color="white">mdi-basket</v-icon>Ouvrir la
          caisse</v-btn
        >
        <v-btn
          block
          outlined
          dark
          class="mb-2"
          @click="$store.commit('open_dialog_consultation_SV', true)"
          ><v-icon small left color="white">mdi-card-account-details</v-icon
          >Démarrer une lecture</v-btn
        >

        <div style="width: 100%" class="d-flex justify-space-around mt-2">
          <v-btn
            fab
            icon
            small
            outlined
            color="white"
            @click="$store.commit('open_dialog_opportunity', true)"
          >
            <v-icon small color="white">mdi-view-dashboard</v-icon>
          </v-btn>
          <v-btn
            fab
            icon
            small
            outlined
            color="white"
            @click.stop="
              $store.commit('open_dialog_back_office', { open: true })
            "
          >
            <v-icon small color="white">mdi-arrow-top-right</v-icon>
          </v-btn>
          <v-btn
            fab
            icon
            small
            outlined
            color="white"
            @click="$store.commit('open_dialog_management_campaign', true)"
          >
            <v-icon small color="white">mdi-bullhorn</v-icon>
          </v-btn>
          <v-btn
            fab
            icon
            small
            outlined
            color="white"
            @click="$store.commit('open_dialog_dashboard', true)"
          >
            <v-icon small color="white">mdi-sine-wave</v-icon>
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuNav from "../common/MenuNav.vue";
import SelectTenantsBtn from "../common/selectTenant/SelectTenantsBtn.vue";
import ToolbarNotifications from "../userSpace/userTips/UserTips.vue";
import ToolbarUser from "../userSpace/menus/ToolbarUser.vue";
export default {
  name: "NavbarMobile",
  components: {
    ToolbarNotifications,
    ToolbarUser,
    SelectTenantsBtn,
    MenuNav,
  },
  computed: {
    ...mapState(["storedMember"]),
  },
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
  methods: {
    route_to_freeQuote() {
      if (this.$route.name != "freeQuote") {
        if (this.storedMember) {
          this.$store.commit("upd_freeQuote_SuggestedPatient", {
            fullName: this.storedMember.fullName,
            patientPath: this.storedMember.patientPath,
          });
        } else {
          this.$store.commit("upd_freeQuote_SuggestedPatient", null);
        }
        this.$store.commit("update_current_freeQuote_uuid", null);
        this.$router.push({ name: "freeQuote" });
      }
    },

    text_truncate(txt, sizeMax = 35) {
      if (txt && txt.length > sizeMax) {
        return txt.substring(0, sizeMax).concat("...");
      } else {
        return txt;
      }
    },
    route_to_homepage() {
      if (this.$route.name != "myOsmose") {
        this.$router.push({ name: "myOsmose" });
      }
    },
  },
};
</script>
