<template>
  <v-card flat :loading="loadInfoSupport" color="bgLvl3">
    <v-card-text>
      <div>
        <v-row dense justify="space-between" align="end">
          <v-col
            cols="4"
            class="d-flex flex-column justify-end align-center text-center"
            v-if="modeLmca"
          >
            <v-icon size="96">mdi-chat-question</v-icon>
            <div class="d-flex justify-center mt-1">
              <div>
                <p class="subtitle-1 my-0">Centre d'aide</p>
                <a
                  href="https://audition-conseil.zendesk.com/hc/fr"
                  class="primary--text body-1"
                  target="blank"
                >
                  Ouvrir le centre d'aide
                </a>
              </div>
            </div>
          </v-col>

          <v-col
            cols="4"
            class="d-flex flex-column justify-end align-center text-center"
            v-if="!modeLmca && contactPhone"
          >
            <v-icon size="96">mdi-phone-classic</v-icon>
            <div class="d-flex justify-center mt-1">
              <div>
                <div>
                  <p class="subtitle-1 my-0">
                    {{ contactPhone.title }}
                  </p>
                  <a
                    :href="'tel:' + contactPhone.displayValue"
                    class="primary--text body-1"
                  >
                    {{ contactPhone.displayValue }}
                  </a>
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="4"
            class="d-flex flex-column justify-end align-center text-center"
            v-if="contactEmail"
          >
            <v-icon size="96">mdi-face-agent</v-icon>
            <div class="d-flex justify-center mt-1">
              <div>
                <p class="subtitle-1 my-0">
                  {{ contactEmail.title }}
                </p>
                <span class="body-1" v-if="!modeLmca">
                  <a :href="format_email(contactEmail.contact.email)">{{
                    contactEmail.contact.email
                  }}</a>
                </span>
                <span class="body-1" v-if="modeLmca">
                  <a :href="format_email('supportosmose@lmcaudio.fr')"
                    >supportosmose@lmcaudio.fr</a
                  >
                </span>
              </div>
            </div>
          </v-col>

          <v-col
            cols="4"
            class="d-flex flex-column justify-end align-center text-center"
          >
            <v-icon size="96">mdi-file-certificate</v-icon>
            <div class="subtitle-1 pre-formatted mt-1">
              <CGV :openCGV="openCGV" isBr />
            </div>
          </v-col>

          <v-col cols="12" class="mb-2">
            <v-alert
              type="info"
              text
              class="pre-formatted"
              style="text-align: start"
            >
              {{ openHoursNotice }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <div v-if="isConnect">
        <div class="d-flex justify-center">
          <span class="subtitle-1">Code support</span>
        </div>
        <div class="d-flex justify-center pl-8">
          <span
            class="text-h3 primary--text px-2 py-1"
            style="
              font-weight: bold;
              border: solid 1px black;
              border-radius: 5px;
            "
            >{{ supportCode }}</span
          >

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                icon
                v-bind="attrs"
                v-on="on"
                @click="codeSupport"
                class="ml-1"
              >
                <v-icon size="32" color="primary" style="cursor: pointer"
                  >mdi-reload</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("support.regenerateCodeSupport") | capitalize }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import auth from "@/class/auth";
import { mapGetters } from "vuex";
export default {
  name: "CardInfoSupport",
  props: {
    isConnect: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  components: {
    CGV: () => import("@/components/common/CGV.vue"),
  },

  computed: {
    ...mapGetters(["currentBu"]),
    contactPhone() {
      return this.contacts.filter((e) => e.channel == "PHONE")[0];
    },
    contactEmail() {
      return this.contacts.filter((e) => e.channel == "EMAIL")[0];
    },
  },

  data() {
    return {
      contacts: [],
      openHoursNotice: null,
      tenantName: "",
      supportCode: "--- --- ---",
      openCGV: false,
      loadInfoSupport: false,

      modeLmca: process.env.NODE_ENV === "LMCA" ? true : false,
    };
  },

  created() {
    this.get_support_info();
  },

  methods: {
    async get_support_info() {
      try {
        this.loadInfoSupport = true;
        this.authService = new auth();
        let info = null;

        if (this.currentBu != null) {
          info = await this.authService.infoSupportTenant(
            this.$i18n.locale,
            this.currentBu.tenant.apiKey
          );
        } else {
          info = await this.authService.infoSupport(this.$i18n.locale);
        }
        this.openHoursNotice = info.openHoursNotice;
        this.contacts = info.contacts;
        if (info.tenantName) {
          this.tenantName = info.tenantName;
        }
        if (info.supportCode) {
          this.formatCode(info.supportCode);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadInfoSupport = false;
      }
    },

    async formatCode(code) {
      this.supportCode =
        code.substring(0, 3) +
        " " +
        code.substring(3, 6) +
        " " +
        code.substring(6, code.length);
    },

    async codeSupport() {
      try {
        this.authService = new auth();
        let supportCode = await this.authService.codeSupport(
          this.currentBu.tenant.apiKey
        );
        await this.formatCode(supportCode.toString());
      } catch (error) {
        console.error(error);
      }
    },

    format_email(email) {
      let formatMail = "mailto:" + email + "?subject=Oblige";
      if (this.currentBu) {
        formatMail =
          "mailto:" +
          email +
          "?subject=" +
          this.currentBu.tenant.label +
          " / " +
          this.supportCode;
      }
      return formatMail;
    },
  },
};
</script>
