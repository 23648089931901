<template>
  <v-autocomplete
    v-if="returnObject"
    v-model="outputCountry"
    :items="countries"
    item-text="name"
    return-object
    :label="inputTitle"
    placeholder="France"
    name="countryOfBirth"
    clearable
    outlined
    dense
  ></v-autocomplete>
  <v-autocomplete
    v-else
    v-model="outputCountry"
    :items="countries"
    item-text="name"
    item-value="name"
    :label="inputTitle"
    placeholder="France"
    name="countryOfBirth"
    clearable
    outlined
    dense
  ></v-autocomplete>
</template>

<script>
import GouvFR from "@/class/gouvFr.js";
export default {
  name: "InputCountry",
  props: {
    country: {
      type: [String, Object],
    },
    inputTitle: {
      type: String,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    outputCountry: {
      get() {
        return this.country;
      },
      set(value) {
        this.$emit("update:country", value);
      },
    },
  },
  data() {
    return {
      ClassGouvFr: null,
      countries: [],
    };
  },
  mounted() {
    this.get_countries();
  },
  methods: {
    async get_countries() {
      this.ClassGouvFr = new GouvFR();
      let response = await this.ClassGouvFr.get_all_countries();
      this.countries = response.entities;
    },
  },
};
</script>

<style lang="scss" scoped></style>
