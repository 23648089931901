<template>
  <v-dialog v-model="openDialog" width="900" max-width="900">
    <v-card color="bgLvl3">
      <v-card-title>
        {{ $t("support.helpSupport") | capitalize }}
        <v-spacer></v-spacer>
        <span class="primary--text caption">
          Build du {{ buildDate | formatDate("LLL") }}
          {{ $t("utility.version") | capitalize }} {{ version }}</span
        >
      </v-card-title>
      <v-card-subtitle>
        <span v-if="isConnect" class="subtitle-1">
          {{ currentBu.tenant.label }}
        </span>
      </v-card-subtitle>
      <Card-info-support :isConnect="isConnect" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close_modals()" text>
          {{ $t("form.close") | capitalize }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CardInfoSupport from "./CardInfoSupport.vue";
import { mapGetters } from "vuex";

export default {
  name: "SupportInfo",

  components: {
    CardInfoSupport,
  },
  props: {
    isConnect: {
      type: Boolean,
      require: false,
      default: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
    ...mapGetters(["currentBu", "version", "buildDate"]),
  },
  methods: {
    close_modals() {
      this.openDialog = false;
    },
  },
};
</script>
