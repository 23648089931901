<template>
  <v-dialog v-if="openDialog" v-model="openDialog" width="1000" persistent>
    <v-card>
      <v-card-title class="title primary--text">
        Notification importante
      </v-card-title>

      <v-card-text class="pb-0">
        <v-card outlined>
          <UserTipsBroadcast
            v-if="tip.nature == 'broadcast'"
            :tip="tip"
            @submited="cancel_form(true)"
          />

          <UserTipsBillingStatus
            v-else-if="tip.nature == 'billingStatus'"
            :tip="tip"
          />

          <UserTipsMessage v-else :tip="tip" />
        </v-card>
      </v-card-text>

      <v-card-actions class="d-flex justify-end align-center">
        <v-btn @click="cancel_form(false)" min-width="100" color="primary" text>
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserTipsBillingStatus from "./tipsElements/UserTipsBillingStatus.vue";
import UserTipsBroadcast from "./tipsElements/UserTipsBroadcast.vue";
import UserTipsMessage from "./tipsElements/UserTipsMessage.vue";

export default {
  components: { UserTipsBroadcast, UserTipsBillingStatus, UserTipsMessage },
  name: "DialogTipsHigh",
  props: {
    tip: {
      type: Object,
    },
  },

  data() {
    return {
      openDialog: true,
    };
  },
  //FIXME Comprendre pourquoi si plusieurs dialog et que tu valide 1 les autres ne s'ouvrent pas
  methods: {
    cancel_form(value) {
      if (value) {
        this.$emit("refresh");
      }
      this.openDialog = false;
    },
  },
};
</script>
