import axios from "axios";
// import store from "../store/index.js";

let API = process.env.VUE_APP_URL_HORUS;

export default class Shop {
  async getCurrentShop(apiKey, view = false) {
    let request = await axios.post(
      API + "osmose/shop?logo=" + view,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_shop_infos(apiKey, shopInfo) {
    let request = await axios.post(API + "osmose/shop/store", shopInfo, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async set_shop_infos_graph(apiKey, graphType) {
    let request = await axios.post(
      API + "osmose/shop/store/graph?noahGraph=" + graphType,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_shop_infos_init(apiKey, shopInfo) {
    let request = await axios.post(API + "osmose/shop/store/init", shopInfo, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async set_inner_infos(apiKey, shopInfo) {
    let request = await axios.post(API + "osmose/shop/store/inner", shopInfo, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // Sending Account to Shop
  async assign_sending_accounts_to_shop(apiKey, accountType, accountUUID) {
    let request = await axios.post(
      API + "osmose/shop/store/account",
      {
        accountType: accountType,
        accountUUID: accountUUID,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // *** PS Account ***

  async get_ps_account(apiKey, shopPS) {
    let request = await axios.post(
      API + "osmose/shop/ps?uuid=" + shopPS,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_list_ps_accounts(apiKey) {
    let request = await axios.post(
      API + "osmose/shop/ps/list",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async assign_ps_account_to_shop(apiKey, psUUID) {
    let request = await axios.post(
      API + "osmose/shop/store/ps",
      {
        psUUID,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async set_ps_account(apiKey, form) {
    let request = await axios.post(API + "osmose/shop/ps/store", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }
  async get_psForm_default_data(apiKey) {
    let request = await axios.post(
      API + "osmose/shop/ps/dummy",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // *** Default healthOwner ***

  async assign_health_owner_to_shop(apiKey, healthOwner) {
    let request = await axios.post(
      API + "osmose/shop/healthOwner/store",
      healthOwner ? healthOwner : {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_default_health_owner(apiKey) {
    let request = await axios.post(
      API + "osmose/shop/healthOwner",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // *** Insurance ***
  async get_list_insurance(apiKey) {
    let request = await axios.post(
      API + "osmose/shop/insurance/list",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_insurance(apiKey, uuidInsurance) {
    let request = await axios.post(
      API + "osmose/shop/insurance?uuid=" + uuidInsurance,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_insurance(apiKey, insurance) {
    let request = await axios.post(
      API + "osmose/shop/insurance/store",
      insurance,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_sender_name_mobile(apiKey, info) {
    let request = await axios.post(
      API + "osmose/shop/store/config/mobile",
      info,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
