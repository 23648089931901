<template>
  <v-dialog v-model="selectTenantDialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="!hideBtn" x-small fab v-bind="attrs" v-on="on" v-blur>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-dots-grid
            </v-icon>
          </template>
          <span>Changer d'espace de travail</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card min-height="300" v-if="selectTenantDialog" color="bgLvl3">
      <v-card-title class="primary--text title">
        Choisir mon espace de travail
      </v-card-title>

      <v-card-text class="px-2 py-0" v-if="renderComponent">
        <SelectTenants
          @closeDialog="selectTenantDialog = false"
          :btnStyle="true"
        />
      </v-card-text>

      <v-card-actions>
        <!-- <v-btn color="primary" @click="showCreateTenantModal = true" dense text>
          Créer un nouvel espace de travail
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          min-width="100"
          text
          color="primary"
          @click="selectTenantDialog = false"
          >{{ $t("form.close") | capitalize }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- 
    <DialogCreateTenant
      v-if="showCreateTenantModal"
      :showDialog.sync="showCreateTenantModal"
      @submited="forceRerender"
    /> -->
  </v-dialog>
</template>

<script>
// import DialogCreateTenant from "./DialogCreateTenant";
export default {
  name: "SelectTenantsBtn",

  components: {
    SelectTenants: () =>
      import("@/components/common/selectTenant/SelectTenants"),
    // DialogCreateTenant,
  },

  props: {
    externalOpening: {
      type: Boolean, // .sync mandatory
    },
    hideBtn: {
      type: Boolean
    }
  },

  computed: {
    forceOpenDialog: {
      get() {
        return this.externalOpening;
      },
      set(value) {
        this.$emit("update:externalOpening", value);
      },
    },
  },

  watch: {
    forceOpenDialog(newVal) {
      if (newVal) {
        this.selectTenantDialog = newVal;
      }
    },
    selectTenantDialog(newVal) {
      if (!newVal) {
        this.forceOpenDialog = newVal;
      }
    },
  },

  data() {
    return {
      selectTenantDialog: false,
      // showCreateTenantModal: false,
      renderComponent: true,
    };
  },

  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
