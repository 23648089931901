import { render, staticRenderFns } from "./PublicLayout.vue?vue&type=template&id=8a88189c"
import script from "./PublicLayout.vue?vue&type=script&lang=js"
export * from "./PublicLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/c004-adherent-front/osmose-ui-adherent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8a88189c')) {
      api.createRecord('8a88189c', component.options)
    } else {
      api.reload('8a88189c', component.options)
    }
    module.hot.accept("./PublicLayout.vue?vue&type=template&id=8a88189c", function () {
      api.rerender('8a88189c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/PublicLayout.vue"
export default component.exports