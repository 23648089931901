var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex text-center flex-column flex-md-row flex-grow-1" },
    [
      _c(
        "v-sheet",
        {
          staticClass:
            "layout-side mx-auto d-none d-md-flex flex-md-column justify-space-between px-2 glass",
          attrs: { height: "100vh", tile: "" },
        },
        [
          _c("div", { staticClass: "mt-3 mt-md-10 pa-2" }, [
            _c(
              "div",
              {
                staticClass: "pa-2 d-flex flex-column justify-start",
                on: {
                  click: function ($event) {
                    return _vm.toLoginPage()
                  },
                },
              },
              [
                _c("v-img", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    src: require("@/assets/brandPro-logo.png"),
                    "aspect-ratio": "1.4",
                    contain: "",
                  },
                }),
              ],
              1
            ),
            _c("div", [
              _c(
                "span",
                { staticClass: "display-1 font-weight-bold secondary--text" },
                [_vm._v("Adhérent")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column",
        },
        [
          _c(
            "div",
            { staticClass: "layout-content ma-auto w-full" },
            [_vm._t("default")],
            2
          ),
          _c(
            "div",
            { staticClass: "overline mt-4" },
            [_c("Toolbar-language")],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }