<template>
  <v-list-item>
    <v-list-item-content>
      <v-row dense>
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon color="primary">mdi-bell-ring-outline</v-icon>
        </v-col>
        <v-col cols="11">
          <div class="title primary--text">
            {{ tip.title }}
          </div>

          <div class="subtitle-2 primary--text my-1">
            {{ tip.description }}
          </div>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "UserTipsMessage",

  props: {
    tip: {
      type: Object,
    },
  },
};
</script>
