<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-form
      v-model="validForm"
      ref="formChangeCompany"
      lazy-validation
      @submit.prevent="validation_form"
    >
      <v-card color="bgLvl3">
        <v-card-title class="title primary--text">
          <span>Changer la société associée</span>
        </v-card-title>
        <v-container>
          <v-row dense>
            <v-col>
              <SearchCompany
                @selected="assign_company_to_form"
                :showBtnCreation="false"
                dense
                label="Attacher à une société"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            :disabled="loadForm"
            @click="cancel_dialog"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            :loading="loadForm"
            :disabled="!validForm"
            type="submit"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Laboratory from "@/class/laboratory";

import SearchCompany from "../SearchCompany.vue";

export default {
  name: "DialogChangeCompany",

  components: { SearchCompany },

  props: {
    showDialog: {
      type: Boolean, // .sync mandatory
    },
    currentCompany: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["apiKey"]),
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
  },
  data() {
    return {
      ClassLaboratory: null,
      validForm: false,
      loadForm: false,
      form: {
        laboratoryUUID: this.$route.params.laboratoryUUID,
        company: null,
      },
      listCompanys: [],
      rules: {
        required: (v) => !!v || "Ce champ est requis.",
        maxLength: (len) => (v) =>
          (v || "").length <= len || `${len} caractères maximum`,
        minLength: (len) => (v) =>
          (v || "").length >= len || `${len} caractères minimum`,
      },
    };
  },

  created() {
    this.ClassLaboratory = new Laboratory();
  },

  mounted() {},

  methods: {
    assign_company_to_form(company) {
      this.form.company = structuredClone(company);
      this.validForm = true;
    },

    // Submit
    async validation_form() {
      if (this.form.company != null) {
        await this.submit_form();
      } else {
        this.validForm = false;
      }
    },
    async submit_form() {
      try {
        this.loadForm = true;

        await this.ClassLaboratory.store_company_in_laboratory(
          this.apiKey,
          this.form
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.cancel_dialog();
        this.$emit("refresh");
      }
    },

    // Dialog funcs
    cancel_dialog() {
      this.openDialog = false;
    },
  },
};
</script>
