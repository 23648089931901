var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "flag-icon",
    class: [`flag-icon-${_vm.flag}`, { "flag-round": _vm.round }],
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }