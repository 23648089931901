var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    ref: "searchMembers",
    staticClass: "mx-1 custom-autocomplete",
    style: "width:" + _vm.widthSize + "px",
    attrs: {
      items: _vm.resultItems,
      "search-input": _vm.strSearch,
      loading: _vm.loadSearch,
      "item-text": "fullName",
      "item-value": "patientPath",
      label: "Rechercher un adhérent, un laboratoire, une société",
      id: "inputNavsearchEntity",
      "hide-details": "",
      "no-filter": "",
      "return-object": "",
      "prepend-inner-icon": "mdi-magnify",
      dense: "",
      dark: "",
      "background-color": "navSecondary",
      outlined: "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.strSearch = $event
      },
      "update:search-input": function ($event) {
        _vm.strSearch = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            !_vm.strSearch || _vm.strSearch.length < 3
              ? _c("div", { staticClass: "pa-1 caption" }, [
                  _c("span", [
                    _vm._v("Minimum 2 caractères pour lancer la recherche."),
                  ]),
                ])
              : _c("div", { staticClass: "pa-1 caption" }, [
                  _vm._v("Pas de résultats"),
                ]),
          ]
        },
        proxy: true,
      },
      {
        key: "append",
        fn: function () {
          return [
            _c("v-icon", [_vm._v("mdi-menu-down")]),
            _c("AddEntity", {
              staticClass: "my-n2",
              staticStyle: { "padding-top": "6px" },
              attrs: {
                btnStyle: false,
                disabled: !_vm.$authorization.validateGrant(
                  "manageMember@brandpro"
                ),
              },
              on: {
                click: function ($event) {
                  _vm.resultItems = null
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "prepend-item",
        fn: function () {
          return [
            _c(
              "v-container",
              { staticClass: "stick" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "px-2",
                    attrs: { justify: "end", align: "center" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled:
                                                _vm.$route.name ==
                                                "searchMembers",
                                              color: "secondary",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$store.commit(
                                                  "open_dialog_back_office",
                                                  { open: true }
                                                )
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("Recherche avancée")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Rechercher un adhérent sur différents critères"
                              ),
                            ]),
                          ]
                        ),
                        _c("AddEntity", {
                          attrs: {
                            disabled: !_vm.$authorization.validateGrant(
                              "manageMember@brandpro"
                            ),
                          },
                          on: {
                            closeSelect: function ($event) {
                              return _vm.$refs.searchMembers.blur()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mb-1" }),
          ]
        },
        proxy: true,
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-list-item-avatar",
              { attrs: { size: "30", tile: "" } },
              [
                _c(
                  "v-avatar",
                  { attrs: { tile: "" } },
                  [
                    _c("v-icon", { attrs: { small: "", primary: "" } }, [
                      _vm._v(_vm._s(_vm.check_icon_type(item.nature))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-list-item-content",
              { style: "max-width: 300px" },
              [
                _c(
                  "v-list-item-title",
                  { staticClass: "subtitle-1 primary--text" },
                  [_vm._v(" " + _vm._s(_vm.name_to_display(item)) + " ")]
                ),
                _c("v-list-item-subtitle", { staticClass: "body-2 d-flex" }, [
                  _c(
                    "div",
                    { staticStyle: { "min-width": "200px" } },
                    [
                      _vm._v(" " + _vm._s(_vm.name_nature(item.nature)) + " "),
                      item.underlying.code
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "info--text body-2",
                                              },
                                              "span",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.underlying.code) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.check_text_code(item.nature)) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  item.nature == "MEMBER" && _vm.get_contact(item, "PHONE")
                    ? _c("span", { staticClass: "grey--text body-2" }, [
                        _vm._v(
                          " " + _vm._s(_vm.get_contact(item, "PHONE")) + " "
                        ),
                      ])
                    : _vm._e(),
                ]),
                item.nature == "MEMBER"
                  ? _c("v-list-item-subtitle", [
                      _c("span", { staticClass: "grey--text body-2" }, [
                        _vm._v(
                          _vm._s(_vm.get_contact(item, "POSTAL_ADDRESS")) + " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            item.nature == "MEMBER"
              ? _c(
                  "v-list-item-avatar",
                  { attrs: { size: "30", tile: "" } },
                  [
                    item.underlying.reducedMobility
                      ? _c(
                          "v-avatar",
                          { attrs: { tile: "" } },
                          [_c("AvatarReducedMobility")],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "selection",
        fn: function (data) {
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  attrs: { "input-value": data.selected, close: "", small: "" },
                  on: {
                    click: data.select,
                    "click:close": function ($event) {
                      return _vm.remove_select_entity()
                    },
                  },
                },
                "v-chip",
                data.attrs,
                false
              ),
              [
                _c(
                  "v-avatar",
                  { attrs: { left: "" } },
                  [
                    _c("v-icon", { attrs: { small: "", primary: "" } }, [
                      _vm._v(_vm._s(_vm.check_icon_type(data.item.nature))),
                    ]),
                  ],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.name_to_display(data.item)) + " "),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectItem,
      callback: function ($$v) {
        _vm.selectItem = $$v
      },
      expression: "selectItem",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }