<template>
  <v-autocomplete
    v-model="selectItem"
    :items="resultItems"
    :search-input.sync="strSearch"
    :loading="loadSearch"
    item-text="socialReason"
    item-value="companyUUID"
    :label="label"
    ref="searchCompany"
    id="inputNavSearchCompany"
    hide-details
    no-filter
    return-object
    class="custom-autocomplete"
    prepend-inner-icon="mdi-magnify"
    outlined
    :dense="dense"
  >
    <template v-slot:append-outer v-if="showBtnCreation">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="dialog_create_company"
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="mt-n1"
            ><v-icon>mdi-plus-circle</v-icon></v-btn
          >
        </template>
        <span>Créer une société</span>
      </v-tooltip>
    </template>
    <template v-slot:item="{ item }">
      <v-row dense>
        <v-col cols="12" class="pb-0 pt-2">
          <span class="primary--text font-weight-bold">
            {{ item.socialReason }} {{ item.code }}
          </span>
        </v-col>
        <v-col cols="12" class="pt-0">
          <span class="caption info--text">
            <span v-if="item.siren != null">
              SIREN {{ item.siren }} &nbsp;
            </span>
            <span v-if="item.vatNumber != null">
              TVA {{ item.vatNumber }} &nbsp;
            </span>
            <span v-if="item.rcs != null"> RCS {{ item.rcs }} &nbsp; </span>
          </span>
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import Company from "@/class/company.js";
export default {
  name: "SearchCompany",
  computed: {
    ...mapGetters(["apiKey"]),
  },
  props: {
    showBtnCreation: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Rechercher une société",
    },
  },
  data() {
    return {
      ClassCompany: null,
      resultItems: [],
      strSearch: "",
      loadSearch: false,
      selectItem: null,
    };
  },
  watch: {
    selectItem(val) {
      if (val && val !== null) {
        this.$emit("selected", val);
      }
    },
    strSearch(val) {
      val && val !== this.selectItem && this.query_selections(val);
    },
  },
  methods: {
    async query_selections(v) {
      clearTimeout(this.timeOut);
      if (v.length >= 2) {
        let queue = [];
        queue.push(v);
        this.loadSearch = true;
        this.timeOut = setTimeout(() => {
          this.search(queue.pop());
        }, 700);
      }
    },

    async search(value) {
      try {
        if (this.ClassCompany == null) {
          this.ClassCompany = new Company();
        }
        let body = { search: value };
        let resGetItems = await this.ClassCompany.search_company(
          this.apiKey,
          body,
          false, // = crossBU.
          this.allKeys
        );
        this.resultItems = await resGetItems.entities;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSearch = false;
      }
    },
    dialog_create_company() {
      this.$emit("creationCompany");
    },
  },
};
</script>

<style lang="scss" scoped></style>
