var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        persistent: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          class: _vm.$vuetify.theme.dark
            ? "curtainBackgroundDark"
            : "curtainBackgroundLight",
          attrs: { tile: "" },
        },
        [
          _c("Curtain-toolbar", {
            attrs: {
              label: _vm._f("capitalize")(_vm.$t(_vm.fullPageDialog.title)),
            },
            on: { clicked: _vm.cancel_dialog },
          }),
          _c(_vm.fullPageDialog.component, { tag: "component" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }