<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-card color="bgLvl3">
      <v-card-title class="title primary--text">
        <span>Nous avons trouvé une société</span>
      </v-card-title>
      <v-card-text>
        <v-row dense class="py-1">
          <v-col cols="12">
            <v-alert type="info" text dense class="mb-0 body-2">
              Nous avons trouvé les informations suivantes pour le SIREN
              <b>{{ companyInformations.siren }}</b
              >.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <table :class="$vuetify.theme.dark ? 'ctnTableDark' : ''">
              <tbody class="body-2">
                <tr
                  v-for="(item, index) of fields"
                  :key="index"
                  :style="trColor"
                >
                  <td class="primary--text">
                    {{ item.text | capitalize }}
                  </td>
                  <td
                    :class="$vuetify.theme.dark ? 'tValueDark' : 'tValueLight'"
                  >
                    <span v-if="item.value != null">
                      <span class="primary--text"> {{ item.value }} </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>

        <v-form
          v-model="validForm"
          ref="formCreateCompanySiren"
          @submit.prevent="validation_form"
        >
          <v-row dense>
            <v-col cols="5">
              <v-text-field
                v-model="companyInformations.code"
                label="Code d'identification"
                :rules="[rules.required]"
                outlined
                dense
                autocomplete="null"
                class="mr-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn color="primary" text min-width="100" @click="cancel_dialog"
          >Annuler</v-btn
        >
        <v-btn color="primary" min-width="100" @click="validation_form"
          >Créer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogCorrectSiren",

  props: {
    showDialog: {
      type: Boolean, // .sync mandatory
    },
    companyInformations: {
      type: Object,
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
    trColor() {
      if (this.$vuetify.theme.dark) {
        return { "--odd-color": "grey", "--even-color": "#616161" };
      } else {
        return {
          "--odd-color": "rgba(0, 0, 0, 0.07)",
          "--even-color": "white",
        };
      }
    },
  },
  data() {
    return {
      validForm: true,
      ClassCompany: null,
      fields: [
        {
          text: "Raison sociale",
          value: this.companyInformations.socialReason,
        },
        { text: "N° TVA", value: this.companyInformations.vatNumber },
        { text: "SIREN", value: this.companyInformations.siren },
        {
          text: "Forme juridique",
          value: this.companyInformations.legalStatus,
        },
        {
          text: "Adresse",
          value: this.companyInformations.headquarterLocation.label,
        },
      ],
      rules: {
        required: (v) => !!v || "Ce champ est requis.",
      },
    };
  },

  methods: {
    validation_form() {
      if (this.validForm) {
        this.$emit("validate");
      } else {
        this.$refs.formCreateCompanySiren.validate();
      }
    },
    // Dialog funcs
    cancel_dialog() {
      this.$emit("cancel");
      this.openDialog = false;
    },
  },
};
</script>

<style scoped>
tbody tr:nth-child(odd) {
  background-color: var(--odd-color);
}
tbody tr:nth-child(even) {
  background-color: var(--even-color);
}
tbody tr {
  background-color: var(--odd-color);
}

tbody tr td {
  padding: 8px 16px;
}
tbody tr td:nth-child(1) {
  width: 40%;
}
tbody tr td:nth-child(2) {
  width: 60%;
}
table {
  background-color: white;
  border-collapse: collapse;
  width: 100%;
}
table caption {
  padding: 8px;
  text-align: left;
}
.ctnTable {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ctnTableDark {
  background: var(--v-bgLvl2-base);
}
.tValueLight {
  font-weight: bold;
  color: var(--v-primary-base);
}
.tValueDark {
  font-weight: bold;
  color: white;
}

.darkLink {
  color: #61d4ef;
}
</style>
