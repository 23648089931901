import axios from "axios";

let urlApi = process.env.VUE_APP_URL_HORUS;

export default class setting {
  // NOTE - Enregistre les preference pour User
  async actionsGet(apiKey, key, env) {
    let request = await axios.get(
      urlApi + "setting/user?key=" + key + "&env=" + env,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async actionsPost(apiKey, key, data, env) {
    let request = await axios.post(
      urlApi + "setting/user?key=" + key + "&env=" + env,
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // NOTE - Enregistre le setting pour le BU nvpair
  async get_settings(apiKey, key) {
    let request = await axios.get(urlApi + "setting/nvpair?key=" + key, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async set_settings(apiKey, key, data) {
    let request = await axios.post(urlApi + "setting/nvpair?key=" + key, data, {
      headers: {
        "Content-Type": "text/plain",
        "x-tenant": apiKey,
      },
    });
    return request;
  }

  async broadcast_ack(apiKey, documentId, ackType) {
    let request = await axios.post(
      urlApi +
        "setting/broadcast/ack?documentId=" +
        documentId +
        "&ackType=" +
        ackType,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_broadcast_message(apiKey, messsage) {
    let request = await axios.post(
      urlApi + "setting/broadcast/store",
      messsage,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_metrics_available(apiKey, perimeter, subscribePerimeter) {
    let request = await axios.post(
      urlApi +
        "setting/registrable?perimeter=" +
        perimeter +
        "&subscribePerimeter=" +
        subscribePerimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async settings_registration(apiKey, shortcuts, subscribePerimeter) {
    let request = await axios.post(
      urlApi +
        "setting/registrable?perimeter=" +
        shortcuts +
        "&subscribePerimeter=" +
        subscribePerimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async settings_registration_with_subperimeter(
    apiKey,
    shortcuts,
    subscribePerimeter,
    subperimeter
  ) {
    let request = await axios.post(
      urlApi +
        "setting/registrable?perimeter=" +
        shortcuts +
        "&subscribePerimeter=" +
        subscribePerimeter +
        "&subPerimeter=" +
        subperimeter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async settings_registration_subscribe(apiKey, subscribe) {
    let request = await axios.post(
      urlApi + "setting/registrable/subscribe",
      subscribe,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async settings_subscribed(apiKey, perimeter, subscribePerimeter) {
    let request = await axios.post(
      urlApi +
        "setting/registrable/subscribed?perimeter=" +
        perimeter +
        "&subscribePerimeter=" +
        subscribePerimeter,
      {},

      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async settings_registration_disabled(apiKey, subscribeKey, perimeter) {
    const request = await axios.post(
      urlApi + "setting/registrable/disable",
      { key: subscribeKey, perimeter },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_date_dynamic(apiKey, subscribe) {
    let request = await axios.post(
      urlApi + "setting/static/date/dynamic",
      subscribe,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_document_theme(apiKey) {
    let request = await axios.get(urlApi + "setting/theme/document", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async save_document_theme(apiKey, body) {
    let request = await axios.post(urlApi + "setting/theme/document", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // *** Theme Application ***

  async get_theme(apiKey, key) {
    let request = await axios.get(
      urlApi + "setting/theme/application?key=" + key,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    if (request.data) {
      request.data.theme = JSON.parse(request.data.theme);
    }
    return request.data;
  }

  async save_theme(apiKey, body) {
    // body = { themeKey, theme }
    const request = await axios.post(
      urlApi + "setting/theme/application",
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_list_themes(apiKey) {
    let request = await axios.get(urlApi + "setting/theme/application/all", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    if (request.data && request.data.length > 0) {
      for (const data of request.data) {
        data.theme = JSON.parse(data.theme);
      }
    }
    return request.data;
  }

  async delete_theme(apiKey, themeKey) {
    const request = await axios.post(
      urlApi + "setting/theme/application/delete",
      {
        themeKey: themeKey,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
