var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-start align-center" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          staticClass: "mr-1",
                          attrs: {
                            fab: "",
                            icon: "",
                            small: "",
                            outlined: "",
                            color: "white",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$store.commit(
                                "open_dialog_dashboard",
                                true
                              )
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "", color: "white" } }, [
                        _vm._v("mdi-sine-wave"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Consulter vos différents tableaux de bords")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }