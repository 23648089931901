<template>
  <v-autocomplete
    v-model="selectItem"
    :items="resultItems"
    :search-input.sync="strSearch"
    :loading="loadSearch"
    item-text="fullName"
    item-value="patientPath"
    label="Rechercher un adhérent, un laboratoire, une société"
    ref="searchMembers"
    id="inputNavsearchEntity"
    hide-details
    no-filter
    return-object
    class="mx-1 custom-autocomplete"
    prepend-inner-icon="mdi-magnify"
    dense
    dark
    :style="'width:' + widthSize + 'px'"
    background-color="navSecondary"
    outlined
  >
    <template v-slot:no-data>
      <div v-if="!strSearch || strSearch.length < 3" class="pa-1 caption">
        <span>Minimum 2 caractères pour lancer la recherche.</span>
      </div>
      <div v-else class="pa-1 caption">Pas de résultats</div>
    </template>

    <template v-slot:append>
      <v-icon>mdi-menu-down</v-icon>

      <AddEntity
        @click="resultItems = null"
        :btnStyle="false"
        class="my-n2"
        style="padding-top: 6px"
        :disabled="!$authorization.validateGrant('manageMember@brandpro')"
      />
    </template>

    <template v-slot:prepend-item>
      <v-container class="stick">
        <v-row justify="end" align="center" class="px-2">
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $store.commit('open_dialog_back_office', { open: true })
                  "
                  :disabled="$route.name == 'searchMembers'"
                  class="mr-2"
                  color="secondary"
                  small
                  >Recherche avancée</v-btn
                >
              </template>
              <span>Rechercher un adhérent sur différents critères</span>
            </v-tooltip>
            <AddEntity
              @closeSelect="$refs.searchMembers.blur()"
              :disabled="!$authorization.validateGrant('manageMember@brandpro')"
            />
          </div>
        </v-row>
      </v-container>
      <v-divider class="mb-1"></v-divider>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-avatar size="30" tile>
        <v-avatar tile>
          <v-icon small primary>{{ check_icon_type(item.nature) }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content :style="'max-width: 300px'">
        <v-list-item-title class="subtitle-1 primary--text">
          {{ name_to_display(item) }}
        </v-list-item-title>
        <v-list-item-subtitle class="body-2 d-flex">
          <div style="min-width: 200px">
            {{ name_nature(item.nature) }}

            <v-tooltip top v-if="item.underlying.code">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="info--text body-2">
                  {{ item.underlying.code }}
                </span>
              </template>
              <span> {{ check_text_code(item.nature) }} </span>
            </v-tooltip>
          </div>

          <span
            class="grey--text body-2"
            v-if="item.nature == 'MEMBER' && get_contact(item, 'PHONE')"
          >
            {{ get_contact(item, "PHONE") }}
          </span>
        </v-list-item-subtitle>

        <v-list-item-subtitle v-if="item.nature == 'MEMBER'">
          <span class="grey--text body-2"
            >{{ get_contact(item, "POSTAL_ADDRESS") }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar size="30" tile v-if="item.nature == 'MEMBER'">
        <v-avatar tile v-if="item.underlying.reducedMobility">
          <AvatarReducedMobility />
        </v-avatar>
      </v-list-item-avatar>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove_select_entity()"
        small
      >
        <v-avatar left>
          <v-icon small primary>{{ check_icon_type(data.item.nature) }}</v-icon>
        </v-avatar>
        {{ name_to_display(data.item) }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import EntitySearch from "@/class/entitysearch";
import AddEntity from "@/components/common/entity/AddEntity.vue";
import AvatarReducedMobility from "../member/profile/AvatarReducedMobility.vue";

export default {
  components: { AddEntity, AvatarReducedMobility },
  name: "NavSearchEntity",
  props: {
    widthSize: {
      type: String,
      default: "300",
    },
  },

  computed: {
    ...mapGetters(["apiKey"]),
    allKeys: function () {
      if (this.searchOption == "all") {
        return true;
      } else {
        return false;
      }
    },
  },

  data() {
    return {
      ClassMember: null,
      ClassEntitySearch: null,
      selectItem: null,
      resultItems: [],
      strSearch: null,
      loadSearch: false,
      searchOption: "name",
    };
  },

  watch: {
    selectItem(val) {
      if (val && val !== null) {
        this.select_entity(val);
      }
    },
    strSearch(val) {
      val && val !== this.selectItem && this.query_selections(val);
    },
    allKeys(newVal, oldVal) {
      if (newVal != oldVal && this.strSearch && this.strSearch.length > 0) {
        this.query_selections(this.strSearch);
      }
    },
  },

  methods: {
    // Search
    async query_selections(v) {
      clearTimeout(this.timeOut);
      if (v.length >= 2) {
        let queue = [];
        queue.push(v);
        this.loadSearch = true;
        this.timeOut = setTimeout(() => {
          this.search(queue.pop());
        }, 700);
      }
    },

    async search(value) {
      try {
        if (this.ClassEntitySearch == null) {
          this.ClassEntitySearch = new EntitySearch();
        }

        let resGetItems = await this.ClassEntitySearch.search(
          this.apiKey,
          value,
          false, // = crossBU.
          this.allKeys
        );
        this.resultItems = await resGetItems.entities;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSearch = false;
      }
    },

    async select_entity(entity) {
      // await this.go_to_details_member(member.memberUUID);
      switch (entity.nature) {
        case "MEMBER":
          this.$page_member(entity.entityUUID);

          break;
        case "COMPANY":
          this.$page_company(entity.entityUUID);
          break;
        case "LABORATORY":
          this.$page_laboratory(entity.entityUUID);
          break;
        default:
          break;
      }
      await new Promise((resolve) => setTimeout(resolve, 500)); // pause execution.
      this.remove_select_entity();
    },
    remove_select_entity() {
      this.selectItem = null;
      this.strSearch = null;
      this.searchOption = "name";
      this.$refs.searchMembers.blur();
    },

    // *** Utils Form ***

    get_contact(item, contactChannel) {
      let result = null;
      if (
        item.contacts &&
        item.contacts.contacts &&
        item.contacts.contacts.length > 0
      ) {
        const indexChannel = item.contacts.contacts
          .map((e) => {
            return e.channel;
          })
          .indexOf(contactChannel);
        if (indexChannel > -1) {
          if (contactChannel == "POSTAL_ADDRESS") {
            const postalCode =
              item.contacts.contacts[indexChannel].contact.postal.city.postCode;
            const city =
              item.contacts.contacts[indexChannel].contact.postal.city.city;
            result = `${postalCode} ${city}`;
          } else {
            result = item.contacts.contacts[indexChannel].displayValue;
          }
        }
      }
      return result;
    },

    // *** Traductions ***

    check_class_gender(gender) {
      if (gender == "MALE") {
        return "blue--text";
      } else if (gender == "FEMALE") {
        return "pink--text";
      } else {
        return "light-green--text";
      }
    },

    name_nature(nature) {
      switch (nature) {
        case "MEMBER":
          return "Adhérent";
        case "COMPANY":
          return "Société";
        case "LABORATORY":
          return "Laboratoire";
        default:
          return nature;
      }
    },

    check_icon_type(nature) {
      switch (nature) {
        case "MEMBER":
          return "mdi-account";
        case "COMPANY":
          return "mdi-domain";
        case "LABORATORY":
          return "mdi-store";
        default:
          return "mdi-account";
      }
    },

    name_to_display(item) {
      switch (item.nature) {
        case "MEMBER":
          return item.underlying.fullName;
        case "LABORATORY":
          return item.underlying.commercialName;
        case "COMPANY":
          return item.underlying.socialReason;
        default:
          break;
      }
    },

    check_text_code(nature) {
      switch (nature) {
        case "MEMBER":
          return "Code adhérent";
        case "COMPANY":
          return "Code société";
        case "LABORATORY":
          return "Code laboratoire";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
#inputNavsearchEntity .stick {
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
