var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", persistent: "" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "bgLvl3" } },
        [
          _c("v-card-title", { staticClass: "title primary--text" }, [
            _c("span", [_vm._v("SIREN incorrect ou inexistant")]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "pt-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0 body-2",
                          attrs: { type: "warning", text: "", dense: "" },
                        },
                        [
                          _vm._v(
                            " Attention, il semblerait que le SIREN renseigné soit incorrect ou inexistant. Veuillez renseigner un SIREN valide ou vérifier que la société existe bien. "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end align-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", "min-width": "100" },
                  on: { click: _vm.cancel_dialog },
                },
                [_vm._v("Fermer")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }