import axios from "axios";
const API = process.env.VUE_APP_URL_HORUS;

export default class Company {
  async count_company(apiKey, search) {
    const request = await axios.post(API + "omember/company/count", search, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }
  // Search

  async store_identification(apiKey, form) {
    let request = await axios.post(
      API + "omember/company/store/identification",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async check_store_identification(apiKey, form) {
    let request = await axios.post(API + "omember/company/store/check", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async store_company_contact(apiKey, form) {
    let request = await axios.post(
      API + "omember/company/store/contact",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_company_details(apiKey, companyUUID) {
    let request = await axios.get(
      API + "omember/company/details?companyUUID=" + companyUUID,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_company_list(apiKey) {
    let request = await axios.post(
      API + "omember/company/select/list",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_person(apiKey, companyUUID, form) {
    let request = await axios.post(
      API + "omember/company/store/person?companyUUID=" + companyUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_shareholder(apiKey, companyUUID, form) {
    let request = await axios.post(
      API + "omember/company/store/shareholder?companyUUID=" + companyUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_shareholder(apiKey, shareholderUUID) {
    let request = await axios.post(
      API +
        "omember/company/delete/shareholder?shareholderUUID=" +
        shareholderUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_role(apiKey, companyUUID, where) {
    let request = await axios.post(
      API +
        "omember/company/delete/person?companyUUID=" +
        companyUUID +
        "&where=" +
        where,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_area(apiKey, companyUUID, form) {
    let request = await axios.post(
      API + "omember/company/store/area?companyUUID=" + companyUUID,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_area(apiKey, areaUUID) {
    let request = await axios.post(
      API + "omember/company/delete/area?areaUUID=" + areaUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async search_company(apiKey, body) {
    let request = await axios.post(API + "omember/company/search", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async search_companies_v2(apiKey, form, pagination) {
    const request = await axios.post(
      API +
        "omember/company/search/v2?page=" +
        pagination.currentPage +
        "&size=" +
        pagination.size,
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async company_export_file(apiKey, body = {}) {
    const request = await axios.post(API + "omember/company/export", body, {
      headers: {
        "x-tenant": apiKey,
      },
      responseType: "blob",
    });
    return request.data;
  }

  async company_by_member(apiKey, memberUUID, body = {}) {
    const request = await axios.post(
      API + "omember/company/list/byMember?memberUUID=" + memberUUID,
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_company_filterKey_list(apiKey) {
    const request = await axios.post(
      API + "omember/company/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
