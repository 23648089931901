import axios from "axios";
const API = process.env.VUE_APP_URL_HORUS;

export default class EntitySearch {
  async search(apiKey, strSearch, crossBU, allKeys = false) {
    let request = await axios.post(
      API + "omember/entity/search",
      { search: strSearch, crossBU: crossBU, allKeys: allKeys },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async adv_search_member(apiKey, query, size, page) {
    let request = await axios.post(
      API + "omember/member/search?size=" + size + "&page=" + page,
      query,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
