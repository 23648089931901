// Confluence : https://opteamwork.atlassian.net/l/cp/U0nAr611
// Jira : https://opteamwork.atlassian.net/browse/OSW-2854

export default {
  page_member(memberUUID) {
    // Routage :
    if (memberUUID) {
      let route = "detailsMember";

      this.$router.push({
        name: route,
        params: { memberUUID: memberUUID },
      });
    } else {
      // pas de patientPath
      this.$router.push({
        name: "myOsmose",
      });
    }
  },

  page_company(companyUUID) {
    // Routage :
    if (companyUUID) {
      let route = "detailsCompany";

      this.$router.push({
        name: route,
        params: { companyUUID: companyUUID },
      });
    } else {
      // pas de patientPath
      this.$router.push({
        name: "myOsmose",
      });
    }
  },

  page_laboratory(laboratoryUUID) {
    // Routage :
    if (laboratoryUUID) {
      let route = "detailsLaboratory";

      this.$router.push({
        name: route,
        params: { laboratoryUUID: laboratoryUUID },
      });
    } else {
      // pas de patientPath
      this.$router.push({
        name: "myOsmose",
      });
    }
  },
};
