var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { on: { click: _vm.open_dialog_billing } },
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center align-center",
                  attrs: { cols: "1" },
                },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("mdi-bell-ring"),
                  ]),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "11" } }, [
                _c("div", { staticClass: "title primary--text" }, [
                  _vm._v(" " + _vm._s(_vm.tip.title) + " "),
                ]),
                _c("div", { staticClass: "subtitle-2 primary--text my-1" }, [
                  _vm._v(" " + _vm._s(_vm.tip.description) + " "),
                ]),
                _c("div", { staticClass: "caption" }, [
                  _vm._v(" " + _vm._s(_vm.tip.underlying.errorCode) + " "),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }