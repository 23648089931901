<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-form
      v-model="valid"
      ref="addMember"
      @submit.prevent="validation_form"
      lazy-validation
    >
      <v-card color="bgLvl3">
        <v-card-title class="title primary--text">
          Ajouter un adhérent
        </v-card-title>
        <v-card-text
          class="thinscrollbar pt-2"
          style="max-height: 500px; overflow-y: auto"
        >
          <v-row dense justify="start">
            <v-col cols="12" sm="4">
              <v-select
                v-model="member.civility"
                :items="listCivility"
                clearable
                item-text="label"
                item-value="uuid"
                label="Civilité"
                name="civilité"
                :disabled="loadForm"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="member.lastName"
                :label="
                  $t('osmose.dashboard_cards.addPatient.input_lastName')
                    | capitalize
                "
                :rules="[rules.required, rules.maxLength(64)]"
                :disabled="loadForm"
                outlined
                dense
                autocomplete="null"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="member.firstName"
                :label="
                  $t('osmose.dashboard_cards.addPatient.input_firstName')
                    | capitalize
                "
                :rules="[rules.required, rules.maxLength(64)]"
                :disabled="loadForm"
                outlined
                dense
                autocomplete="null"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="member.code"
                label="Code d'identification"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
                autocomplete="null"
              ></v-text-field
            ></v-col>

            <v-col cols="8">
              <v-text-field
                v-model="emailPro.email"
                label="Adresse email professionnelle"
                :rules="[rules.maxLength(64), rules.emailValid]"
                :disabled="loadForm"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels flat v-model="moreForm">
                <v-expansion-panel
                  :class="
                    $vuetify.theme.dark ? 'fakeInputDark' : 'fakeInputLight'
                  "
                  style="background-color: var(--v-bgLbl3-base)"
                >
                  <v-expansion-panel-header class="pa-2" ripple>
                    <span>Informations complémentaires (optionnelles)</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="mt-1">
                    <v-row dense>
                      <!--  <v-col cols="6">
                      <v-select
                          v-model="member.gender"
                          :items="genders"
                          item-text="name"
                          item-value="value"
                          label="Genre"
                          clearable
                          :disabled="loadForm"
                          outlined
                          dense
                        ></v-select> 
                      </v-col> -->

                      <v-col cols="4">
                        <Input-date
                          :dateModel.sync="member.dateOfBirth"
                          dateLabel="Date de naissance"
                          outlined
                          dense
                          clearable
                          todayMax
                          majority
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="member.placeOfBirth"
                          label="Lieu de naissance"
                          placeholder="75000 Paris"
                          :rules="[rules.maxLength(128)]"
                          :disabled="loadForm"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <!-- <v-autocomplete
                          v-model="member.countryOfBirth"
                          :items="countries"
                          item-text="countryName"
                          item-value="countryName"
                          label="Pays de naissance"
                          placeholder="France"
                          :disabled="loadForm"
                          name="countryOfBirth"
                          outlined
                          dense
                        ></v-autocomplete> -->
                        <InputCountry
                          :country.sync="member.countryOfBirth"
                          inputTitle="Pays de naissance"
                        />
                      </v-col>
                      <v-col cols="12">
                        <MazPhoneNumberInput
                          v-model="phonePro.num"
                          :default-country-code="countryForm"
                          :translations="{
                            countrySelectorLabel: 'Code pays',
                            countrySelectorError: 'Choisir un pays',
                            phoneNumberLabel: 'Numéro professionnel',
                            example: 'Exemple :',
                          }"
                          class="mb-2"
                          @update="isNumValid"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mt-0">
          <v-btn
            width="100"
            color="primary"
            text
            @click="close_modal()"
            :disabled="loadForm"
          >
            {{ $t("form.cancel") | capitalize }}
          </v-btn>
          <v-btn
            width="100"
            color="primary"
            type="submit"
            :disabled="!valid"
            :loading="loadForm"
          >
            {{ $t("form.validate") | capitalize }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import listCountry from "@/data/countryData.json";
import Member from "@/class/member";
import InputDate from "@/components/common/customFormFields/InputDate.vue";
import InputCountry from "@/components/common/customFormFields/InputCountry";
import { MazPhoneNumberInput } from "maz-ui";

export default {
  name: "AddMember",

  components: {
    InputDate,
    InputCountry,
    MazPhoneNumberInput,
  },

  computed: {
    ...mapGetters(["apiKey", "userAuth"]),
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    returnPatient: {
      type: Boolean,
      default: false,
      require: false,
    },
    disabled: {
      type: Boolean,
      require: false,
    },
  },

  data() {
    return {
      ClassMember: null,
      ClassOsmose: null,
      moreForm: 1, // 1 pour fermer
      loadForm: false,
      valid: false,
      countries: [],
      listCivility: [
        { label: "Monsieur", uuid: "monsieur" },
        { label: "Madame", uuid: "madame" },
      ],
      rules: {
        required: (v) => !!v || "Ce champ est requis.",
        maxLength: (len) => (v) =>
          (v || "").length <= len || `${len} caractères maximum`,
        emailValid: (v) =>
          (v || "").length == 0 || /.+@.+\..+/.test(v) || "E-mail invalide",
      },
      member: {
        lastName: null,
        firstName: null,
        code: null,
        dateOfBirth: null,
        gender: null,
        civility: null,
        countryOfBirth: null,
        placeOfBirth: null,
        civility: null,
        state: true,
        code: null,
      },
      emailPro: {
        email: null,
        type: "email",
      },
      phonePro: {
        num: null,
        phoneType: "Professionnel",
        country: this.countryForm,
        type: "phone",
      },
      // for form
      memberUUID: "",
      genders: [
        { name: "Homme", value: "MALE" },
        { name: "Femme", value: "FEMALE" },
        { name: "Autres", value: "INDETERMINATE" },
      ],
      countryForm: "",
    };
  },

  created() {
    this.ClassMember = new Member();
    this.countries = listCountry;
  },
  mounted() {
    this.init_form();
  },

  methods: {
    isNumValid(results) {
      if (results.phoneNumber != null && results.phoneNumber != "") {
        this.valid = results.isValid;
      }
    },
    init_form() {
      this.countryForm = this.userAuth.country.uuid;
    },

    // Submit
    async validation_form() {
      if (this.$refs.addMember.validate()) {
        await this.submit_form();
      } else {
        this.$refs.addMember.validate();
      }
    },

    async submit_form() {
      try {
        this.loadForm = true;

        this.member.mail = {
          email: this.member.email,
          type: "email",
        };
        delete this.member.email;

        let resMember = await this.ClassMember.store_member_identity(
          this.apiKey,
          this.member
        );

        if (this.emailPro.email != null) {
          let form = {};
          if (this.phonePro.num != null) {
            form.phonePro = this.phonePro;
          }
          form.memberUUID = resMember.memberUUID;
          form.emailPro = this.emailPro;

          await this.ClassMember.store_member_contact(this.apiKey, form);
        }

        // After POST
        this.memberUUID = resMember.memberUUID;
        if (!this.returnPatient) {
          this.$router.push({
            name: "detailsMember",
            params: { memberUUID: this.memberUUID },
          });
        } else {
          this.$emit("member_added", resMember);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.$emit("close");
        this.close_modal();
      }
    },

    close_modal() {
      this.openDialog = false;
      this.moreForm = 1;
      this.$refs.addMember.reset();
    },

    async origin_submited(originUUID) {
      await this.fetch_origin_available();
      this.member.origin.uuid = originUUID.key;
    },
  },
};
</script>

<style>
.fakeInputLight {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  /* corresponds to the colors of the vuetify input outlined */
}
.fakeInputDark {
  border: 1px solid rgba(255, 255, 255, 0.38) !important;
  color: rgba(255, 255, 255, 0.6) !important;
}

.maz-select__options-list {
  max-height: 150px !important;
}
</style>
