<template>
  <div>
    <v-avatar v-if="typeAvatar == 'default'" :size="avatarSize" color="grey">
      <v-img src="/images/avatars/avatar-svgrepo-com.svg" />
    </v-avatar>

    <v-avatar v-if="typeAvatar == 'picture'" :size="avatarSize" color="grey">
      <v-img :src="imgSrc" />
    </v-avatar>

    <v-avatar
      v-if="typeAvatar == 'trigram'"
      :color="check_color_bg(avatarInfo.background)"
      :size="avatarSize"
    >
      <span
        :class="check_text_size()"
        :style="
          'color:' +
          check_color_text_avatar(avatarInfo.foreground, avatarInfo.background)
        "
      >
        {{ avatarInfo.label }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "UserAvatar",

  props: {
    avatarInfo: {
      type: Object,
      require: true,
    },
    avatarSize: {
      type: Number,
      default: 40,
    },
  },

  computed: {
    typeAvatar() {
      if (this.avatarInfo && this.avatarInfo.image) {
        return "picture";
      } else if (this.avatarInfo && this.avatarInfo.label) {
        return "trigram";
      } else {
        return "default";
      }
    },

    imgSrc() {
      if (this.avatarInfo && this.avatarInfo.image) {
        return this.avatarInfo.image.prefixData + this.avatarInfo.image.content;
      }
    },
  },

  methods: {
    // *** Utils ***

    check_text_size() {
      if (this.avatarSize < 40) {
        if (this.avatarInfo.label.length > 2) {
          return "caption";
        } else {
          return "body-2";
        }
      } else {
        if (this.avatarInfo.label.length == 3) {
          return "subtitle-1";
        } else if (this.avatarInfo.label.length == 4) {
          return "caption";
        } else {
          return "title";
        }
      }
    },

    check_color_bg(bgColor) {
      if (bgColor) {
        return bgColor;
      } else {
        return "grey";
      }
    },

    check_color_text_avatar(colorText, colorBg) {
      if (colorText) {
        return colorText;
      } else if (colorBg) {
        return check_color_text(colorBg);
      } else {
        return "white";
      }
    },

    check_color_text(hexColor) {
      let hexToRgb = function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : null;
      };
      let colorRgb = hexToRgb(hexColor);
      let cText = "";
      const calcColor =
        colorRgb.r * 0.299 + colorRgb.g * 0.587 + colorRgb.b * 0.114;
      if (calcColor > 150) {
        cText = "#000000";
      } else {
        cText = "#ffffff";
      }
      return cText;
    },
  },
};
</script>
