<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="350"
    offset-x
    z-index="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab dark v-bind="attrs" v-on="on" color="white">
        <v-icon color="navPrimary">mdi-apps</v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="pa-1 d-flex justify-end">
        <v-btn small text color="secondary" @click="$emit('change-tenant')"
          >Changer d'espace de travail
        </v-btn>
      </div>
      <v-divider class="mx-2"></v-divider>
      <div class="pa-2">
        <v-row dense>
          <v-col cols="6" v-for="item of items" :key="item.uuid">
            <v-hover v-slot="{ hover }">
              <v-card
                flat
                v-blur
                :color="hover ? 'soft' : ''"
                class="d-flex flex-column align-center justify-center"
                @click.stop="select_app(item.link)"
                :disabled="
                  item.currentApp || !$authorization.validateGrant(item.grant)
                "
              >
                <v-badge
                  :value="!$authorization.validateGrant(item.grant)"
                  bordered
                  overlap
                  color="error"
                  icon="mdi-lock"
                >
                  <v-icon
                    size="50"
                    :color="item.currentApp ? 'grey' : item.color"
                    >{{ item.icon }}</v-icon
                  >
                </v-badge>
                <div
                  class="subtitle-2"
                  :class="{ 'grey--text': item.currentApp }"
                >
                  {{ item.name }}
                </div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "MenuSwitchApps",

  data() {
    return {
      menu: false,
      items: [
        {
          uuid: "central",
          name: "Centrale",
          icon: "mdi-set-all",
          color: "#FFCA28",
          link: process.env.VUE_APP_URL_CENTRAL,
          currentApp: false,
          grant: "accCentral@brandpro",
        },
        {
          uuid: "invoice",
          name: "FactureR",
          icon: "mdi-currency-usd",
          color: "#FF8A65",
          link: process.env.VUE_APP_URL_INVOICE,
          currentApp: false,
          grant: "accInvoice@brandpro",
        },
        {
          uuid: "member",
          name: "Adhérent",
          icon: "mdi-account-group",
          color: "#90CAF9",
          link: process.env.VUE_APP_URL_MEMBER,
          currentApp: true,
          grant: "accMember@brandpro",
        },
        {
          uuid: "supplier",
          name: "Fournisseur",
          icon: "mdi-truck-delivery",
          color: "#9CCC65",
          link: process.env.VUE_APP_URL_SUPPLIER,
          currentApp: false,
          grant: "accSupplier@brandpro",
        },
        // {
        //   uuid: "catalog",
        //   name: "Catalogues",
        //   icon: "mdi-book-open",
        //   color: "#D4E157",
        //   link: null,
        // },
      ],
    };
  },

  methods: {
    select_app(link) {
      if (link) {
        window.open(link);
      }
    },
  },
};
</script>
