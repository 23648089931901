var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          ref: "button",
          staticClass: "drawer-button",
          attrs: { color: _vm.colorHorusDesktop, dark: "" },
          on: {
            click: function ($event) {
              _vm.menuDesktop = true
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-desktop-classic")])],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            fixed: "",
            right: "",
            "hide-overlay": "",
            temporary: "",
            width: "350",
            color: "bgLvl3",
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "pa-2 overline",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://desktop.opteamwork.io/",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" Accéder au module support ")]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.menuDesktop,
            callback: function ($$v) {
              _vm.menuDesktop = $$v
            },
            expression: "menuDesktop",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "pa-2" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-start" },
                    [
                      _c("v-switch", {
                        staticClass: "mt-0",
                        attrs: {
                          "hide-details": "",
                          color: "success",
                          dense: "",
                          inset: "",
                          label: "Activer horus desktop",
                        },
                        model: {
                          value: _vm.activePing,
                          callback: function ($$v) {
                            _vm.activePing = $$v
                          },
                          expression: "activePing",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "cursor-pointer",
                  attrs: { dense: "" },
                  on: {
                    click: function ($event) {
                      _vm.menuDesktop = false
                    },
                  },
                },
                [
                  _c("v-col", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("Horus Desktop "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "mr-2", attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.menuDesktop = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.statusDesktop &&
              _vm.responseStatusDesktop.desktop &&
              _vm.responseStatusDesktop.desktop["version"]
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { staticClass: "overline" }, [
                        _vm._v(
                          " V" +
                            _vm._s(
                              _vm.responseStatusDesktop.desktop["version"]
                            ) +
                            " - Build " +
                            _vm._s(_vm.responseStatusDesktop.desktop["build"]) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.statusDesktop
                ? _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/horusDesktop/horusDesktop.png"),
                              "aspect-ratio": "2",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" Horus Desktop n'est pas démarré "),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("InstallHorusDesktop")],
                        1
                      ),
                      !_vm.statusDesktop
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "ma-0 body-2",
                                  attrs: { type: "info", text: "", dense: "" },
                                },
                                [
                                  _vm._v(
                                    "Ce module permet d'intéragir avec le matériel installé sur votre poste (imprimante, scan, etc...)"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.statusDesktop
                ? _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("Profile", {
                            attrs: {
                              loading: false,
                              listPrintOK: _vm.loadingPrinters,
                              listScanOK: _vm.loadingScans,
                            },
                            on: {
                              "update:listPrintOK": function ($event) {
                                _vm.loadingPrinters = $event
                              },
                              "update:list-print-o-k": function ($event) {
                                _vm.loadingPrinters = $event
                              },
                              "update:listScanOK": function ($event) {
                                _vm.loadingScans = $event
                              },
                              "update:list-scan-o-k": function ($event) {
                                _vm.loadingScans = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }