<template>
  <div style="height: 100%">
    <v-tooltip bottom v-if="!btnStyle && !btnCard">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          plain
          style="opacity: 1"
          text
          @click="addEntityDialog = true"
          :disabled="disabled"
        >
          <v-icon dark class="pr-1"> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Ajouter un adhérent, un laboratoire ou une société</span>
    </v-tooltip>

    <v-tooltip top v-if="btnStyle && !btnCard">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
          color="primary"
          @click="addEntityDialog = true"
          :disabled="disabled"
        >
          <v-icon left small> mdi-plus </v-icon>
          <span> Ajouter une entité </span>
        </v-btn>
      </template>
      <span>Ajouter un adhérent, un laboratoire ou une société</span>
    </v-tooltip>

    <v-card
      v-if="btnCard && !btnStyle"
      color="info"
      dark
      height="100%"
      hover
      @click="addEntityDialog = true"
      :disabled="disabled"
    >
      <div
        style="height: 100%; width: 100%"
        class="d-flex flex-column justify-center align-center text-center pa-2"
      >
        <v-icon x-large>mdi-plus</v-icon>
        <div class="mt-1 subtitle-1">Créer une nouvelle entité</div>
      </div>
    </v-card>

    <v-dialog
      v-model="addEntityDialog"
      v-if="addEntityDialog"
      persistent
      max-width="1000"
    >
      <v-card color="bgLvl3">
        <v-card-title class="title primary--text">
          Que souhaitez-vous créer ?
        </v-card-title>
        <v-card-text
          class="thinscrollbar pt-1"
          style="max-height: 500px; overflow-y: auto"
        >
          <v-row dense>
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="4" v-bind="attrs" v-on="on">
                  <v-card v-blur hover @click="open_dialog_add_member">
                    <v-card-title class="d-flex justify-center info--text">
                      Un adhérent
                    </v-card-title>
                    <v-card-text class="d-flex justify-center">
                      <v-icon x-large color="info">mdi-account</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <span>
                Un adhérent aussi appelé membre, est une personne <br />
                physique gérante d'une société, actionnaire ou autre.</span
              >
            </v-tooltip>
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="4" v-bind="attrs" v-on="on">
                  <v-card v-blur hover @click="open_dialog_add_company">
                    <v-card-title class="d-flex justify-center info--text">
                      Une société
                    </v-card-title>
                    <v-card-text class="d-flex justify-center">
                      <v-icon x-large color="info">mdi-domain</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <span>
                Une société est une personne morale identifiée par <br />
                un SIREN et un n° TVA Intracommunautaire.</span
              >
            </v-tooltip>
            <v-tooltip top color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-col cols="4" v-bind="attrs" v-on="on">
                  <v-card v-blur hover @click="open_dialog_add_laboratory">
                    <v-card-title class="d-flex justify-center info--text">
                      Un laboratoire
                    </v-card-title>
                    <v-card-text class="d-flex justify-center">
                      <v-icon x-large color="info">mdi-store</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <span>
                Un laboratoire est un point de vente ou un centre ayant
                obligatoirement <br />
                un n° SIRET, un n° FINESS, une adresse postale et une adresse de
                livraison.</span
              >
            </v-tooltip>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn width="100" color="primary" text @click="close_modal">
            {{ $t("form.cancel") | capitalize }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddMember
      v-if="addMemberDialog"
      :showDialog.sync="addMemberDialog"
      @close="close_modal"
    />
    <DialogCreateCompany
      v-if="addCompanyDialog"
      :showDialog.sync="addCompanyDialog"
      @close="close_modal"
    />
    <DialogCreateLaboratory
      v-if="addLaboratoryDialog"
      :showDialog.sync="addLaboratoryDialog"
      @close="close_modal"
    />
  </div>
</template>

<script>
import AddMember from "../../member/profile/AddMember.vue";
const DialogCreateCompany = () =>
  import("../../company/DialogCreateCompany.vue");
const DialogCreateLaboratory = () =>
  import("../../laboratory/DialogCreateLaboratory.vue");
export default {
  name: "AddEntity",
  components: {
    AddMember,
    DialogCreateCompany,
    DialogCreateLaboratory,
  },
  props: {
    btnStyle: {
      type: Boolean,
      default: false,
    },
    btnCard: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      require: false,
    },
  },

  data() {
    return {
      addEntityDialog: false,
      addMemberDialog: false,
      addLaboratoryDialog: false,
      addCompanyDialog: false,
    };
  },

  methods: {
    open_dialog_add_member() {
      this.addMemberDialog = true;
    },
    open_dialog_add_laboratory() {
      this.addLaboratoryDialog = true;
    },
    open_dialog_add_company() {
      this.addCompanyDialog = true;
    },

    close_modal() {
      this.addEntityDialog = false;
    },
  },
};
</script>

<style>
.fakeInputLight {
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  /* corresponds to the colors of the vuetify input outlined */
}
.fakeInputDark {
  border: 1px solid rgba(255, 255, 255, 0.38) !important;
  color: rgba(255, 255, 255, 0.6) !important;
}

.maz-select__options-list {
  max-height: 150px !important;
}
</style>
