var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _vm.renderComponent && _vm.currentBu && _vm.$vuetify.breakpoint.lgAndUp
        ? _c("Navbar-desktop", { attrs: { currentBu: _vm.currentBu } })
        : _vm._e(),
      _vm.renderComponent && _vm.currentBu && _vm.$vuetify.breakpoint.mdAndDown
        ? _c("Navbar-mobile")
        : _vm._e(),
      _c(
        "v-main",
        [
          _vm.renderComponent && _vm.currentBu
            ? _c("div", [_vm.localeOk ? _vm._t("default") : _vm._e()], 2)
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: "",
                "hide-overlay": "",
                transition: "dialog-bottom-transition",
              },
              model: {
                value: _vm.modalCGV,
                callback: function ($$v) {
                  _vm.modalCGV = $$v
                },
                expression: "modalCGV",
              },
            },
            [
              _c("CGV", {
                on: {
                  close: function ($event) {
                    _vm.modalCGV = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("HorusDesktopMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }