export default [
  {
    path: "/sign/api/:apiKey",
    name: "publicSignature",
    component: () => import("@/pages/public/SignDocument.vue"),
    meta: {
      layout: "Public"
    }
  }

]