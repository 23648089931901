export default [
  {
    path: "/my-member",
    component: () => import("@/pages/myOsmose/IndexMyOsmose.vue"),
    beforeEnter: (to, from, next) => {
      const store = require("../store/index");
      const typeBu = store.default.state.currentBu.tenant.type;
      next();
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "myOsmose",
        component: () => import("@/pages/myOsmose/MyOsmose.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "member/:memberUUID",
        component: () => import("../pages/myOsmose/member/IndexMember"),
        children: [
          {
            path: "",
            name: "detailsMember",
            meta: {
              requiresAuth: true,
            },
            component: () => import("../pages/myOsmose/member/DetailsMember"),
          },
        ],
      },
      {
        path: "company/:companyUUID",
        component: () => import("../pages/myOsmose/company/IndexCompany"),
        children: [
          {
            path: "",
            name: "detailsCompany",
            meta: {
              requiresAuth: true,
            },
            component: () => import("../pages/myOsmose/company/DetailsCompany"),
          },
        ],
      },
      {
        path: "laboratory/:laboratoryUUID",
        component: () => import("../pages/myOsmose/laboratory/IndexLaboratory"),
        children: [
          {
            path: "",
            name: "detailsLaboratory",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("../pages/myOsmose/laboratory/DetailsLaboratory"),
          },
        ],
      },

      // {
      //   path: "inventory/:inventoryUUID",
      //   name: "inventoryStock",
      //   component: () => import("@/pages/inventory/inventoryPages.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
    ],
  },
];
