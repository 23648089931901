export default {
  // global theme for the app
  globalTheme: "light", // light | dark

  // side menu theme, use global theme or custom
  menuTheme: "global", // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: "global", // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    bgLvl0: "#F2F2F2", // pages
    bgLvl1: "#f2f3f6", // widgets
    bgLvl2: "#ffffff", // cards sur widgets
    bgLvl3: "#ffffff", // dialog fond
    soft: "#f3f7f7",
    navPrimary: "#111640",
    navSecondary: "#00BFB2",
    primary: "#31525b",
    secondary: "#00BFB2",
    accent: "#FFF500",
    active: "#039BE5",
    // alerts
    info: "#039BE5",
    error: "#FF5252",
    success: "#4caf50",
    warning: "#fb8c00",
  },

  // light theme colors
  light: {
    bgLvl0: "#F2F2F2", // pages
    bgLvl1: "#f2f3f6", // widgets
    bgLvl2: "#ffffff", // cards sur widgets
    bgLvl3: "#ffffff", // dialog fond
    soft: "#f3f7f7",
    navPrimary: "#3474a3",
    navSecondary: "#253165",
    primary: "#253165",
    secondary: "#3474a3",
    accent: "#FFF500",
    active: "#039BE5",
    // alerts
    info: "#039BE5",
    error: "#FF5252",
    success: "#4caf50",
    warning: "#fb8c00",
  },
};

// origine LUX
//   background: "#ffffff",
//   surface: "#f2f5f8",
//   primary: "#0096c7",
//   secondary: "#a0b9c8",
//   accent: "#048ba8",
//   error: "#ef476f",
//   info: "#2196F3",
//   success: "#06d6a0",
//   warning: "#ffd166"
