var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", persistent: "" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "bgLvl3" } },
        [
          _c("v-card-title", { staticClass: "title primary--text" }, [
            _c("span", [_vm._v("Nous avons trouvé une société")]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "py-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-0 body-2",
                          attrs: { type: "info", text: "", dense: "" },
                        },
                        [
                          _vm._v(
                            " Nous avons trouvé les informations suivantes pour le SIREN "
                          ),
                          _c("b", [
                            _vm._v(_vm._s(_vm.companyInformations.siren)),
                          ]),
                          _vm._v(". "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "table",
                      { class: _vm.$vuetify.theme.dark ? "ctnTableDark" : "" },
                      [
                        _c(
                          "tbody",
                          { staticClass: "body-2" },
                          _vm._l(_vm.fields, function (item, index) {
                            return _c(
                              "tr",
                              { key: index, style: _vm.trColor },
                              [
                                _c("td", { staticClass: "primary--text" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("capitalize")(item.text)) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    class: _vm.$vuetify.theme.dark
                                      ? "tValueDark"
                                      : "tValueLight",
                                  },
                                  [
                                    item.value != null
                                      ? _c("span", [
                                          _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.value) + " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "formCreateCompanySiren",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.validation_form.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mr-2",
                            attrs: {
                              label: "Code d'identification",
                              rules: [_vm.rules.required],
                              outlined: "",
                              dense: "",
                              autocomplete: "null",
                            },
                            model: {
                              value: _vm.companyInformations.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyInformations, "code", $$v)
                              },
                              expression: "companyInformations.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end align-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", "min-width": "100" },
                  on: { click: _vm.cancel_dialog },
                },
                [_vm._v("Annuler")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", "min-width": "100" },
                  on: { click: _vm.validation_form },
                },
                [_vm._v("Créer")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }