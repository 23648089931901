var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.typeAvatar == "default"
        ? _c(
            "v-avatar",
            { attrs: { size: _vm.avatarSize, color: "grey" } },
            [
              _c("v-img", {
                attrs: { src: "/images/avatars/avatar-svgrepo-com.svg" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.typeAvatar == "picture"
        ? _c(
            "v-avatar",
            { attrs: { size: _vm.avatarSize, color: "grey" } },
            [_c("v-img", { attrs: { src: _vm.imgSrc } })],
            1
          )
        : _vm._e(),
      _vm.typeAvatar == "trigram"
        ? _c(
            "v-avatar",
            {
              attrs: {
                color: _vm.check_color_bg(_vm.avatarInfo.background),
                size: _vm.avatarSize,
              },
            },
            [
              _c(
                "span",
                {
                  class: _vm.check_text_size(),
                  style:
                    "color:" +
                    _vm.check_color_text_avatar(
                      _vm.avatarInfo.foreground,
                      _vm.avatarInfo.background
                    ),
                },
                [_vm._v(" " + _vm._s(_vm.avatarInfo.label) + " ")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }