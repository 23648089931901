<template>
  <v-toolbar
    :absolute="absolute"
    width="100%"
    color="navPrimary"
    style="box-shadow: 0px 5px 15px -5px rgba(0,0,0, 0.6)!important"
    @click="$emit('clicked')"
  >
    <v-container fluid class="pa-0">
      <v-row
        dense
        class="cursor-pointer"
        justify="center"
        align="center"
        justify-md="start"
      >
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-start align-center "
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <span class="white--text body-2"
            >Cliquez sur cette barre pour fermer le rideau</span
          >
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <v-icon x-large color="white">mdi-chevron-down</v-icon>
            <h3 class="mx-1 white--text">
              {{ label }}
            </h3>
            <v-icon x-large color="white">mdi-chevron-down</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  name: "CurtainToolbar",
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      require: true
    }
  }
};
</script>
