var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticStyle: {
        "box-shadow": "0px 5px 15px -5px rgba(0, 0, 0, 0.6) !important",
      },
      attrs: { app: "", color: "navPrimary" },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-0 ma-0",
          attrs: { color: "transparent", flat: "", width: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between align-center",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-1" },
                    [
                      _c("MenuSwitchApps", {
                        on: {
                          "change-tenant": function ($event) {
                            _vm.dialogSelectTenant = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("SelectTenantsBtn", {
                    attrs: {
                      externalOpening: _vm.dialogSelectTenant,
                      hideBtn: true,
                    },
                    on: {
                      "update:externalOpening": function ($event) {
                        _vm.dialogSelectTenant = $event
                      },
                      "update:external-opening": function ($event) {
                        _vm.dialogSelectTenant = $event
                      },
                    },
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-1",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.route_to_homepage()
                                        },
                                      },
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-home"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Aller à l'accueil")])]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _vm.$vuetify.breakpoint.lgAndUp ? _c("Menu-nav") : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: { outlined: "", color: "white" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.$store.commit(
                                            "open_dialog_back_office",
                                            { open: true }
                                          )
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", color: "white" } },
                                    [_vm._v("mdi-chevron-up-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "white--text font-weight-medium",
                                    },
                                    [_vm._v(" Back office ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Ouvrir le Back-office")])]
                  ),
                  _c("NavSearchEntity", {
                    attrs: {
                      widthSize: _vm.$vuetify.breakpoint.lgAndDown
                        ? "400"
                        : "500",
                    },
                  }),
                  _c("Toolbar-user", { staticClass: "ml-1" }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.showDialogCreateCompany
        ? _c("DialogCreateCompany", {
            attrs: { showDialog: _vm.showDialogCreateCompany },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDialogCreateCompany = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDialogCreateCompany = $event
              },
            },
          })
        : _vm._e(),
      _vm.showDialogCreateLaboratory
        ? _c("DialogCreateLaboratory", {
            attrs: { showDialog: _vm.showDialogCreateLaboratory },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDialogCreateLaboratory = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDialogCreateLaboratory = $event
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }