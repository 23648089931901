<template>
  <v-list-item @click="open_dialog_billing">
    <v-list-item-content>
      <v-row dense>
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon color="primary">mdi-bell-ring</v-icon>
        </v-col>
        <v-col cols="11">
          <div class="title primary--text">
            {{ tip.title }}
          </div>
          <div class="subtitle-2 primary--text my-1">
            {{ tip.description }}
          </div>
          <div class="caption">
            {{ tip.underlying.errorCode }}
          </div>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserTipsBillingStatus",
  computed: {
    ...mapState(["currentBu"]),
  },
  props: {
    tip: {
      type: Object,
      require: true,
    },
  },

  methods: {
    open_dialog_billing() {
      if (
        this.currentBu.authorizations["manageBilling@billing"] == "ACCESSIBLE"
      ) {
        this.$store.commit("update_fullPageDialog", {
          title: "menu.billing",
          component: "billing",
        });
      }
    },
  },
};
</script>
