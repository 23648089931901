import axios from "axios";
// import store from "../store/index.js";

let API = process.env.VUE_APP_URL_HORUS;

export default class osmose {
  async set_patient_health(apiKey, params) {
    let request = await axios.post(
      API + "osmose/patient/store/health",
      params,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async add_patient(apiKey, patient) {
    let request = await axios.post(API + "osmose/patient/store", patient, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_list_patients(apiKey, size, page, query) {
    let FormData = require("form-data");
    let data = new FormData();

    if (query != undefined) {
      data.append("query", query);
    } else data = query;

    let request = await axios.post(
      API + "osmose/patient?size=" + size + "&page=" + page,
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_patient_details(apiKey, patientPath) {
    let request = await axios.get(
      API + "osmose/patient/detail?patientPath=" + patientPath,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Search
  async search_patient(apiKey, strSearch, crossBU, allKeys = false) {
    let request = await axios.post(
      API + "osmose/patient/search",
      { search: strSearch, crossBU: crossBU, allKeys: allKeys },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async adv_search_patient(apiKey, query, size, page) {
    let request = await axios.post(
      API + "osmose/patient/search?size=" + size + "&page=" + page,
      query,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // project tags
  async get_patient_tags_used(apiKey) {
    let request = await axios.get(API + "osmose/patient/tag/used", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_patient_tags_available(apiKey) {
    let request = await axios.get(API + "osmose/patient/tag", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async add_patient_tags(apiKey, projectPath, objectTags) {
    let request = await axios.post(
      API + "osmose/patient/tag?path=" + projectPath,
      objectTags,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Pathology

  async get_patient_pathology_available_tags(apiKey) {
    const request = await axios.get(API + "osmose/patient/pathology", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async add_patient_pathology(apiKey, patientPath, objectTags) {
    const request = await axios.post(
      API + "osmose/patient/pathology?path=" + patientPath,
      objectTags,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Story
  async story_patient(apiKey, patientPath, natures, mode, size, page) {
    let urlStory = "";
    if (natures != "") {
      urlStory =
        API +
        "osmose/story/patient?patientPath=" +
        patientPath +
        "&natures=" +
        natures +
        "&size=" +
        size +
        "&page=" +
        page +
        "&mode=" +
        mode;
    } else {
      urlStory =
        API +
        "osmose/story/patient?patientPath=" +
        patientPath +
        "&size=" +
        size +
        "&page=" +
        page +
        "&mode=" +
        mode;
    }

    let request = await axios.post(
      urlStory,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async story_patient_rich(apiKey, patientPath, natures, mode, size, page) {
    let urlStory = "";
    if (natures != "") {
      urlStory =
        API +
        "osmose/story/patient/rich?patientPath=" +
        patientPath +
        "&natures=" +
        natures +
        "&size=" +
        size +
        "&page=" +
        page +
        "&mode=" +
        mode;
    } else {
      urlStory =
        API +
        "osmose/story/patient/rich?patientPath=" +
        patientPath +
        "&size=" +
        size +
        "&page=" +
        page +
        "&mode=" +
        mode;
    }

    let request = await axios.post(
      urlStory,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_draft_note(apiKey, eventUUID) {
    let request = await axios.post(
      API + "osmose/story/draft/note/set?eventUUID=" + eventUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Patient documents
  async get_patient_document_content(apiKey, body) {
    let request = await axios.post(
      API + "osmose/patient/document/content",
      JSON.parse(body),
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_patient_document(apiKey, body) {
    let request = await axios.post(
      API + "osmose/patient/document",
      JSON.parse(body),
      {
        headers: {
          "x-tenant": apiKey,
        },
        responseType: "blob",
      }
    );
    return request.data;
  }

  async get_patient_document_output(apiKey, body) {
    // ?output=pdf;png
    let request = await axios.post(
      API + "osmose/patient/document",
      JSON.parse(body),
      {
        headers: {
          "x-tenant": apiKey,
        },
        responseType: "blob",
      }
    );

    // NOTE récupération du nom de fichier + extension
    request.data.fileName =
      request.request.getResponseHeader("x-resource-name");
    return request.data;
  }

  async delete_patient_document(apiKey, documentUUID) {
    let request = await axios.post(
      API + "osmose/patient/document/delete?documentUUID=" + documentUUID,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
        responseType: "blob",
      }
    );
    return request.data;
  }

  // Osmose/health
  async set_professional(apiKey, form) {
    let request = await axios.post(
      API + "osmose/health/professional/store",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_list_professionals(apiKey, options, page, size) {
    const request = await axios.post(
      API + "osmose/health/professional?size=" + size + "&page=" + page,
      {
        allowDeliverAct: options.allowDeliverAct,
        allowDeliverQuote: options.allowDeliverQuote,
        pattern: options.pattern,
        speciality: options.speciality ? options.speciality : null,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async set_favorite_professional(apiKey, body) {
    const request = await axios.post(
      API + "osmose/health/professional/favorite",
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_act(apiKey, form) {
    let request = await axios.post(API + "osmose/health/act/store", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_acts_list(apiKey, patientUUID, healthSpeciality, page) {
    let request = await axios.post(
      API + "osmose/health/act?size=10" + "&page=" + page,
      {
        patientUUID,
        healthSpeciality,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async assign_professional_to_patient(
    apiKey,
    patientUUID,
    natureDoctor,
    doctor = { professionalUUID: null }
  ) {
    const request = await axios.post(
      API +
        "osmose/patient/store/health/" +
        natureDoctor +
        "?patientUUID=" +
        patientUUID,
      doctor,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async assign_professional_to_patient_header(
    apiKey,
    patientUUID,
    professionalUUID
  ) {
    const request = await axios.post(
      API +
        "osmose/patient/store/health/healthOwner" +
        "?patientUUID=" +
        patientUUID,
      professionalUUID,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async act_dummy_health(apiKey, patientUUID, speciality) {
    const request = await axios.post(
      API +
        "osmose/health/act/dummy?patientUUID=" +
        patientUUID +
        "&speciality=" +
        speciality,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async merge_patients(apiKey, params) {
    const request = await axios.post(API + "osmose/patient/merge", params, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // Static

  async get_filterKey_list(apiKey) {
    let request = await axios.post(
      API + "osmose/static/patient/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_static_specialities(apiKey) {
    let request = await axios.post(
      API + "osmose/static/health/speciality",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_static_civilities(apiKey) {
    let request = await axios.post(
      API + "osmose/static/civility",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_static_jobs(apiKey) {
    let request = await axios.post(
      API + "osmose/static/health/job",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
  async get_static_civility() {
    let listCivility = [
      { label: "Monsieur", uuid: "monsieur" },
      { label: "Madame", uuid: "madame" },
    ];
    return listCivility;
  }
  async search_static_ps_catalog(apiKey, searchForm) {
    const request = await axios.post(
      API + "osmose/static/health/ps",
      {
        pattern: searchForm.searchStr,
        postalCodePattern: searchForm.postalCodePattern,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_natures_documents(apiKey) {
    let request = await axios.post(
      API + "osmose/static/document/nature/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_static_issue_nature(apiKey) {
    const request = await axios.post(
      API + "osmose/static/issueNature/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_static_issue_nature(apiKey, newNature) {
    const request = await axios.post(
      API + "osmose/static/issueNature/set",
      newNature,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_static_maintains_nature(apiKey) {
    const request = await axios.post(
      API + "osmose/static/maintenanceNature/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_static_maintains_nature(apiKey, newNature) {
    const request = await axios.post(
      API + "osmose/static/maintenanceNature/set",
      newNature,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async static_insure(apiKey) {
    const request = await axios.post(
      API + "osmose/static/insurer/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async list_static_organization(apiKey, size, page) {
    const request = await axios.post(
      API + "osmose/static/organization?size=" + size + "&page=" + page,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_static_organization(apiKey, organization) {
    const request = await axios.post(
      API + "osmose/static/organization/store",
      organization,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // patient articles
  async get_patient_articles(apiKey, patientUUID, page, size, body = {}) {
    const request = await axios.post(
      API +
        "osmose/patient/delivery/consumable?patientUUID=" +
        patientUUID +
        "&size=" +
        size +
        "&page=" +
        page,
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_followUp(apiKey, deliveryNature, deliveryUUID, follow) {
    const request = await axios.post(
      API +
        "osmose/patient/delivery/followUp?deliveryNature=" +
        deliveryNature +
        "&prestationUUID=" +
        deliveryUUID +
        "&followUp=" +
        follow,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // Document
  async upload_act_document(
    apiKey,
    isEdit,
    patientUUID,
    documentActEdm,
    file,
    baseUrl,
    nameExtansion
  ) {
    let url = baseUrl + "?patientUUID=" + patientUUID;
    if (isEdit) {
      url = baseUrl + "?patientUUID=" + patientUUID + "&edm=" + documentActEdm;
    }
    let FormData = require("form-data");
    let data = new FormData();
    var blob = new Blob([file], { type: "text/xml" });
    data.append("resource", blob, nameExtansion);

    let request = await axios.post(API + url, data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async upload_document(apiKey, file, nameExtansion) {
    // charge un document et renvoie un num edm
    // resource peut etre null
    // retourn un json a parser
    let FormData = require("form-data");
    const data = new FormData();
    var blob = new Blob([file], { type: "text/xml" });
    data.append("resource", blob, nameExtansion);
    let request = await axios.post(
      API + "osmose/patient/document/upload",
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async store_document(apiKey, data) {
    let request = await axios.post(
      API + "osmose/patient/document/store",
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // *** Osmose dashboard ***

  async get_alerts(apiKey) {
    let request = await axios.post(
      API + "osmose/dashboard/alerts",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_alerts_patient(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/dashboard/patientAlerts",
      { patientUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_shortcuts(apiKey) {
    let request = await axios.post(
      API + "osmose/dashboard/shortcuts",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_searchs_by_perimeter(apiKey, perimeter) {
    const request = await axios.post(
      API + "osmose/dashboard/search",
      {
        perimeter,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_search(apiKey, search) {
    let request = await axios.post(API + "osmose/dashboard/save", search, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_objectives(apiKey) {
    let request = await axios.post(
      API + "osmose/dashboard/objectives",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_tips(apiKey) {
    let request = await axios.get(API + "osmose/story/tips", {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async check_simulation(
    apiKey,
    recordUUID,
    situationUUID,
    frModeSecurisation,
    executionDate,
    topSimlation,
    type,
    isAld,
    areaVersion,
    dateFactureTest
  ) {
    let request = await axios.post(
      API + "osmose/area/facturer",
      {
        recordUUID,
        situationUUID,
        frModeSecurisation,
        executionDate,
        topSimlation,
        type,
        isAld,
        areaVersion,
        dateFactureTest,
      },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async simuler_facturer_retour(apiKey, content) {
    let request = await axios.post(API + "osmose/area/facturer/ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_list_documents(apiKey, patientUUID, size, page) {
    let request = await axios.post(
      API +
        "osmose/patient/documents?patientUUID=" +
        patientUUID +
        "&size=" +
        size +
        "&page=" +
        page,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async lireCPS_ack(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireCPS/ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async lireCV_ack(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireCV/ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async auto_fill_adr(apiKey, content) {
    let request = await axios.post(API + "osmose/area/autoFillADR ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async sync_CV(apiKey, content) {
    let request = await axios.post(API + "osmose/area/syncCV", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request;
  }

  async lire_adr(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireADR", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async lire_adr_ack(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireADR/ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async lire_apcv(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireAPCV", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async relire_apcv(apiKey, content) {
    let request = await axios.post(API + "osmose/area/relireAPCV", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async lire_apcv_ack(apiKey, content) {
    let request = await axios.post(API + "osmose/area/lireAPCV/ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async supprimer_apcv(apiKey, content) {
    let request = await axios.post(API + "osmose/area/supprimerAPCV", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async refresh_apcv(apiKey, content) {
    let request = await axios.post(API + "osmose/area/refreshApcv", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async lire_Cv_enrich(apiKey, body) {
    const request = await axios.post(API + "osmose/area/lireCV/enrich", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_fse_tracking(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/suivis?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_lot_tracking(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/lots?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_fse_overview(apiKey) {
    let request = await axios.post(
      API + "osmose/fse/overview",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_follow_overview(apiKey) {
    let request = await axios.post(
      API + "osmose/fse/follow",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async put_on_batch(apiKey, content) {
    let request = await axios.post(API + "osmose/area/mettreEnLot ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async put_on_file(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/mettreEnFichier ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async teletransmit(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/teletransmettre ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async extract_lots(apiKey, content) {
    let request = await axios.post(API + "osmose/area/extraireLots ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async extract_lots_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/extraireLots/ack ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async extract_RSPs(apiKey, content) {
    let request = await axios.post(API + "osmose/area/extraireRSPs ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async extract_RSPs_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/extraireRSP/ack ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async paramPS(apiKey, content) {
    let request = await axios.post(API + "osmose/area/paramPS ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async delete_fse(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/supprimerFacture ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_fse_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/supprimerFacture/ack",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async secure_fse(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/securiserFacture ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async secure_fse_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/securiserFacture/ack ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_money_transfer(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/virements?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_treatments(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/traitements?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_mfis(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/mfis?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_punctual_payments(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/autrepaiements?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async cancel_ope(apiKey, data) {
    let request = await axios.post(
      API + "osmose/invoice/payment/settlement/cancel",
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async edit_ope(apiKey, data) {
    let request = await axios.post(
      API + "osmose/invoice/payment/settlement/amend",
      data,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_pj(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/pieces?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async ajouter_pj(apiKey, content) {
    let request = await axios.post(API + "osmose/area/ajouterPJ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async ajouter_pj_ack(apiKey, content) {
    let request = await axios.post(API + "osmose/area/ajouterPJ_ack", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async extract_SCOR(apiKey, content) {
    let request = await axios.post(API + "osmose/area/extraireSCOR ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async extract_SCOR_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/extraireSCOR/ack ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_scor_tracking(apiKey, page, size, content) {
    let request = await axios.post(
      API + "osmose/fse/dossierScor?page=" + page + "&size=" + size,
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async delete_pj(apiKey, content) {
    let request = await axios.post(API + "osmose/area/supprimerPJ ", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async delete_pj_ack(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/supprimerPJ/ack ",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async osmose_export_file(apiKey, key, form = {}) {
    let request = await axios.post(API + "osmose/export/" + key, form, {
      headers: {
        "x-tenant": apiKey,
      },
      responseType: "blob",
    });
    return request.data;
  }

  async osmose_invoice_credit(apiKey, data) {
    let request = await axios.post(API + "osmose/invoice/credit", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async osmose_invoice_refund(apiKey, data) {
    let request = await axios.post(API + "osmose/invoice/refund", data, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_patient_origin(apiKey) {
    let request = await axios.post(
      API + "osmose/static/patientOrigin/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_patient_origin(apiKey, form) {
    let request = await axios.post(
      API + "osmose/static/patientOrigin/set",
      form,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_patient_origin(apiKey) {
    let request = await axios.post(
      API + "osmose/static/patientOrigin",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  // list contacts
  async list_contact_patient(apiKey, form) {
    let request = await axios.post(API + "osmose/patient/recipients", form, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // send mail
  async send_mail(apiKey, content) {
    let request = await axios.post(API + "osmose/patient/send", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }
  async send_sms(apiKey, content) {
    let request = await axios.post(API + "osmose/patient/send", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  // Back office activity
  async get_activity_filter_keys(apiKey) {
    const request = await axios.post(
      API + "osmose/static/activity/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async export_activity(apiKey, data) {
    const request = await axios.post(API + "osmose/export/activity", data, {
      headers: {
        "x-tenant": apiKey,
      },
      responseType: "blob",
    });
    return request.data;
  }

  async search_document(apiKey, url) {
    const request = await axios.post(
      API + url,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async overview_document(apiKey, context, document) {
    const request = await axios.post(
      API + context + "/service/document/test",
      document,
      {
        headers: {
          "x-tenant": apiKey,
        },
        responseType: "blob",
      }
    );
    return request.data;
  }

  async get_society_list(apiKey) {
    const request = await axios.post(
      API + "osmose/static/company/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_society(apiKey, body) {
    const request = await axios.post(API + "osmose/static/company/set", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async select_society(apiKey, body) {
    const request = await axios.post(API + "osmose/shop/store/company", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async store_sequence(apiKey, body) {
    const request = await axios.post(API + "osmose/shop/store/sequence", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_state_list(apiKey) {
    const request = await axios.post(
      API + "osmose/static/shopRegion/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async set_state(apiKey, body) {
    const request = await axios.post(
      API + "osmose/static/shopRegion/set",
      body,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async select_state(apiKey, body) {
    const request = await axios.post(API + "osmose/shop/store/region", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async get_suivis_filterKey_list(apiKey) {
    let request = await axios.post(
      API + "osmose/fse/suivis/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_lots_filterKey_list(apiKey) {
    let request = await axios.post(
      API + "osmose/fse/lots/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_scor_filterKey_list(apiKey) {
    let request = await axios.post(
      API + "osmose/fse/scor/filterKey/select",
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async export_patient(apiKey, form) {
    let request = await axios.post(
      API +
        "/osmose/patient/export/patients/doctolib?startDate=" +
        form.startDate +
        "&endDate=" +
        form.endDate +
        "&batchSize=" +
        form.batchSize,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async export_appointment(apiKey, form) {
    let request = await axios.post(
      API +
        "/osmose/patient/export/calEvents/doctolib?startDate=" +
        form.startDate +
        "&endDate=" +
        form.endDate +
        "&batchSize=" +
        form.batchSize,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async get_by_fses_lot(apiKey, content) {
    let request = await axios.post(API + "osmose/fse/suivisByLot", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async force_to_payed(apiKey, content) {
    let request = await axios.post(API + "osmose/fse/forceToPayed", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async archive_fse(apiKey, content) {
    let request = await axios.post(API + "osmose/fse/archiveFse", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async link_ps_to_cart(apiKey, quoteUUID, nature, uuid) {
    let parameter = "";
    if (uuid != "") {
      parameter =
        "?quoteUUID=" + quoteUUID + "&nature=" + nature + "&uuid=" + uuid;
    } else {
      parameter = "?quoteUUID=" + quoteUUID + "&nature=" + nature;
    }
    let request = await axios.post(
      API + "osmose/quote/health/link" + parameter,
      {},
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async share_patient(apiKey, body) {
    let request = await axios.post(API + "osmose/patient/share", body, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async check_shop_situation(apiKey, content) {
    let request = await axios.post(
      API + "osmose/area/checkShopSituation",
      content,
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async ins_from_criteria(apiKey, content) {
    let request = await axios.post(API + "osmose/area/INSSearch", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async ins_from_cv(apiKey, content) {
    let request = await axios.post(API + "osmose/area/INSCV", content, {
      headers: {
        "x-tenant": apiKey,
      },
    });
    return request.data;
  }

  async sync_INS(apiKey, patientUUID, xml) {
    let request = await axios.post(
      API + "osmose/area/syncINS",
      {
        patientUUID: patientUUID,
        xml: xml,
      },

      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async auto_fill_ins(apiKey, patientUUID) {
    let request = await axios.post(
      API + "osmose/area/autoFillINS",
      { patientUUID: patientUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }

  async check_ins(apiKey, patientUUID, situationUUID) {
    let request = await axios.post(
      API + "osmose/area/checkINS",
      { situation: situationUUID, patientUUID: patientUUID },
      {
        headers: {
          "x-tenant": apiKey,
        },
      }
    );
    return request.data;
  }
}
