<template>
  <div class="d-flex justify-end align-center">
    <div
      class="mr-2"
      v-if="!$vuetify.breakpoint.smAndDown"
      style="min-width: 130px"
    >
      <span class="white--text subtitle-2">{{ userAuth.displayName }}</span>
      <br />
      <span class="grey--text subtitle-2">
        {{ timestamp | formatDate("LL") }}
      </span>
    </div>

    <v-menu
      offset-y
      left
      transition="slide-y-transition"
      :close-on-content-click="false"
      :close-on-click="onOffClickOut"
      v-model="menuOpen"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon class="elevation-2" v-on="on">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  padding: 1px;
                  background-color: rgba(255, 255, 255, 1);
                  border-radius: 50%;
                "
              >
                <UserAvatar :avatarInfo="userAuth.avatar" />
              </div>

              <!-- <v-avatar size="40" v-bind="attrs" v-on="on">
                <v-img src="/images/avatars/avatar-svgrepo-com.svg" />
              </v-avatar> -->
            </template>
            <div>
              <div class="body-1">
                <span>{{ userAuth.displayName }}</span>
              </div>
              <div class="body-1">
                est <strong>{{ currentBu.profilLabel }}</strong>
              </div>
              <div class="body-1">
                sur <span>{{ currentBu.tenant.label }}</span>
              </div>
            </div>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card tile>
        <!-- user menu list -->
        <v-list dense nav min-width="300" class="pa-1">
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            :exact="item.exact"
            :disabled="item.disabled"
            @click="item.dialog ? openDialog(item) : goToRoute(item.link)"
            link
            class="pa-1"
          >
            <v-list-item-icon>
              <v-icon small :class="{ 'grey--text': item.disabled }">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.key ? $t(item.key) : item.text | capitalize }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="logout"
            class="primary mt-1 pa-1"
            style="border-radius: 0"
          >
            <v-list-item-icon>
              <v-icon small color="white">mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text">
                {{ $t("auth.logout") | capitalize }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <FullPageDialog />
    <!-- <SupportInfo isConnect :showDialog.sync="clickSupportInfo" /> -->
  </div>
</template>

<script>
import config from "../../../configs";
import auth from "../../../class/auth";
import { mapGetters } from "vuex";
import FullPageDialog from "../../common/fullPageDialog.vue";
//import SupportInfo from "@/components/support/SupportInfo";
import UserAvatar from "../userProfil/UserAvatar.vue";
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default {
  name: "ToolbarUser",
  components: {
    FullPageDialog,
    // SupportInfo,
    UserAvatar,
  },
  computed: {
    ...mapGetters(["currentBu", "userAuth", "fullPageDialog"]),
  },
  data() {
    return {
      menu: config.toolbar.user,
      menuOpen: false,
      menuTri: [],
      intervalTime: null,
      timestamp: new Date(),
      clickSupportInfo: false,
      onOffClickOut: true,
    };
  },
  watch: {
    clickSupportInfo(val) {
      if (!val) {
        this.onOffClickOutM();
      }
    },
  },
  mounted() {
    this.autorizations();
    this.intervalTime = setInterval(() => {
      this.get_currentDate();
    }, 1000);
  },
  methods: {
    get_currentDate() {
      let date = new Date();
      this.timestamp = date;
    },

    autorizations() {
      for (let i = 0; i < this.menu.length; i++) {
        if (
          this.menu[i].key == "menu.billing" &&
          this.currentBu.authorizations["manageBilling@billing"] != "ACCESSIBLE"
        ) {
          this.menu.splice(i, 1);
        }
      }
    },

    goToRoute(route) {
      this.$router.push({ path: route });
    },

    openDialog(item) {
      this.$store.commit("update_fullPageDialog", {
        title: item.key,
        component: item.dialogComponent,
      });
    },

    async logout() {
      this.authService = new auth();
      try {
        await this.authService.logout();
        this.$router.push({
          name: "auth-signin",
        });
      } catch (error) {
        console.error(error);
      }
    },

    menuParam() {
      this.onOffClickOut = false;
    },

    onOffClickOutM() {
      this.onOffClickOut = true;
      this.menuOpen = false;
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalTime);
  },
};
</script>
