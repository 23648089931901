var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      class: _vm.allowRedirection ? "cursor-pointer" : "",
      style: _vm.outlined ? "border: 1px solid var(--v-primary-base)" : "",
      attrs: { color: _vm.color },
      on: {
        click: function ($event) {
          return _vm.go_to_company_page(_vm.currentCompany.companyUUID)
        },
      },
    },
    [
      _vm.showTitle
        ? _c(
            "v-card-title",
            {
              staticClass: "d-flex justify-space-between subtitle-1 grey--text",
              staticStyle: { "font-weight": "450" },
            },
            [
              _vm._v(" Société associée "),
              _vm.showEditButton &&
              _vm.$authorization.validateGrant("manageMember@brandpro")
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("edit")
                        },
                      },
                    },
                    [_vm._v("Modifier")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-card-text", { staticClass: "d-flex justify-space-between" }, [
        _c("div", [
          _c("p", { staticClass: "subtitle-1 info--text mb-0" }, [
            _vm._v(
              " " +
                _vm._s(_vm.currentCompany.socialReason) +
                " - " +
                _vm._s(_vm.currentCompany.code) +
                " "
            ),
          ]),
          _vm.currentCompany.address != null
            ? _c("p", { staticClass: "subtitle-2 primary--text mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.currentCompany.address.postal.city.city) +
                    " " +
                    _vm._s(_vm.currentCompany.address.postal.city.postCode) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "caption grey--text mb-0" }, [
            _vm.currentCompany.siren != null
              ? _c("span", [
                  _vm._v(" SIREN " + _vm._s(_vm.currentCompany.siren) + "   "),
                ])
              : _vm._e(),
            _vm.currentCompany.vatNumber != null
              ? _c("span", [
                  _vm._v(
                    " TVA " + _vm._s(_vm.currentCompany.vatNumber) + "   "
                  ),
                ])
              : _vm._e(),
            _vm.currentCompany.rcs != null
              ? _c("span", [
                  _vm._v("RCS " + _vm._s(_vm.currentCompany.rcs) + "   "),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          [
            _vm.$authorization.validateGrant("manageMember@brandpro")
              ? _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", small: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.showChangeCompany = true
                      },
                    },
                  },
                  [_vm._v("Changer de société")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.showChangeCompany
        ? _c("DialogChangeCompany", {
            attrs: {
              showDialog: _vm.showChangeCompany,
              currentCompany: _vm.currentCompany,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showChangeCompany = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showChangeCompany = $event
              },
              refresh: function ($event) {
                return _vm.$emit("refresh")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }