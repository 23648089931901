import axios from "axios";
import router from "../router/index";
import store from "../store/index";
import { WriteLogs } from "../tools/logs/WriteLogs";

export default () => {
  axios.defaults.baseURL = process.env.VUE_APP_URL;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // WriteLogs.error(error.response);
      if (
        error.response.status === 500 &&
        error.response.data.message.includes("JWT expired")
      ) {
        store.commit("update_userConnected", false);
        store.commit("update_userAuth", {
          displayName: null,
          locale: null,
          login: null,
          loginDate: null,
          nextRenewCredentialsDate: null,
          token: null,
        });
        store.commit("update_currentBu", null);
        axios.defaults.headers.common["Authorization"] = null;
        router.push({
          name: "auth-signin",
          query: { expired: true },
        });
      } else if (error.response.status === 403) {
        if (error.response.data.errorCode == "securityPasswordInvalid") {
        localStorage.removeItem("horusToken");
        router.push({
            name: "auth-signin",
            query: {
              messageError: "signInNOK",
            },
          });
        } else if (error.response.data.errorCode == "securityPasswordMissing") {
        localStorage.removeItem("horusToken");
        router.push({
            name: "auth-signin",
            query: {
              messageError: "missingPassword",
            },
          });
        }
        if (error.response.data.errorCode == "securityUserDisabled") {
          localStorage.removeItem("horusToken");
          router.push({
            name: "auth-signin",
            query: {
              messageError: "userDisabled",
            },
          });
        } else if (error.response.data.errorCode == "securityUserUnknown") {
          localStorage.removeItem("horusToken");
          router.push({
            name: "auth-signin",
            query: {
              messageError: "securityUserUnknown",
            },
          });
        } else if (error.response.data.errorCode == "securityTokenInvalid") {
          localStorage.removeItem("horusToken");
          router.push({
            name: "auth-signin",
            query: {
              messageError: "securityTokenInvalid",
            },
          });
        } else if (error.response.data.errorCode == "securityAccessDenied") {
          localStorage.removeItem("horusToken");
          router.push({
            name: "auth-signin",
            query: {
              messageError: "securityAccessDenied",
            },
          });
        } else if (error.response.data.errorCode == "securityUserUnconfirmed") {
          localStorage.removeItem("horusToken");
          router.push({
            name: "auth-signin",
            query: {
              messageError: "securityUserUnconfirmed",
            },
          });
        }
        // else {
        //   router.push({
        //     name: "auth-signin",
        //     query: {
        //       messageError: "toAllow"
        //     }
        //   });
        // }
        localStorage.removeItem("horusToken");
        store.commit("update_userConnected", false);
        store.commit("update_userAuth", {
          displayName: null,
          locale: null,
          login: null,
          loginDate: null,
          nextRenewCredentialsDate: null,
          token: null,
        });
        store.commit("update_currentBu", null);
        delete axios.defaults.headers.common["Authorization"];
      } else if (
        error.response.status === 503 ||
        error.response.status === 102 ||
        error.response.status === 500
      ) {
        router.push({
          name: "utility-maintenance",
        });
      } else if (error.response.status === 400) {
        if (error.response.data.errorCode == "dataTooMany") {
          router.push({
            name: "auth-signin",
            query: {
              messageError: "dataTooMany",
            },
          });
        }
      }
    }
  );
};
