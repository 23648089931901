export default {
  // user dropdown menu
  user: [
    {
      icon: "mdi-account-box",
      key: "menu.profile",
      text: "Profile",
      link: "/mon-profile",
      dialog: true,
      dialogComponent: "profil"
    },
    // {
    //   icon: "mdi-account-key",
    //   key: "menu.authorizations",
    //   text: "autorisations",
    //   link: "/autorisations",
    //   dialog: true,
    //   dialogComponent: "authorizations"
    // },
    // {
    //   icon: "mdi-domain",
    //   key: "menu.billing",
    //   text: "billing",
    //   link: "/billing",
    //   dialog: true,
    //   dialogComponent: "billing"
    // }
    // {
    //   icon: "mdi-cog",
    //   key: "menu.configuration",
    //   text: "configuration",
    //   link: "/configuration",
    // }
  ]
};
