var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              !_vm.hideBtn
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [{ name: "blur", rawName: "v-blur" }],
                          attrs: { "x-small": "", fab: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { color: "primary" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-dots-grid ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Changer d'espace de travail")])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selectTenantDialog,
        callback: function ($$v) {
          _vm.selectTenantDialog = $$v
        },
        expression: "selectTenantDialog",
      },
    },
    [
      _vm.selectTenantDialog
        ? _c(
            "v-card",
            { attrs: { "min-height": "300", color: "bgLvl3" } },
            [
              _c("v-card-title", { staticClass: "primary--text title" }, [
                _vm._v(" Choisir mon espace de travail "),
              ]),
              _vm.renderComponent
                ? _c(
                    "v-card-text",
                    { staticClass: "px-2 py-0" },
                    [
                      _c("SelectTenants", {
                        attrs: { btnStyle: true },
                        on: {
                          closeDialog: function ($event) {
                            _vm.selectTenantDialog = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { "min-width": "100", text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.selectTenantDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("form.close"))))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }