<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      :class="
        $vuetify.theme.dark ? 'curtainBackgroundDark' : 'curtainBackgroundLight'
      "
      tile
    >
      <Curtain-toolbar
        :label="$t(fullPageDialog.title) | capitalize"
        @clicked="cancel_dialog"
      />

      <component :is="fullPageDialog.component"> </component>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import CurtainToolbar from "./CurtainToolbar.vue";

export default {
  name: "fullPageDialog",
  computed: {
    ...mapGetters(["fullPageDialog"]),
  },
  components: {
    profil: () => import("@/pages/myOsmose/UserProfil.vue"),
    // Billing: () => import("@/pages/billing/billing.vue"),
    CurtainToolbar,
  },
  watch: {
    fullPageDialog(newValue) {
      if (newValue.title != "") {
        this.dialog = true;
      } else this.dialog = false;
    },
    $route: {
      handler(to, from) {
        if (to.fullPath != from.fullPath) {
          this.cancel_dialog();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    cancel_dialog() {
      this.$store.commit("update_fullPageDialog", {
        title: "",
        component: "",
      });
    },
  },
};
</script>
