var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", left: "", transition: "slide-y-transition" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _vm.count != 0
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-badge",
                            {
                              staticStyle: { "font-weight": "bold" },
                              attrs: {
                                color: "accent black--text",
                                content: _vm.count,
                                "offset-x": "22",
                                "offset-y": "22",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { icon: "" } }, on),
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-bell"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-bell"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-card",
            { attrs: { width: "600", loading: _vm.loadTips, color: "bgLvl2" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-space-between py-1 pr-1" },
                [
                  _c("span", { staticClass: "subtitle-1 primary--text" }, [
                    _vm._v(
                      " Mes " +
                        _vm._s(_vm.$t("notification.notifications")) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.dialogSetBroadcast = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" Diffuser un message "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.count == 0
                ? _c(
                    "v-alert",
                    {
                      staticClass: "body-2 mb-0 mt-1 mx-1",
                      attrs: { type: "info", text: "", dense: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("capitalize")(
                              _vm.$t("notification.noNotifications")
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-list",
                {
                  staticClass: "pa-1",
                  attrs: { "three-line": "", color: "bgLvl2" },
                },
                [
                  _vm._l(_vm.tips, function (item, index) {
                    return [
                      _c(
                        "div",
                        { key: index },
                        [
                          item.nature == "broadcast"
                            ? _c("UserTipsBroadcast", {
                                attrs: { tip: item },
                                on: { submited: _vm.get_tips },
                              })
                            : item.nature == "billingStatus"
                            ? _c("UserTipsBillingStatus", {
                                attrs: { tip: item },
                              })
                            : _c("UserTipsMessage", { attrs: { tip: item } }),
                          index < _vm.tips.length - 1
                            ? _c("v-divider")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm.dialogSetBroadcast
            ? _c("DialogSetBrodcastMessage", {
                attrs: { showDialog: _vm.dialogSetBroadcast },
                on: {
                  "update:showDialog": function ($event) {
                    _vm.dialogSetBroadcast = $event
                  },
                  "update:show-dialog": function ($event) {
                    _vm.dialogSetBroadcast = $event
                  },
                  submited: _vm.get_tips,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.$route.name == "myOsmose"
        ? _c(
            "div",
            _vm._l(_vm.tipsHigh, function (tip, index) {
              return _c("DialogTipsHigh", {
                key: index,
                attrs: { tip: tip },
                on: {
                  refresh: function ($event) {
                    return _vm.get_tips()
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }