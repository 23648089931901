<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-card color="bgLvl3">
      <v-card-title class="title primary--text">
        <span>SIREN incorrect ou inexistant</span>
      </v-card-title>
      <v-card-text>
        <v-row dense class="pt-1">
          <v-col cols="12">
            <v-alert type="warning" text dense class="mb-0 body-2">
              Attention, il semblerait que le SIREN renseigné soit incorrect ou
              inexistant. Veuillez renseigner un SIREN valide ou vérifier que la
              société existe bien.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center">
        <v-btn color="primary" text min-width="100" @click="cancel_dialog"
          >Fermer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogIncorrectSiren",

  props: {
    showDialog: {
      type: Boolean, // .sync mandatory
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
  },

  methods: {
    // Dialog funcs
    cancel_dialog() {
      this.openDialog = false;
    },
  },
};
</script>
