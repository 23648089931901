<template>
  <div>
    <v-btn
      ref="button"
      class="drawer-button"
      :color="colorHorusDesktop"
      @click="menuDesktop = true"
      dark
    >
      <v-icon>mdi-desktop-classic</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="menuDesktop"
      fixed
      right
      hide-overlay
      temporary
      width="350"
      color="bgLvl3"
    >
      <v-container class="pa-2">
        <v-row dense>
          <v-col class="d-flex justify-start">
            <v-switch
              v-model="activePing"
              hide-details
              color="success"
              dense
              inset
              label="Activer horus desktop"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row dense @click="menuDesktop = false" class="cursor-pointer">
          <v-col>
            <span class="title">Horus Desktop </span>
          </v-col>
          <v-col cols="1" class="mr-2">
            <v-btn icon @click="menuDesktop = false">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          dense
          v-if="
            statusDesktop &&
            responseStatusDesktop.desktop &&
            responseStatusDesktop.desktop['version']
          "
        >
          <v-col class="overline">
            V{{ responseStatusDesktop.desktop["version"] }} - Build
            {{ responseStatusDesktop.desktop["build"] }}
          </v-col>
        </v-row>

        <v-row dense class="mt-3" v-if="!statusDesktop">
          <v-col cols="12">
            <v-img
              src="@/assets/horusDesktop/horusDesktop.png"
              aspect-ratio="2"
              contain
            ></v-img>
          </v-col>
          <v-col cols="12"> Horus Desktop n'est pas démarré </v-col>
          <v-col cols="12">
            <InstallHorusDesktop />
          </v-col>
          <v-col cols="12" v-if="!statusDesktop">
            <v-alert type="info" text dense class="ma-0 body-2"
              >Ce module permet d'intéragir avec le matériel installé sur votre
              poste (imprimante, scan, etc...)</v-alert
            >
          </v-col>
        </v-row>

        <v-row dense class="mt-3" v-if="statusDesktop">
          <v-col cols="12">
            <Profile
              :loading="false"
              :listPrintOK.sync="loadingPrinters"
              :listScanOK.sync="loadingScans"
            />
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:append>
        <div class="pa-2 overline" style="text-align: center">
          <a href="https://desktop.opteamwork.io/" target="_blank">
            Accéder au module support
          </a>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// NOTE imprimante, scanner, lecteur de carte
// FIXME si dans l'AMC on choisie une autre scan que celui par defaut add dans le profil le scan avec une config dans un context (faire des test sur le site de recette)

import { mapGetters } from "vuex";

import horusDesktop from "@/class/horusDesktop/horusDesktop";

export default {
  name: "HorusDesktopMenu",

  components: {
    InstallHorusDesktop: () => import("./install/InstallHorusDesktop.vue"),
    Profile: () => import("./profile/Profile.vue"),
  },
  computed: {
    ...mapGetters(["statusDesktop"]),
  },
  data() {
    return {
      activePing: this.$store.state.pingHorusDesktop,
      colorHorusDesktop: "red",
      msgHorusDesktop: "-- Hors line --",
      menuDesktop: false,
      loadingScans: false,
      loadingPrinters: false,
      timeInterval: 0, // 15 seconde
      ping: null,
      pingTimeout: null,
      horusDesktopService: null,
      responseStatusDesktop: {},
    };
  },
  watch: {
    activePing(newValue) {
      clearTimeout(this.pingTimeout);
      this.$store.commit("upd_pingHorusDesktop", newValue);
      if (newValue) {
        this.check_horus_desktop();
      } else {
        this.change_status(false);
      }
    },
    ping(newValue) {
      if (this.ping != null && this.activePing) {
        this.change_status(newValue);
      }
    },
  },

  mounted() {
    // this.animate();
    if (this.horusDesktopService == null) {
      this.horusDesktopService = new horusDesktop();
    }
    if (this.activePing) {
      this.check_horus_desktop();
    }
  },
  methods: {
    async change_status(ping) {
      if (ping) {
        if (!this.statusDesktop) {
          this.$store.commit("upd_statusDesktop", true);
        }
        this.colorHorusDesktop = "green";
        this.msgHorusDesktop = "-- OnLine --";
        this.timeInterval = 60000; // 1 minute
        // this.timeInterval = 1000; // 1 seconde :: test mode

        let count = 0;
        this.pingTimeout = setTimeout(async () => {
          await this.check_horus_desktop();
          count = 1;
        }, this.timeInterval);
        if (count == 1) {
          clearTimeout(this.pingTimeout);
        }
      } else {
        if (this.statusDesktop) {
          this.$store.commit("upd_statusDesktop", false);
        }
        this.colorHorusDesktop = "red";
        this.msgHorusDesktop = "-- Hors line --";
        this.timeInterval = 15000; // 15 secondes
        this.timeInterval = 1000; // 1 seconde :: test mode

        let count = 0;
        this.pingTimeout = setTimeout(async () => {
          await this.check_horus_desktop();
          count = 1;
        }, this.timeInterval);
        if (count == 1) {
          clearTimeout(this.pingTimeout);
        }
      }
    },

    async check_horus_desktop() {
      this.ping = null;
      let a = await this.ping_horus_desktop();
      this.ping = a;
      if (this.ping) {
        this.get_status_area();
      }
    },

    async ping_horus_desktop() {
      try {
        this.responseStatusDesktop = await this.horusDesktopService.status();
        return true;
      } catch (error) {
        return false;
      }
    },

    async get_status_area() {
      if (
        this.responseStatusDesktop.area != null &&
        this.responseStatusDesktop.area[`areafse.process.status`] == "live"
      ) {
        this.$store.commit("upd_statusAreaFse", true);
      } else {
        if (this.responseStatusDesktop.area[`areafse.autostart`] == "false") {
          this.$store.commit("upd_statusAreaFse", true);
        } else {
          this.$store.commit("upd_statusAreaFse", false);
        }
      }
    },

    // NOTE is a animation for the btn horus
    // animate() {
    //   if (this.timeout) clearTimeout(this.timeout);
    //   const time = (Math.floor(Math.random() * 10 + 1) + 10) * 1000;
    //   this.timeout = setTimeout(() => {
    //     this.$animate(this.$refs.button.$el, "bounce");
    //     this.animate();
    //   }, time);
    // }
  },
  beforeDestroy() {
    clearTimeout(this.pingTimeout);
  },
};
</script>

<style lang="scss" scoped>
.drawer-button {
  position: fixed;
  top: 160px;
  right: -20px;
  z-index: 6;
  box-shadow: 1px 1px 18px grey;

  .v-icon {
    margin-left: -18px;
    font-size: 1.3rem;
  }
}
</style>
