<template>
  <v-app>
    <div style="min-height: 100vh; width: 100vw" id="elApp">
      <!-- :class="
        $vuetify.theme.dark ? 'backgroundPagesDark' : 'backgroundPagesLight'
      " -->
      <!-- Layout component -->
      <component :is="currentLayout" v-if="isRouterLoaded">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path" />
        </transition>
      </component>
    </div>

    <SnackbarNotification v-if="this.notification.open" />
    <IsUpdateBuild />
    <InitCustomTheme v-if="isRouterLoaded" :currentLayout="currentLayout" />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import config from "./configs";

// Layouts
import defaultLayout from "./layouts/DefaultLayout";
import authLayout from "./layouts/AuthLayout";
import errorLayout from "./layouts/ErrorLayout";
import surveyLayout from "./layouts/SurveyLayout";
import PublicLayout from "@/layouts/PublicLayout.vue";

import SnackbarNotification from "./components/common/SnackbarNotification.vue";
import IsUpdateBuild from "./components/isUpdateBuild/IsUpdateBuild.vue";

export default {
  name: "App",
  components: {
    defaultLayout,
    authLayout,
    errorLayout,
    surveyLayout,
    PublicLayout,
    SnackbarNotification,
    IsUpdateBuild,
    InitCustomTheme: () =>
      import(
        "./components/userSpace/userSpaceConfigurations/elements/customTheme/InitCustomTheme.vue"
      ),
  },

  computed: {
    ...mapGetters(["notification", "apiKey"]),
    ...mapState("app", ["toast"]),
    isRouterLoaded: function () {
      if (this.$route.name !== null) return true;
      return false;
    },
    currentLayout: function () {
      const layout = this.$route.meta.layout || "default";
      return layout + "Layout";
    },
  },

  data() {
    return {
      // Detection keyDown
      keyCodes: [],
      onKeyDown: null,
      // timeout: null,
    };
  },

  created() {
    // Detection keyDown
    this.onKeyDown = this.on_key_Down.bind(this);
    document.addEventListener("keydown", this.onKeyDown);
  },

  mounted() {
    this.$vuetify.theme.dark = this.$store.state.themeDark;
  },

  beforeDestroy() {
    clearInterval(this.timeout);
  },

  destroyed() {
    // Detection keyDown
    if (this.onKeyDown) {
      document.removeEventListener("keydown", this.onKeyDown);
    }
  },

  methods: {
    // Detection keyDown
    on_key_Down(e) {
      // ctrl -> x -> d
      this.keyCodes.push(e.keyCode);
      if (this.keyCodes.length > 2) {
        if (
          this.keyCodes[this.keyCodes.length - 1] === 68 &&
          this.keyCodes[this.keyCodes.length - 2] === 88 &&
          this.keyCodes[this.keyCodes.length - 3] === 17
        ) {
          this.$store.commit("upd_theme_dark", !this.$vuetify.theme.dark);
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        }
        if (this.keyCodes.length > 3) {
          this.keyCodes.splice(0, 1);
        }
      }
    },
  },

  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: "stylesheet", href })),
    ],
  },
};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
