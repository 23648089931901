<template>
  <div class="d-flex justify-start align-center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          icon
          small
          outlined
          class="mr-1"
          color="white"
          @click="$store.commit('open_dialog_dashboard', true)"
          v-blur
        >
          <v-icon small color="white">mdi-sine-wave</v-icon>
        </v-btn>
      </template>
      <span>Consulter vos différents tableaux de bords</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MenuNav",

  computed: {
    ...mapGetters(["apiKey"]),
    ...mapState(["storedMember"]),
    checkDoctolibAvailability() {
      return this.$store.state.booleanUpdMenuNav;
    },
  },

  data() {
    return {
      borderColor: "rgba(255, 255, 255, 0.3)",
    };
  },

  methods: {
    open_dashboard() {
      this.$store.commit("open_dialog_dashboard", true);
    },
  },
};
</script>
