var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", loading: _vm.loadInfoSupport, color: "bgLvl3" } },
    [
      _c("v-card-text", [
        _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { dense: "", justify: "space-between", align: "end" } },
              [
                _vm.modeLmca
                  ? _c(
                      "v-col",
                      {
                        staticClass:
                          "d-flex flex-column justify-end align-center text-center",
                        attrs: { cols: "4" },
                      },
                      [
                        _c("v-icon", { attrs: { size: "96" } }, [
                          _vm._v("mdi-chat-question"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center mt-1" },
                          [
                            _c("div", [
                              _c("p", { staticClass: "subtitle-1 my-0" }, [
                                _vm._v("Centre d'aide"),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "primary--text body-1",
                                  attrs: {
                                    href: "https://audition-conseil.zendesk.com/hc/fr",
                                    target: "blank",
                                  },
                                },
                                [_vm._v(" Ouvrir le centre d'aide ")]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.modeLmca && _vm.contactPhone
                  ? _c(
                      "v-col",
                      {
                        staticClass:
                          "d-flex flex-column justify-end align-center text-center",
                        attrs: { cols: "4" },
                      },
                      [
                        _c("v-icon", { attrs: { size: "96" } }, [
                          _vm._v("mdi-phone-classic"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center mt-1" },
                          [
                            _c("div", [
                              _c("div", [
                                _c("p", { staticClass: "subtitle-1 my-0" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.contactPhone.title) + " "
                                  ),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "primary--text body-1",
                                    attrs: {
                                      href:
                                        "tel:" + _vm.contactPhone.displayValue,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.contactPhone.displayValue) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.contactEmail
                  ? _c(
                      "v-col",
                      {
                        staticClass:
                          "d-flex flex-column justify-end align-center text-center",
                        attrs: { cols: "4" },
                      },
                      [
                        _c("v-icon", { attrs: { size: "96" } }, [
                          _vm._v("mdi-face-agent"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center mt-1" },
                          [
                            _c("div", [
                              _c("p", { staticClass: "subtitle-1 my-0" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.contactEmail.title) + " "
                                ),
                              ]),
                              !_vm.modeLmca
                                ? _c("span", { staticClass: "body-1" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.format_email(
                                            _vm.contactEmail.contact.email
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.contactEmail.contact.email)
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.modeLmca
                                ? _c("span", { staticClass: "body-1" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.format_email(
                                            "supportosmose@lmcaudio.fr"
                                          ),
                                        },
                                      },
                                      [_vm._v("supportosmose@lmcaudio.fr")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  {
                    staticClass:
                      "d-flex flex-column justify-end align-center text-center",
                    attrs: { cols: "4" },
                  },
                  [
                    _c("v-icon", { attrs: { size: "96" } }, [
                      _vm._v("mdi-file-certificate"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "subtitle-1 pre-formatted mt-1" },
                      [
                        _c("CGV", {
                          attrs: { openCGV: _vm.openCGV, isBr: "" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "mb-2", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "pre-formatted",
                        staticStyle: { "text-align": "start" },
                        attrs: { type: "info", text: "" },
                      },
                      [_vm._v(" " + _vm._s(_vm.openHoursNotice) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isConnect
          ? _c("div", [
              _c("div", { staticClass: "d-flex justify-center" }, [
                _c("span", { staticClass: "subtitle-1" }, [
                  _vm._v("Code support"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-center pl-8" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-h3 primary--text px-2 py-1",
                      staticStyle: {
                        "font-weight": "bold",
                        border: "solid 1px black",
                        "border-radius": "5px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.supportCode))]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-1",
                                        attrs: { fab: "", icon: "" },
                                        on: { click: _vm.codeSupport },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { size: "32", color: "primary" },
                                      },
                                      [_vm._v("mdi-reload")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        61117894
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("capitalize")(
                              _vm.$t("support.regenerateCodeSupport")
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }