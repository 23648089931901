var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-end align-center" },
    [
      !_vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "div",
            { staticClass: "mr-2", staticStyle: { "min-width": "130px" } },
            [
              _c("span", { staticClass: "white--text subtitle-2" }, [
                _vm._v(_vm._s(_vm.userAuth.displayName)),
              ]),
              _c("br"),
              _c("span", { staticClass: "grey--text subtitle-2" }, [
                _vm._v(
                  " " + _vm._s(_vm._f("formatDate")(_vm.timestamp, "LL")) + " "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            left: "",
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "close-on-click": _vm.onOffClickOut,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      { staticClass: "elevation-2", attrs: { icon: "" } },
                      on
                    ),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              padding: "1px",
                                              "background-color":
                                                "rgba(255, 255, 255, 1)",
                                              "border-radius": "50%",
                                            },
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("UserAvatar", {
                                          attrs: {
                                            avatarInfo: _vm.userAuth.avatar,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("div", [
                            _c("div", { staticClass: "body-1" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.userAuth.displayName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(" est "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.currentBu.profilLabel)),
                              ]),
                            ]),
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(" sur "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.currentBu.tenant.label)),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menuOpen,
            callback: function ($$v) {
              _vm.menuOpen = $$v
            },
            expression: "menuOpen",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(
                "v-list",
                {
                  staticClass: "pa-1",
                  attrs: { dense: "", nav: "", "min-width": "300" },
                },
                [
                  _vm._l(_vm.menu, function (item, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        staticClass: "pa-1",
                        attrs: {
                          exact: item.exact,
                          disabled: item.disabled,
                          link: "",
                        },
                        on: {
                          click: function ($event) {
                            item.dialog
                              ? _vm.openDialog(item)
                              : _vm.goToRoute(item.link)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c(
                              "v-icon",
                              {
                                class: { "grey--text": item.disabled },
                                attrs: { small: "" },
                              },
                              [_vm._v(" " + _vm._s(item.icon) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("capitalize")(
                                      item.key ? _vm.$t(item.key) : item.text
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "primary mt-1 pa-1",
                      staticStyle: { "border-radius": "0" },
                      on: { click: _vm.logout },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "white" } },
                            [_vm._v("mdi-logout-variant")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "white--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.$t("auth.logout"))
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FullPageDialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }