<template>
  <div style="height: 100%">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SurveyLayout",

  mounted() {
    this.langDetect();
  },

  methods: {
    langDetect() {
      const supported = ["en", "fr"];
      try {
        // get browser default language
        const { 0: browserLang } = navigator.language.split("-");

        if (supported.includes(browserLang)) this.$i18n.locale = browserLang;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
