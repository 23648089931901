var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", persistent: "" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _c(
        "v-form",
        {
          ref: "formChangeCompany",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validation_form.apply(null, arguments)
            },
          },
          model: {
            value: _vm.validForm,
            callback: function ($$v) {
              _vm.validForm = $$v
            },
            expression: "validForm",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { color: "bgLvl3" } },
            [
              _c("v-card-title", { staticClass: "title primary--text" }, [
                _c("span", [_vm._v("Changer la société associée")]),
              ]),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("SearchCompany", {
                            attrs: {
                              showBtnCreation: false,
                              dense: "",
                              label: "Attacher à une société",
                            },
                            on: { selected: _vm.assign_company_to_form },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        "min-width": "100",
                        disabled: _vm.loadForm,
                      },
                      on: { click: _vm.cancel_dialog },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loadForm,
                        disabled: !_vm.validForm,
                        type: "submit",
                      },
                    },
                    [_vm._v("Valider")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }