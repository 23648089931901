<template>
  <v-dialog v-model="openDialog" max-width="1000" persistent>
    <v-form
      v-model="validForm"
      ref="formSetNatureOpp"
      @submit.prevent="validation_form"
    >
      <v-card color="bgLvl3">
        <v-card-title class="title primary--text">
          <span>Créer un laboratoire</span>
        </v-card-title>
        <v-container class="pt-0">
          <v-row dense>
            <v-col cols="12" class="mb-1">
              <v-alert type="info" text dense class="mb-0 body-2">
                Créer votre laboratoire en renseignant directement son SIRET.
                Nous récupererons les informations depuis notre annuaire
                national. Vous pouvez créer votre laboratoire en renseignant
                manuellement sa dénomination commerciale.
              </v-alert>
            </v-col>

            <v-row dense>
              <v-col cols="6">
                <v-col
                  cols="12"
                  v-if="form.company.companyUUID == null"
                  class="px-2 pt-0"
                >
                  <SearchCompany
                    @selected="assign_company_to_form"
                    @creationCompany="dialog_create_company"
                    label="Attacher à une société"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <CompanySummaryCard
                    :currentCompany="form.company"
                    :showEditButton="true"
                    :allowRedirection="false"
                    @edit="reset_search"
                    :outlined="true"
                  />
                </v-col>

                <v-col cols="12" class="px-2 py-1">
                  <v-text-field
                    v-model="form.code"
                    label="Code d'identification"
                    :rules="[rules.required]"
                    :disabled="loadForm"
                    outlined
                    dense
                    autocomplete="null"
                  ></v-text-field
                ></v-col>

                <v-col cols="12" class="px-2 py-1">
                  <v-text-field
                    v-model="form.accountingCode"
                    label="Code tiers"
                    :disabled="loadForm"
                    outlined
                    dense
                    autocomplete="null"
                    persistent-hint
                    hint="Si vide alors le code d’identification sera utilisé"
                  ></v-text-field
                ></v-col>
              </v-col>

              <v-divider color="grey" vertical></v-divider>

              <v-col cols="6">
                <v-col cols="12" class="px-2 py-0">
                  <v-text-field
                    v-model="siret"
                    label="Rechercher un SIRET dans notre annuaire"
                    name="siret"
                    :rules="[rules.maxLength(128)]"
                    clearable
                    outlined
                    dense
                    :loading="loading"
                    @keyup.enter="get_info_by_siret"
                    :disabled="loading"
                    color="primary"
                    hint="Appuyez sur Entrer pour effectuer la recherche"
                  >
                    <template v-slot:append>
                      <span v-if="vetted != null">
                        <v-tooltip top v-if="vetted">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="success" v-bind="attrs" v-on="on"
                              >mdi-check-circle</v-icon
                            >
                          </template>
                          <span>SIRET valide</span>
                        </v-tooltip>
                        <v-tooltip top v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="error" v-bind="attrs" v-on="on"
                              >mdi-alert-circle</v-icon
                            >
                          </template>
                          <span>SIRET invalide ou inexistant</span>
                        </v-tooltip>
                      </span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="px-2">
                  <v-row dense class="align-center">
                    <v-divider color="grey" class="mr-1"></v-divider>
                    <span class="subtitle-2 primary--text">
                      ou créer manuellement
                    </span>
                    <v-divider color="grey" class="ml-1"></v-divider>
                  </v-row>
                </v-col>

                <v-col cols="12" class="px-2 py-1">
                  <v-text-field
                    v-model="form.commercialName"
                    label="Dénomination commerciale"
                    name="socialReason"
                    :rules="[rules.required, rules.maxLength(128)]"
                    clearable
                    outlined
                    dense
                    :disabled="loadForm"
                    color="primary"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            :disabled="loadForm"
            @click="cancel_dialog"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            :loading="loadForm"
            :disabled="!validForm"
            type="submit"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <DialogCreateCompany
      v-if="dialogAddCompany"
      :showDialog.sync="dialogAddCompany"
      @close="close_dialog_company"
      :companySiren="companySiren"
      :returnCompany="true"
    />
    <DialogCorrectSiret
      v-if="openDialogCorrectSiret"
      :showDialog.sync="openDialogCorrectSiret"
      :laboratoryInformation="form"
      @cancel="reset_form"
    />
    <!-- @validate="submit_form" -->

    <DialogIncorrectSiret
      v-if="openDialogIncorrectSiret"
      :showDialog.sync="openDialogIncorrectSiret"
    />
    <!-- <DialogAskCreateCompany
      v-if="dialogAskCreateCompany"
      :showDialog.sync="dialogAskCreateCompany"
      :companySiren="companySiren"
      @create="dialog_create_company"
    /> -->

    <DialogShowMessageBack
      v-if="showDialogErrorMessageBack"
      :showDialog.sync="showDialogErrorMessageBack"
      :messages="messageErrorBack"
      confirmBtn
      @confirm="submit_form"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import GouvFr from "@/class/gouvFr.js";
import Laboratory from "@/class/laboratory.js";

import CompanySummaryCard from "../company/cards/CompanySummaryCard.vue";
const DialogCreateCompany = () => import("../company/DialogCreateCompany.vue");
const DialogCorrectSiret = () => import("./DialogCorrectSiret.vue");
const DialogIncorrectSiret = () => import("./DialogIncorrectSiret.vue");
const DialogAskCreateCompany = () =>
  import("../company/DialogAskCreateCompany.vue");
import SearchCompany from "../company/SearchCompany.vue";
const DialogShowMessageBack = () =>
  import("@/components/common/DialogShowMessageBack");

export default {
  name: "DialogCreateLaboratory",
  components: {
    CompanySummaryCard,
    DialogCreateCompany,
    DialogCorrectSiret,
    DialogIncorrectSiret,
    DialogAskCreateCompany,
    SearchCompany,
    DialogShowMessageBack,
  },
  props: {
    showDialog: {
      type: Boolean, // .sync mandatory
    },
    returnLaboratory: {
      type: Boolean,
      default: false,
    },
    currentCompany: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["apiKey"]),
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
  },
  data() {
    return {
      ClassGouvFr: null,
      ClassLaboratory: null,
      showDialogErrorMessageBack: false,
      messageErrorBack: [],
      validForm: false,
      loadForm: false,
      loadSearch: false,
      resultItems: [],
      timeOut: null,
      strSearch: null,
      selectItem: null,
      dialogAddCompany: false,
      siret: null,
      vetted: null,
      openDialogCorrectSiret: false,
      openDialogIncorrectSiret: false,
      postalAddress: null,
      loading: false,
      companySiren: null,
      dialogAskCreateCompany: false,
      form: {
        commercialName: null,
        state: true,
        code: null,
        accountingCode: null,
        company: {
          companyUUID: null,
        },
      },
      listCompany: [],
      rules: {
        required: (v) => !!v || "Ce champ est requis.",
        maxLength: (len) => (v) =>
          (v || "").length <= len || `${len} caractères maximum`,
      },
    };
  },

  created() {
    this.ClassLaboratory = new Laboratory();
  },

  mounted() {
    if (this.currentCompany != null) {
      this.form.company = this.currentCompany;
    }
  },

  methods: {
    // Submit
    async validation_form() {
      if (this.validForm && this.form.company.companyUUID != null) {
        await this.check_identification();
      } else {
        this.$refs.formSetNatureOpp.validate();
      }
    },

    async check_identification() {
      try {
        const response = await this.ClassLaboratory.check_store_identification(
          this.apiKey,
          this.form
        );

        if (response.status == "ABORTED") {
          this.messageErrorBack = response.audits;
          this.showDialogErrorMessageBack = true;
        } else {
          this.submit_form();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async submit_form() {
      try {
        this.loadForm = true;

        const response = await this.ClassLaboratory.store_identification(
          this.apiKey,
          this.form
        );
        if (this.returnLaboratory == true) {
          this.$emit("close", response);
        } else {
          this.$router.push({
            name: "detailsLaboratory",
            params: { laboratoryUUID: response.laboratoryUUID },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.reset_form();
        this.cancel_dialog();
        if (this.returnLaboratory == false) this.$emit("close");
      }
    },

    // Dialog funcs
    cancel_dialog() {
      this.openDialog = false;
    },

    dialog_create_company() {
      this.dialogAddCompany = true;
    },

    close_dialog_company(response) {
      this.form.company = response;
      this.dialogAddCompany = false;
    },

    reset_search() {
      this.selectItem = null;
      this.strSearch = null;
      this.form.company = {
        companyUUID: null,
      };
    },

    check_creation_company() {
      if (this.currentCompany == null && this.companySiren != null) {
        this.dialogAskCreateCompany = true;
      }
    },

    async get_info_by_siret() {
      this.loading = true;
      if (this.ClassGouvFr == null) {
        this.ClassGouvFr = new GouvFr();
      }

      let response = await this.ClassGouvFr.get_info_by_siret(this.siret);
      this.loading = false;
      this.vetted = false;
      if (response.vetted) {
        // await this.search(response.legalEntity);

        if (this.resultItems != null && this.resultItems.length > 0) {
          this.selectItem = this.resultItems[0];
        } else {
          this.companySiren = response.legalEntity;
        }

        this.postalAddress = response.location;
        this.vetted = false;
        this.form.commercialName = response.businessName;
        this.form.state = response.live;
        this.form.siret = response.identifier;
        this.form.location = {
          label: response.location.label,
        };

        // if (this.currentCompany != null) {
        //   // don't reset if we have a currentCompany props because we want to keep it
        //   this.form.company = this.currentCompany;
        // } else {
        //   this.form.company = {
        //     companyUUID: null,
        //   };
        // }
        this.vetted = true;
        this.openDialogCorrectSiret = true;
      } else {
        this.openDialogIncorrectSiret = true;
      }
      this.loading = false;
    },

    reset_form() {
      this.form.commercialName = null;
      this.selectItem = null;
      if (this.currentCompany != null) {
        // don't reset if we have a currentCompany props because we want to keep it
        this.form.company.companyUUID = null;
      }
      this.vetted = null;
      this.siret = null;
      this.companySiren = null;
    },

    assign_company_to_form(company) {
      this.form.company = company;
    },
  },
};
</script>
