var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.returnObject
    ? _c("v-autocomplete", {
        attrs: {
          items: _vm.countries,
          "item-text": "name",
          "return-object": "",
          label: _vm.inputTitle,
          placeholder: "France",
          name: "countryOfBirth",
          clearable: "",
          outlined: "",
          dense: "",
        },
        model: {
          value: _vm.outputCountry,
          callback: function ($$v) {
            _vm.outputCountry = $$v
          },
          expression: "outputCountry",
        },
      })
    : _c("v-autocomplete", {
        attrs: {
          items: _vm.countries,
          "item-text": "name",
          "item-value": "name",
          label: _vm.inputTitle,
          placeholder: "France",
          name: "countryOfBirth",
          clearable: "",
          outlined: "",
          dense: "",
        },
        model: {
          value: _vm.outputCountry,
          callback: function ($$v) {
            _vm.outputCountry = $$v
          },
          expression: "outputCountry",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }