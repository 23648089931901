var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    ref: "searchCompany",
    staticClass: "custom-autocomplete",
    attrs: {
      items: _vm.resultItems,
      "search-input": _vm.strSearch,
      loading: _vm.loadSearch,
      "item-text": "socialReason",
      "item-value": "companyUUID",
      label: _vm.label,
      id: "inputNavSearchCompany",
      "hide-details": "",
      "no-filter": "",
      "return-object": "",
      "prepend-inner-icon": "mdi-magnify",
      outlined: "",
      dense: _vm.dense,
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.strSearch = $event
      },
      "update:search-input": function ($event) {
        _vm.strSearch = $event
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.showBtnCreation
          ? {
              key: "append-outer",
              fn: function () {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mt-n1",
                                        attrs: { icon: "", color: "primary" },
                                        on: {
                                          click: _vm.dialog_create_company,
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-plus-circle")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2343918786
                      ),
                    },
                    [_c("span", [_vm._v("Créer une société")])]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "item",
          fn: function ({ item }) {
            return [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0 pt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "primary--text font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.socialReason) +
                              " " +
                              _vm._s(item.code) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
                    _c("span", { staticClass: "caption info--text" }, [
                      item.siren != null
                        ? _c("span", [
                            _vm._v(" SIREN " + _vm._s(item.siren) + "   "),
                          ])
                        : _vm._e(),
                      item.vatNumber != null
                        ? _c("span", [
                            _vm._v(" TVA " + _vm._s(item.vatNumber) + "   "),
                          ])
                        : _vm._e(),
                      item.rcs != null
                        ? _c("span", [
                            _vm._v(" RCS " + _vm._s(item.rcs) + "   "),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.selectItem,
      callback: function ($$v) {
        _vm.selectItem = $$v
      },
      expression: "selectItem",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }