<template>
  <v-dialog v-model="openDialog" max-width="800" persistent>
    <v-form
      v-model="validForm"
      ref="formCreateCompany"
      @submit.prevent="validation_form"
    >
      <v-card color="bgLvl3">
        <v-card-title class="title primary--text">
          <span>Créer une société</span>
        </v-card-title>
        <v-container class="pt-0 pb-1">
          <v-row dense>
            <v-col cols="12" class="mb-1">
              <v-alert type="info" text dense class="mb-0 body-2">
                Créer votre société en renseignant directement son SIREN. Nous
                récupererons les informations depuis notre annuaire national.
                Vous pouvez créer votre société en renseignant manuellement sa
                raison sociale.
              </v-alert>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="5">
              <v-text-field
                v-model="form.code"
                label="Code d'identification"
                :rules="[rules.required]"
                :disabled="loadForm"
                outlined
                dense
                autocomplete="null"
                class="mr-2"
              ></v-text-field
            ></v-col>

            <v-divider color="grey" vertical></v-divider>

            <v-col cols="7">
              <v-col cols="12" class="px-2 py-0">
                <v-text-field
                  v-model="siren"
                  label="Rechercher un SIREN dans notre annuaire"
                  name="siren"
                  :rules="[rules.maxLength(128)]"
                  clearable
                  outlined
                  dense
                  :loading="loading"
                  @keyup.enter="get_info_by_siren"
                  :disabled="loading"
                  color="primary"
                  hint="Appuyez sur Entrer pour effectuer la recherche"
                >
                  <template v-slot:append-outer>
                    <span v-if="vetted != null">
                      <v-tooltip top v-if="vetted">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="success" v-bind="attrs" v-on="on"
                            >mdi-check-circle</v-icon
                          >
                        </template>
                        <span>SIREN valide</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="error" v-bind="attrs" v-on="on"
                            >mdi-alert-circle</v-icon
                          >
                        </template>
                        <span>SIREN invalide ou inexistant</span>
                      </v-tooltip>
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-row dense class="align-center">
                  <v-divider color="grey" class="mr-1"></v-divider>
                  <span class="subtitle-2 primary--text">
                    ou créer manuellement
                  </span>
                  <v-divider color="grey" class="ml-1"></v-divider>
                </v-row>
              </v-col>
              <v-col cols="12" class="px-2 py-0">
                <v-text-field
                  v-model="form.socialReason"
                  label="Raison sociale"
                  name="socialReason"
                  :rules="[rules.required, rules.maxLength(128)]"
                  clearable
                  outlined
                  dense
                  :disabled="loadForm"
                  color="primary"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="d-flex justify-end align-center">
          <v-btn
            color="primary"
            text
            min-width="100"
            :disabled="loadForm"
            @click="cancel_dialog"
            >Annuler</v-btn
          >
          <v-btn
            color="primary"
            min-width="100"
            :loading="loadForm"
            :disabled="!validForm"
            type="submit"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <DialogCorrectSiren
      v-if="openDialogCorrectSiren"
      :showDialog.sync="openDialogCorrectSiren"
      :companyInformations="form"
      @validate="validation_form"
      @cancel="reset_form"
    />
    <DialogIncorrectSiren
      v-if="openDialogIncorrectSiren"
      :showDialog.sync="openDialogIncorrectSiren"
    />
    <DialogShowMessageBack
      v-if="showDialogErrorMessageBack"
      :showDialog.sync="showDialogErrorMessageBack"
      :messages="messageErrorBack"
      @confirm="submit_form"
      confirmBtn
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import GouvFr from "@/class/gouvFr.js";
import Company from "@/class/company.js";

import DialogCorrectSiren from "./DialogCorrectSiren.vue";
import DialogIncorrectSiren from "./DialogIncorrectSiren.vue";
const DialogShowMessageBack = () =>
  import("@/components/common/DialogShowMessageBack");

export default {
  name: "DialogCreateCompany",
  components: {
    DialogCorrectSiren,
    DialogIncorrectSiren,
    DialogShowMessageBack,
  },
  props: {
    showDialog: {
      type: Boolean, // .sync mandatory
    },
    returnCompany: {
      type: Boolean,
      default: false,
    },
    companySiren: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["apiKey"]),
    openDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit("update:showDialog", value);
      },
    },
  },
  data() {
    return {
      ClassGouvFr: null,
      ClassCompany: null,
      showDialogErrorMessageBack: false,
      messageErrorBack: [],
      validForm: false,
      loadForm: false,
      siren: null,
      vetted: null,
      openDialogCorrectSiren: false,
      openDialogIncorrectSiren: false,
      postalAddress: null,
      loading: false,
      form: {
        state: true,
        socialReason: null,
        code: null,
      },
      rules: {
        required: (v) => !!v || "Ce champ est requis.",
        maxLength: (len) => (v) =>
          (v || "").length <= len || `${len} caractères maximum`,
      },
    };
  },
  mounted() {
    if (this.companySiren != null) {
      this.siren = this.companySiren;
      this.get_info_by_siren();
    }
  },
  methods: {
    // Submit
    async validation_form() {
      if (this.validForm) {
        await this.check_identification();
      } else {
        this.$refs.formCreateCompany.validate();
      }
    },

    async check_identification() {
      try {
        if (this.ClassCompany == null) {
          this.ClassCompany = new Company();
        }

        const response = await this.ClassCompany.check_store_identification(
          this.apiKey,
          this.form
        );

        if (response.status == "ABORTED") {
          this.messageErrorBack = response.audits;
          this.showDialogErrorMessageBack = true;
        } else {
          this.submit_form();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async submit_form() {
      try {
        this.loadForm = true;
        const response = await this.ClassCompany.store_identification(
          this.apiKey,
          this.form
        );
        if (this.postalAddress != null) {
          let body = {
            companyUUID: response.companyUUID,
            address: {
              type: "postalAddress",
              postal: this.postalAddress,
            },
          };
          await this.ClassCompany.store_company_contact(this.apiKey, body);
        }
        if (this.returnCompany) {
          this.$emit("close", response);
        } else {
          this.$router.push({
            name: "detailsCompany",
            params: { companyUUID: response.companyUUID },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadForm = false;
        this.cancel_dialog();
        if (this.returnCompany == false) {
          this.$emit("close");
        }
      }
    },

    async get_info_by_siren() {
      this.loading = true;
      if (this.ClassGouvFr == null) {
        this.ClassGouvFr = new GouvFr();
      }

      let response = await this.ClassGouvFr.get_info_by_siren(this.siren);
      this.postalAddress = response.headquarterLocation;
      this.loading = false;
      this.vetted = false;
      if (response.vetted) {
        this.form = {
          socialReason: response.name,
          vatNumber: response.vatin,
          legalStatus: response.legalTypeMnemo,
          state: response.live,
          siren: response.identifier,
          headquarterLocation: response.headquarterLocation,
        };
        this.vetted = true;
        this.openDialogCorrectSiren = true;
      } else {
        this.openDialogIncorrectSiren = true;
      }
    },

    // Dialog funcs
    cancel_dialog() {
      this.openDialog = false;
    },

    reset_form() {
      this.form.socialReason = null;
      this.vetted = null;
      this.siren = null;
    },
  },
};
</script>
